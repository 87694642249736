import { useSearchParams as useSearchParamsReactRouterDom } from "react-router-dom";

export const useSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParamsReactRouterDom();

  return {
    searchParams,
    addSearchParam: (param: string, value: string) => {
      searchParams.set(param, value);
      setSearchParams(searchParams);
    },
    removeSearchParam: (param: string) => {
      searchParams.delete(param);
      setSearchParams(searchParams);
    },
  };
};
