import { useNavigate } from "react-router-dom";
import { openUrl } from "../utils/linking";

export const useNavigation = () => {
  const navigate = useNavigate();

  const internalNavigate = (to: string, params?: Record<string, string | undefined>) => {
    const urlParts = to.split("?");
    const searchParams = new URLSearchParams(urlParts[1]);

    Object.entries(params ?? {}).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value);
      }
    });

    if (urlParts[0]?.startsWith("http")) {
      window.location.href = to;
    } else {
      navigate(`${urlParts[0]}?${searchParams.toString()}`);
    }
  };

  return {
    navigate: internalNavigate,
    goBack: () => navigate(-1),
  };
};
