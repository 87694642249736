import { createRestSlice } from "./restSlice";

export type Character = {
  id: string;
  worldId: string;
  name: string;
  role: string;
  description: string;
  image: string;
  voiceId: string;
  isPublic: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

const characterSlice = createRestSlice<Character>("character", "/v1/characters");

export const characterActions = characterSlice.actions;
export const characterReducer = characterSlice.reducer;
