import { useState } from "react";

import { accountReaderActions } from "../../redux/reducers/accountReaderSlice";
import { Haptics } from "../../utils/haptics";
import { OnboardingCard } from "../../components/OnboardingCard";
import { ROUTES } from "../../utils/routes";
import { SelectableCard } from "../../components/SelectableCard";
import { sessionActions } from "../../redux/reducers/sessionSlice";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useNavigation } from "../../hooks/useNavigation";
import { useRoute } from "../../hooks/useRoute";

type Route = {
  params: {
    onboardingType: "account" | "contributor";
  };
};

export const OnboardingReaderLevelPage = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const route = useRoute<Route>();
  const user = useAppSelector((state) => state.session.user);
  const isLoading = useAppSelector((state) => state.accountReader.isLoading);
  const [level, setLevel] = useState<string>("emergent");

  const onChangeLevelHandler = (newLevel: string) => () => {
    setLevel(newLevel);
  };

  const onClickContinue = async () => {
    Haptics.trigger(Haptics.HapticFeedbackTypes.impactLight);

    await dispatch(
      accountReaderActions.updateById({
        id: user?.account?.readers?.[0].id,
        level,
      }),
    );

    dispatch(sessionActions.getSession());

    navigation.navigate(ROUTES.ONBOARDING_CREATE_CONTRIBUTOR, { onboardingType: route.params.onboardingType });
  };

  return (
    <OnboardingCard
      title="To tailor the experience for your child, select their current reading level. 📚"
      currentStep={4}
      totalSteps={6}
      onClickContinue={onClickContinue}
      continueDisabled={!level}
      continueLoading={isLoading}
    >
      <SelectableCard
        className="mb-2"
        title="Emergent Reader"
        subtitle="Prefers books with lots of pictures and simple words."
        isSelected={level === "emergent"}
        onClick={onChangeLevelHandler("emergent")}
      />
      <SelectableCard
        className="mb-2"
        title="Early Reader"
        subtitle="Enjoys short sentences and simple stories with basic vocabulary."
        isSelected={level === "early"}
        onClick={onChangeLevelHandler("early")}
      />
      <SelectableCard
        className="mb-4"
        title="Fluent Reader"
        subtitle="Loves reading chapter books and longer stories."
        isSelected={level === "fluent"}
        onClick={onChangeLevelHandler("fluent")}
      />
    </OnboardingCard>
  );
};
