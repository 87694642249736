import { useRef, useState } from "react";

export const useRecording = () => {
  const recordingTimestamp = useRef(0);
  const [isLoadingRecording, setIsLoadingRecording] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const recording = useRef<MediaRecorder | null>(null);
  const recordingChunks = useRef<BlobPart[]>([]);

  const start = async (retry = 0) => {
    try {
      setIsLoadingRecording(true);

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      recording.current = new MediaRecorder(stream);
      recordingChunks.current = [];

      recording.current.ondataavailable = (e) => {
        if (e.data.size > 0) recordingChunks.current.push(e.data);
      };

      recording.current.start();

      recordingTimestamp.current = Date.now();

      setIsLoadingRecording(false);
      setIsRecording(true);
    } catch (error) {
      if (retry < 3) {
        setTimeout(() => {
          start(retry + 1);
        }, 1000);
      } else {
        setIsLoadingRecording(false);
        alert("Failed to record your voice. Please try again later.");
      }
    }
  };

  const stop = async () => {
    setIsRecording(false);

    recording.current?.stop();

    const duration = Date.now() - recordingTimestamp.current;
    if (duration >= 60000) {
      const blob = new Blob(recordingChunks.current, { type: "audio/webm" });
      const file = new File([blob], "recording.wav", { type: "audio/webm" });

      return file;
    }

    return null;
  };

  return {
    start,
    stop,
    isLoadingRecording,
    isRecording,
  };
};
