import { Text } from "./Text";
import { Card } from "./Card";
import { View } from "./View";

type StoryPlotCardProps = {
  className?: string;
  title?: string;
  content?: string;
};

export const StoryPlotCard = ({ className, title, content }: StoryPlotCardProps) => {
  return (
    <Card className={className}>
      <View className="flex flex-col p-8">
        <Text className="text-2xl sm:text-4xl font-semibold text-gray-800 line-clamp-2 mb-4">{title}</Text>
        <Text className="text-lg sm:text-2xl text-gray-800">{content}</Text>
      </View>
    </Card>
  );
};
