import { createRestSlice } from "./restSlice";

export type Theme = {
  id: string;
  name: string;
  description: string;
  image: string;
};

const themeSlice = createRestSlice<Theme>("theme", "/v1/themes");

export const themeActions = themeSlice.actions;
export const themeReducer = themeSlice.reducer;
