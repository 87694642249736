type TextProps = {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent) => void;
};

export const Text = ({ className, children, style, onClick }: TextProps) => {
  return (
    <span
      className={className}
      style={style}
      onClick={onClick}
    >
      {children}
    </span>
  );
};
