import {
  ArrowBackRounded,
  ArrowDownwardRounded,
  ArrowUpwardRounded,
  CheckRounded,
  Folder,
  ImageOutlined,
  InfoOutlined,
  MicOutlined,
  QuestionMarkOutlined,
  Stop,
} from "@mui/icons-material";

import { memoize } from "../../utils/performance";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { info } from "console";

const icons: Record<string, OverridableComponent<SvgIconTypeMap<{}, "svg">>> = {
  "arrow-back": ArrowBackRounded,
  "arrow-up": ArrowUpwardRounded,
  "arrow-down": ArrowDownwardRounded,
  "info-outline": InfoOutlined,
  "question-mark": QuestionMarkOutlined,
  mic: MicOutlined,
  folder: Folder,
  image: ImageOutlined,
  stop: Stop,
  check: CheckRounded,
  info: InfoOutlined,
};

type IconProps = {
  name: string;
  size?: number;
  className?: string;
};

export const Icon = memoize(({ name, size = 24, className }: IconProps) => {
  const Component = icons[name] || QuestionMarkOutlined;

  return (
    <Component
      className={className}
      style={{
        fontSize: size,
      }}
    />
  );
});
