import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { Image } from "../components/Image";
import { View } from "../components/View";
import { ROUTES } from "../utils/routes";
import { useNavigation } from "../hooks/useNavigation";

export const AppLayout = () => {
  const { pathname } = useLocation();
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.session.user);
  const isLoading = useAppSelector((state) => state.session.isLoadingSession);

  useEffect(() => {
    dispatch(sessionActions.getSession());
  }, []);

  useEffect(() => {
    if (user?.isAdmin) {
      if (!pathname.includes("/admin")) {
        navigation.navigate("/admin");
      }
    } else if (user && !pathname.includes("/onboarding")) {
      navigation.navigate(ROUTES.AUDIO_LIBRARY);
    }
  }, [user, pathname]);

  return isLoading ? (
    <div className="flex items-center justify-center w-full h-full">
      <div className="w-16 h-16 border-t-2 border-b-2 border-white rounded-full animate-spin" />
    </div>
  ) : user ? (
    <Outlet />
  ) : (
    <View className="relative w-full h-full flex flex-col items-center justify-center transition-all">
      <Image
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="https://api.primstories.com/v1/files/images/822ff9a5-68ba-4de1-87d1-ab159f970e37.webp"
      />
      <Outlet />
    </View>
  );
};
