import { DeleteOutlined, EditOutlined, Person } from "@mui/icons-material";

import { Button } from "./Button";
import { Image } from "./Image";
import { Text } from "./Text";
import { View } from "./View";

type AccountInfoCardProps = {
  name?: string;
  image?: string;
  onClickDelete?: () => void;
  onClickEdit?: () => void;
};

export const AccountInfoCard = ({ name, image, onClickDelete, onClickEdit }: AccountInfoCardProps) => {
  return (
    <View className="w-full flex items-center justify-between p-4 border-[1px] border-gray-400 rounded-lg">
      <View className="flex items-center gap-4">
        {image ? (
          <Image
            src={image}
            className="!w-12 !h-12 rounded-full"
          />
        ) : (
          <View className="flex items-center justify-center w-12 h-12 rounded-full border-4 border-gray-300 bg-gray-300 overflow-hidden">
            <Person className="mt-2 !w-12 !h-12 text-white" />
          </View>
        )}
        <Text className="text-lg truncate">{name}</Text>
      </View>
      <View className="flex items-center gap-2">
        <Button
          onClick={onClickDelete}
          type="basic"
          className="!w-8 !h-8"
        >
          <DeleteOutlined className="text-red-500 !w-6 !h-6" />
        </Button>
        <Button
          onClick={onClickEdit}
          type="basic"
          className="!w-8 !h-8"
        >
          <EditOutlined className="text-primary !w-6 !h-6" />
        </Button>
      </View>
    </View>
  );
};
