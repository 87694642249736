import clsx from "clsx";
import { View } from "./View";
import React, { useEffect } from "react";

type SlideGroupProps = {
  className?: string;
  currentSlide?: number;
  children?: React.ReactNode;
  direction?: "horizontal" | "vertical";
  onStartSlide?: (currentSlide: number) => void;
  onEndSlide?: (newSlide: number) => void;
};

export const SlideGroup = ({
  className,
  currentSlide = 0,
  children,
  direction = "horizontal",
  onStartSlide = () => {},
  onEndSlide = () => {},
}: SlideGroupProps) => {
  useEffect(() => {
    setTimeout(() => {
      onEndSlide(currentSlide);
    }, 700);

    return () => {
      onStartSlide(currentSlide);
    };
  }, [currentSlide]);

  return (
    <View className={clsx("relative flex items-center w-full h-full overflow-hidden", className)}>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child as React.ReactElement, {
          key: index,
          style: {
            transform: direction === "horizontal" ? `translateX(${(index - currentSlide) * 100}%)` : `translateY(${(index - currentSlide) * 100}%)`,
          },
        });
      })}
    </View>
  );
};
