import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

type RedirectPageProps = {
  className?: string;
  path: string;
};

export const RedirectPage = ({ className, path }: RedirectPageProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(path);
  }, []);

  return <div className={className} />;
};
