import clsx from "clsx";
import React, { useEffect, useRef } from "react";

import { FieldLabel } from "./FieldLabel";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

type CheckboxFieldProps = {
  rootClassName?: string;
  className?: string;
  label?: string;
  name?: string;
  onChange?: (value: boolean) => void;
  prefix?: React.ReactNode;
  value?: boolean;
  readOnly?: boolean;
};

export const CheckboxField = ({
  rootClassName,
  className,
  label,
  name,
  onChange = () => {},
  prefix,
  value,
  readOnly,
}: CheckboxFieldProps) => {
  const onChangeInternal = (event: CheckboxChangeEvent) => {
    onChange(event.target.checked);
  };

  return (
    <FieldLabel label={label}>
      <div className={clsx("relative w-full", rootClassName)}>
        <div className="absolute left-4 top-[10px] text-gray-700">{prefix}</div>
        <Checkbox
          className={clsx(
            "w-full rounded p-2 text-gray-900 outline-none shadow-lg max-h-[300px]",
            prefix ? "pl-12" : "pl-2",
            readOnly ? "bg-gray-200 border-gray-200" : "bg-white border-gray-300",
            className,
          )}
          onChange={onChangeInternal}
          name={name}
          checked={value}
        />
      </div>
    </FieldLabel>
  );
};
