import { createRestSlice } from "./restSlice";

export type Account = {
  id: string;
  subscriptionId: string;
  subscriptionRenewalDate: string;
  subscriptionStatus: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: null | string;
};

const accountSlice = createRestSlice<Account>("account", "/v1/accounts");

export const accountActions = accountSlice.actions;
export const accountReducer = accountSlice.reducer;
