import { useState } from "react";

import { Button } from "../../components/Button";
import { View } from "../../components/View";
import { InputField } from "../../components/InputField";
import { useApiRequest } from "../../hooks/useApiRequest";

export const AdminStoryBuilderPage = () => {
  const [content, setContent] = useState<string>("");
  const createChunksApiRequest = useApiRequest<{ chunks: string[] }>();

  const onClickCreateChunks = async () => {
    const response = await createChunksApiRequest.fetch("/v1/stories/create-chunks", {
      method: "POST",
      body: {
        content,
      },
    });

    console.log(response);
  };

  return (
    <View className="flex flex-col items-center justify-center w-full h-full p-4">
      <InputField
        labelClassName="flex-grow"
        className="flex-grow !indent-0"
        label="Story"
        placeholder="Enter story content"
        onChange={(value: string) => setContent(value)}
        value={content}
        type="textarea"
      />
      <Button
        onClick={onClickCreateChunks}
        isLoading={createChunksApiRequest.isLoading}
        isDisabled={!content}
      >
        Create Chunks
      </Button>
    </View>
  );
};
