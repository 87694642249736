import { CircularProgress } from "@mui/material";
import { LaunchOutlined } from "@mui/icons-material";
import { Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "../../components/Button";
import { InputField } from "../../components/InputField";
import { RestList, RestListButtonProps, RestListComponentProps } from "../../components/RestList";
import { Story, storyActions } from "../../redux/reducers/storySlice";
import { StoryChunk, storyChunkActions } from "../../redux/reducers/storyChunkSlice";
import { StoryTemplate } from "../../redux/reducers/storyTemplateSlice";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useAppDispatch, useAppSelector, webSocket } from "../../redux";
import { View } from "../../components/View";
import { World } from "../../redux/reducers/worldSlice";
import { Theme } from "../../redux/reducers/themeSlice";
import { useEntity } from "../../hooks/useEntity";
import { Character } from "../../redux/reducers/characterSlice";
import { AudioPlayer, AudioPlayerRef } from "../../components/AudioPlayer";
import { useSearchParam } from "react-use";
import { DropdownField } from "../../components/DropdownField";

type ChunkRowProps = {
  chunk: StoryChunk;
};

const ChunkRow = ({ chunk }: ChunkRowProps) => {
  const dispatch = useAppDispatch();
  const generateAudioRequest = useApiRequest<never>();
  const updateVideoRequest = useApiRequest<never>();
  const languageCode = useAppSelector((state) => state.session.languageCode) || "en-us";
  const characterEntities = useAppSelector((state) => state.character.entities);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const onClick = async () => {
    await generateAudioRequest.fetch(`/v1/stories/${chunk.storyId}/generate-audio/${chunk.id}`, {
      method: "POST",
    });

    dispatch(storyChunkActions.getByStoryId({ storyId: chunk.storyId, languageCode }));
  };

  const onClickRegenerate = async () => {
    await generateAudioRequest.fetch(`/v1/stories/${chunk.storyId}/generate-audio/${chunk.id}`, {
      method: "POST",
      body: {
        regenerate: true,
      },
    });

    dispatch(storyChunkActions.getByStoryId({ storyId: chunk.storyId, languageCode }));
  };

  const onClickUpdateVideo = () => {
    setVideoUrl(chunk.video || "");
    setIsVideoModalOpen(true);
  };

  const onClickConfirmUpdateVideo = async () => {
    await updateVideoRequest.fetch(`/v1/storyChunks/${chunk.id}`, {
      method: "PATCH",
      body: {
        video: videoUrl,
      },
    });

    setIsVideoModalOpen(false);

    dispatch(storyChunkActions.getByStoryId({ storyId: chunk.storyId, languageCode }));
  };

  const onClickCancelVideo = () => {
    setIsVideoModalOpen(false);
  };

  const onChangeVideoUrl = (value: string) => {
    setVideoUrl(value);
  };

  const onChangeCharacter = (value: string) => {
    dispatch(storyChunkActions.updateById({ id: chunk.id, characterId: value, audio: "" }));
  };

  return (
    <>
      <div
        key={chunk.id}
        className="grid items-center w-full border-b border-gray-300 py-2"
        style={{ gridTemplateColumns: "150px 1fr 64px 64px 64px 64px" }}
      >
        <DropdownField
          className="w-[142px] !h-8"
          options={Object.values(characterEntities).map((character) => ({
            label: character.name,
            value: character.id,
          }))}
          value={chunk.characterId}
          onChange={onChangeCharacter}
          searchable
        />
        <div className="truncate">{chunk.content}</div>
        <div
          className="flex flex-row items-center justify-center cursor-pointer"
          onClick={() => setIsPlaying(true)}
        >
          🔈
        </div>
        <div
          className="flex flex-row items-center justify-center cursor-pointer"
          onClick={onClickRegenerate}
        >
          🔄
        </div>
        <div
          className="flex flex-row items-center justify-center cursor-pointer"
          onClick={onClick}
        >
          {generateAudioRequest.isLoading ? <CircularProgress size={16} /> : chunk.audio ? "✅" : "❌"}
        </div>
        <div
          className="flex flex-row items-center justify-center cursor-pointer"
          onClick={onClickUpdateVideo}
        >
          {chunk.video ? "✅" : "❌"}
        </div>
      </div>
      <Modal
        open={isVideoModalOpen}
        onCancel={onClickCancelVideo}
        onOk={onClickConfirmUpdateVideo}
      >
        <InputField
          className="border-[1px] border-gray-300 shadow-none mt-8"
          placeholder="Add your video URL..."
          value={videoUrl}
          onChange={onChangeVideoUrl}
        />
      </Modal>
      {isPlaying && (
        <audio
          autoPlay
          src={chunk.audio}
          onEnded={() => setIsPlaying(false)}
        />
      )}
    </>
  );
};

type StoryChunkModalProps = {
  onClose: () => void;
};

const StoryChunkModal = ({ onClose }: StoryChunkModalProps) => {
  const audioRef = useRef<AudioPlayerRef>(null);
  const dispatch = useAppDispatch();
  const storyChunkEntities = useAppSelector((state) => state.storyChunk.entities);
  const storyEntities = useAppSelector((state) => state.story.entities);
  const languageCode = useAppSelector((state) => state.session.languageCode) || "en-us";
  const storyId = useSearchParam("id") || "";
  const storyChunks = Object.values(storyChunkEntities)
    .filter((chunk) => chunk.storyId === storyId)
    .sort((a, b) => a.index - b.index);
  const generateAllAudioRequest = useApiRequest<never>();
  const combineAudioRequest = useApiRequest<never>();

  const onClickCloseInternal = () => {
    audioRef.current?.pause();

    onClose();
  };

  const onClickGenerateAllAudioHandler = (id: string) => async () => {
    await generateAllAudioRequest.fetch(`/v1/stories/${id}/generate-audio`, {
      method: "POST",
    });
  };

  const onClickCombineAudioHandler = (id: string) => async () => {
    await combineAudioRequest.fetch(`/v1/stories/${id}/combine-audio`, {
      method: "POST",
    });

    dispatch(storyActions.getById(storyId));
  };

  useEffect(() => {
    dispatch(storyChunkActions.getByStoryId({ storyId, languageCode }));
  }, []);

  useEffect(() => {
    if (storyId) {
      webSocket.send(
        JSON.stringify({
          event: "joinRoom",
          data: {
            roomId: `${storyId}:debugger`,
          },
        }),
      );
    }
  }, [storyId]);

  return (
    <Modal
      open
      onCancel={onClickCloseInternal}
      width="80vw"
      footer={null}
    >
      <div className="flex flex-col w-full max-h-[70vh]">
        <div className="flex flex-row items-center justify-start w-full my-8">
          <AudioPlayer
            ref={audioRef}
            className="flex-grow"
            analysis={storyEntities[storyId]?.analysis}
            audioUrl={storyEntities[storyId]?.audioUrl}
          />
          <Button
            onClick={onClickGenerateAllAudioHandler(storyId)}
            type="outlined"
            className="!w-48 mr-2"
            isLoading={generateAllAudioRequest.isLoading}
          >
            Generate Audio
          </Button>
          <Button
            onClick={onClickCombineAudioHandler(storyId)}
            type="outlined"
            className="!w-48"
            isLoading={combineAudioRequest.isLoading}
          >
            Combine Audio
          </Button>
        </div>
        <div
          className="grid w-full font-semibold"
          style={{ gridTemplateColumns: "150px 1fr 64px 64px 64px 64px" }}
        >
          <div>Character</div>
          <div>Content</div>
          <div className="flex flex-row items-center justify-center"></div>
          <div className="flex flex-row items-center justify-center"></div>
          <div className="flex flex-row items-center justify-center">Audio</div>
          <div className="flex flex-row items-center justify-center">Video</div>
        </div>
        <div className="w-full overflow-y-auto">
          {storyChunks.map((chunk) => (
            <ChunkRow chunk={chunk} />
          ))}
          {storyChunks.length === 0 && <div className="flex flex-row items-center justify-center w-full h-64">No chunks found.</div>}
        </div>
      </div>
    </Modal>
  );
};

type AdminStoriesPageProps = {
  className?: string;
};

export const AdminStoriesPage = ({ className }: AdminStoriesPageProps) => {
  const navigate = useNavigate();
  const audioPreviewRef = useRef<AudioPlayerRef>(null);
  const [isChunksModalOpen, setIsChunksModalOpen] = useState<boolean>(false);
  const { entities: characterEntities } = useEntity<Character>("character");
  const { entities: storyTemplateEntities } = useEntity<StoryTemplate>("storyTemplate");
  const { entities: themeEntities } = useEntity<Theme>("theme");
  const { entities: worldEntities } = useEntity<World>("world");

  const onClickViewHandler = (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    navigate(`/story?id=${id}`);
  };

  const onClickViewChunks = (id: string) => () => {
    audioPreviewRef.current?.pause();

    setIsChunksModalOpen(true);
  };

  const onClickCloseViewChunks = () => {
    setIsChunksModalOpen(false);
  };

  const fields = [
    {
      name: "audioPlayer",
      label: "Audio Player",
      component: ({ formData }: RestListComponentProps<Story>) => (
        <AudioPlayer
          ref={audioPreviewRef}
          className="flex-grow mb-4 px-0"
          analysis={formData?.analysis}
          audioUrl={formData?.audioUrl}
        />
      ),
      visible: false,
    },
    {
      name: "title",
      label: "Title",
    },
    {
      name: "themeId",
      label: "Theme",
      type: "searchable_dropdown",
      visible: false,
      options: Object.values(themeEntities).map((theme) => ({
        label: theme.name,
        value: theme.id,
      })),
    },
    {
      name: "worldId",
      label: "World",
      type: "searchable_dropdown",
      visible: false,
      options: Object.values(worldEntities).map((world) => ({
        label: world.name,
        value: world.id,
      })),
    },
    {
      name: "storyTemplateId",
      label: "Template",
      type: "searchable_dropdown",
      visible: false,
      options: Object.values(storyTemplateEntities).map((template) => ({
        label: template.name,
        value: template.id,
      })),
    },
    {
      name: "narratorId",
      label: "Narrator",
      type: "searchable_dropdown",
      visible: false,
      options: Object.values(characterEntities).map((template) => ({
        label: template.name,
        value: template.id,
      })),
    },
    {
      name: "speakingCharacterIds",
      label: "Speaking Characters",
      type: "character_list",
      visible: false,
    },
    {
      name: "nonSpeakingCharacterIds",
      label: "Non-Speaking Characters",
      type: "character_list",
      visible: false,
    },
    {
      name: "tags",
      label: "Tags",
    },
    {
      name: "audioUrl",
      label: "Generated",
      type: "checkbox",
      readOnly: true,
    },
    {
      name: "isPublic",
      label: "Public",
      type: "checkbox",
      visible: false,
    },
    {
      name: "content",
      label: "Content",
      type: "textarea_modal",
      visible: false,
    },
    {
      name: "actions",
      label: "Actions",
      visible: false,
      component: ({ id }: RestListComponentProps<Story>) => (
        <View className="flex flex-col items-center justify-center w-full">
          {id !== "new" && (
            <Button
              className="mb-2"
              onClick={onClickViewChunks(id)}
              isDisabled={id === "new"}
              type="outlined"
            >
              View Chunks
            </Button>
          )}
        </View>
      ),
    },
    {
      name: "view",
      label: "View",
      button: ({ id }: RestListButtonProps<Story>) => (
        <Button
          onClick={onClickViewHandler(id)}
          type="basic"
        >
          <LaunchOutlined className="text-gray-500 group-hover:text-gray-700" />
        </Button>
      ),
    },
  ];

  return (
    <>
      <RestList<Story>
        className={className}
        entityName="story"
        fields={fields}
        sort={(ids, entities) => [...ids].sort((a, b) => entities[a]?.title.localeCompare(entities[b]?.title))}
      />
      {isChunksModalOpen && <StoryChunkModal onClose={onClickCloseViewChunks} />}
    </>
  );
};
