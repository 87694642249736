type ImageProps = {
  alt?: string;
  className?: string;
  src?: string;
};

export const Image = ({ className, src }: ImageProps) => {
  return (
    <div
      className={className}
      style={{
        backgroundImage: src && `url(${src})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    />
  );
};
