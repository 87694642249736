import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";

import { Button } from "../components/Button";
import { Character } from "../redux/reducers/characterSlice";
import { Icon } from "../components/base/Icon";
import { Story, storyActions } from "../redux/reducers/storySlice";
import { StorySet } from "../redux/reducers/storySetSlice";
import { useApiRequest } from "../hooks/useApiRequest";
import { useAppDispatch } from "../redux";
import { useSearchParams } from "../hooks/useSearchParams";
import { useWindowSize } from "react-use";
import { View } from "../components/View";
import { World } from "../redux/reducers/worldSlice";
import { WorldCard } from "../components/WorldCard";

type LibraryResponse = {
  data: {
    characters: Character[];
    stories: Story[];
    storySets: StorySet[];
    worlds: World[];
  };
};

type LibraryPageProps = {
  className?: string;
};

export const LibraryPage = ({ className }: LibraryPageProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [worlds, setWorlds] = useState<World[]>([]);
  const [storySets, setStorySets] = useState<StorySet[]>([]);
  const [stories, setStories] = useState<Story[]>([]);
  const [characters, setCharacters] = useState<Character[]>([]);
  const { searchParams, addSearchParam, removeSearchParam } = useSearchParams();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollPositionRef = useRef(0);
  const scrollContainerHeight = scrollContainerRef?.current?.offsetHeight || 0;
  const apiRequest = useApiRequest<LibraryResponse>();
  const [slideIndexLeft, setSlideIndexLeft] = useState(0);
  const [slideIndexRight, setSlideIndexRight] = useState(0);
  const { width: windowWidth } = useWindowSize();
  const [currentWorldIndex, setCurrentWorldIndex] = useState(0);
  const createStorySetApiRequest = useApiRequest<{ data: { collectionName: string; stories: { title: string; plot: string }[] } }>();
  const [newStories, setNewStories] = useState<Story[]>([]);

  const onClickUp = () => {
    scrollContainerRef.current?.scrollBy({
      top: -scrollContainerHeight,
      behavior: "smooth",
    });
  };

  const onClickDown = () => {
    scrollContainerRef.current?.scrollBy({
      top: scrollContainerHeight,
      behavior: "smooth",
    });
  };

  const onClickCreateStorySet = async (worldId: string, details: string, characters: Record<string, string>) => {
    const { data } = await createStorySetApiRequest.fetch("/v1/storySets/generate", {
      method: "POST",
      body: {
        details,
        worldId,
        narratorId: characters.narrator,
        characterIds: [characters.character_1, characters.character_2, characters.character_3].filter((id) => id),
      },
    });

    const formattedStories = data?.data.stories.map((story) => ({
      id: uuidv4(),
      title: story.title,
      content: story.plot,
    })) as unknown as Story[];

    setNewStories(formattedStories || []);
  };

  useEffect(() => {
    const storySetId = searchParams.get("g");
    const storySet = storySets.find((storySet) => storySet.id === storySetId);

    if (!storySetId) {
      setSlideIndexLeft(0);
      setSlideIndexRight(0);
    }

    if (storySetId === "new") {
      if (createStorySetApiRequest.data) {
        setSlideIndexLeft(2);
        setSlideIndexRight(2);
      } else {
        setSlideIndexLeft(1);
        setSlideIndexRight(1);
      }
    } else if (storySetId) {
      dispatch(storyActions.get({ storyIds: storySet?.storyIds.join(",") || "" }));
      setSlideIndexLeft(1);
    }
  }, [searchParams, storySets, createStorySetApiRequest.data]);

  useEffect(() => {
    const newWorldIndex = Math.round(scrollPosition / scrollContainerHeight);

    if (newWorldIndex !== currentWorldIndex) {
      removeSearchParam("g");
      setCurrentWorldIndex(newWorldIndex);
    }
  }, [scrollPosition]);

  const onClickBack = () => {
    removeSearchParam("s");
  };

  useEffect(() => {
    apiRequest.fetch("/v1/content/library").then((response) => {
      setCharacters(response.data?.data.characters || []);
      setStories(response.data?.data.stories || []);
      setStorySets(response.data?.data.storySets || []);
      setWorlds(response.data?.data.worlds || []);
    });

    const onScroll = () => {
      const scrollPosition = scrollContainerRef.current?.scrollTop || 0;

      if (Math.abs(scrollPosition - scrollPositionRef.current) > 2) {
        setScrollPosition(scrollPosition);
      }

      scrollPositionRef.current = scrollPosition;
    };

    scrollContainerRef.current?.addEventListener("scroll", onScroll);

    return () => {
      scrollContainerRef.current?.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <View className={clsx("relative w-full h-full transition-opacity duration-700", apiRequest.data ? "opacity-100" : "opacity-0", className)}>
      <View
        className={clsx(
          "flex flex-col w-full h-full snap-mandatory snap-y bg-black overflow-x-hidden overscroll-none",
          searchParams.get("s") ? "overflow-y-hidden" : "overflow-y-scroll",
        )}
        ref={scrollContainerRef}
      >
        {worlds.map((world, index) => {
          const currentIndex = Math.floor(Math.round(scrollPosition) / scrollContainerHeight);

          let opacity = 1;

          if (index === currentIndex) {
            opacity = 1 - (Math.round(scrollPosition) / scrollContainerHeight - index);
          }

          if (index === currentIndex + 1) {
            opacity = 1 + (Math.round(scrollPosition) / scrollContainerHeight - index);
          }

          return (
            <WorldCard
              key={world.id + index}
              world={world}
              className="snap-center"
              characters={characters.filter((character) => character.worldId === world.id)}
              storySets={storySets.filter((storySet) => storySet.worldId === world.id)}
              stories={stories.filter((story) => story.worldId === world.id)}
              isSelected={index === Math.round(scrollPosition / scrollContainerHeight)}
              slideIndexLeft={index === Math.round(scrollPosition / scrollContainerHeight) ? slideIndexLeft : 0}
              slideIndexRight={index === Math.round(scrollPosition / scrollContainerHeight) ? slideIndexRight : 0}
              onClickCreateStorySet={onClickCreateStorySet}
              newStories={newStories}
              newStorySetName={createStorySetApiRequest.data?.data.collectionName}
              createStorySetLoading={createStorySetApiRequest.isLoading}
              style={{
                opacity: Math.max(0.3, Math.min(1, opacity)),
              }}
            />
          );
        })}
      </View>
      {windowWidth > 768 && (
        <Button
          className={clsx(
            "!absolute bottom-8 left-8 !w-16 !h-16 bg-white z-10 transition-all !duration-700 active:scale-95 shadow-avatar backdrop-blur-md bg-opacity-80",
            searchParams.get("s") ? "opacity-100 delay-700" : "opacity-0 !cursor-default",
          )}
          type="basic"
          onClick={searchParams.get("s") ? onClickBack : undefined}
        >
          <Icon
            name="arrow-back"
            className="text-black"
            size={48}
          />
        </Button>
      )}
      {!searchParams.get("p") && (
        <>
          <Button
            className={clsx(
              "invisible md:visible !absolute top-[88px] right-8 !w-16 !h-16 bg-white z-10 transition-all !duration-700 active:scale-95 shadow-avatar backdrop-blur-md bg-opacity-80",
              searchParams.get("s") ? "!opacity-0 !cursor-default" : "opacity-100 delay-700",
              searchParams.get("p") ? "invisible" : "visible",
              scrollPosition > 50 ? "opacity-100 !delay-0" : "!opacity-0 !delay-0",
            )}
            type="basic"
            onClick={searchParams.get("s") ? undefined : onClickUp}
          >
            <Icon
              name="arrow-up"
              className="text-black"
              size={48}
            />
          </Button>
          <Button
            className={clsx(
              "invisible md:visible !absolute bottom-8 right-8 !w-16 !h-16 bg-white z-10 transition-all !duration-700 active:scale-95 shadow-avatar backdrop-blur-md bg-opacity-80",
              searchParams.get("s") ? "!opacity-0 !cursor-default z-0" : "opacity-100 delay-700",
              scrollPosition < scrollContainerHeight * (worlds.length - 2) + 50 ? "opacity-100 !delay-0" : "!opacity-0 !delay-0",
            )}
            type="basic"
            onClick={searchParams.get("s") ? undefined : onClickDown}
          >
            <Icon
              name="arrow-down"
              className="text-black"
              size={48}
            />
          </Button>
        </>
      )}
    </View>
  );
};
