import { AddRounded, CloseRounded } from "@mui/icons-material";
import { Modal } from "antd";
import { useState } from "react";

import { Avatar } from "./Avatar";
import { Button } from "./Button";
import { Character } from "../redux/reducers/characterSlice";
import { FieldLabel } from "./FieldLabel";
import { InputField } from "./InputField";
import { Text } from "./Text";
import { useEntity } from "../hooks/useEntity";
import { View } from "./View";
import clsx from "clsx";

type CharacterListFieldProps = {
  label?: string;
  value: string[];
  onChange: (value: string[]) => void;
  size?: "small" | "medium" | "large";
  className?: string;
};

export const CharacterListField = ({ label, value = [], onChange = () => {}, size = "medium", className }: CharacterListFieldProps) => {
  const { entities: characterEntities } = useEntity<Character>("character");
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const onChangeSearchText = (value: string) => {
    setSearchText(value);
  };

  const onClickAddCharacter = () => {
    setIsOpen(true);
  };

  const onClickCancel = () => {
    setIsOpen(false);
  };

  const onClickCharacterHandler = (id: string) => () => {
    onChange([...value, id]);

    setIsOpen(false);
  };

  const onClickRemoveHandler = (id: string) => () => {
    onChange([...value].filter((characterId) => characterId !== id));
  };

  return (
    <FieldLabel label={label}>
      <View className={clsx("flex items-center justify-start w-full flex-wrap p-2 gap-4", className)}>
        {value?.map((id) => (
          <View className="relative">
            <Avatar
              name={characterEntities[id]?.name}
              image={characterEntities[id]?.image}
              className={clsx("!w-16 !h-16", size === "small" ? "!w-12 !h-12" : size === "large" ? "!w-24 !h-24" : "")}
            />
            <Button
              type="basic"
              className="!absolute !top-0 !-right-2 !w-6 !h-6 !rounded-full !bg-red-500"
              onClick={onClickRemoveHandler(id)}
            >
              <CloseRounded className="!w-4 !h-4 !text-white -mt-0.5" />
            </Button>
          </View>
        ))}
        <Button
          type="basic"
          onClick={onClickAddCharacter}
          className={clsx(
            "!w-16 !h-16 !rounded-full border-2 border-gray-700 border-dashed",
            size === "small" ? "!w-12 !h-12" : size === "large" ? "!w-24 !h-24" : "",
          )}
        >
          <AddRounded className="!w-8 !h-8 !text-gray-700" />
        </Button>
      </View>
      <Modal
        open={isOpen}
        onCancel={onClickCancel}
        footer={null}
        width="80vw"
        bodyProps={{
          style: {
            height: "70vh",
            overflow: "hidden",
            paddingTop: 32,
          },
        }}
      >
        <InputField
          value={searchText}
          onChange={onChangeSearchText}
        />
        <View className="w-full h-[calc(100%-52px)] flex flex-wrap items-start justify-start overflow-y-scroll">
          {Object.values(characterEntities)
            .filter((character) => {
              if (!searchText) {
                return true;
              }

              return character.name.toLowerCase().includes(searchText.toLowerCase());
            })
            .map((character) => (
              <View
                key={character.id}
                className="flex flex-col items-center justify-start"
              >
                <Avatar
                  key={character.id}
                  name={character.name}
                  image={character.image}
                  role={character.role}
                  className="!w-24 !h-24 m-4 cursor-pointer"
                  onClick={onClickCharacterHandler(character.id)}
                  isDisabledSpeaking
                />
                <Text>{character.name}</Text>
              </View>
            ))}
        </View>
      </Modal>
    </FieldLabel>
  );
};
