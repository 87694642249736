import { useState } from "react";

import { accountReaderActions } from "../../redux/reducers/accountReaderSlice";
import { Form } from "../../components/base/Form";
import { Haptics } from "../../utils/haptics";
import { InputField } from "../../components/base/InputField";
import { OnboardingCard } from "../../components/OnboardingCard";
import { ROUTES } from "../../utils/routes";
import { sessionActions } from "../../redux/reducers/sessionSlice";
import { Text } from "../../components/base/Text";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useNavigation } from "../../hooks/useNavigation";
import { useRoute } from "../../hooks/useRoute";

type FormData = {
  description?: string;
};

type Route = {
  params: {
    onboardingType: "account" | "contributor";
  };
};

export const OnboardingReaderDescriptionPage = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const route = useRoute<Route>();
  const isLoading = useAppSelector((state) => state.accountReader.isLoading);
  const reader = useAppSelector((state) => state.session.user?.account?.readers?.[0]);
  const [formData, setFormData] = useState<FormData>({});

  const onChangeFormData = (newFormData: FormData) => {
    setFormData(newFormData);
  };

  const onClickContinue = async () => {
    Haptics.trigger(Haptics.HapticFeedbackTypes.impactLight);

    await dispatch(
      accountReaderActions.updateById({
        id: reader?.id,
        description: formData.description,
      }),
    );

    dispatch(sessionActions.getSession());

    navigation.navigate(ROUTES.ONBOARDING_READER_GENDER, { onboardingType: route.params.onboardingType });
  };

  return (
    <OnboardingCard
      title={<Text>Tell us about {reader?.name ? <Text className="text-secondary">{reader?.name}'s</Text> : "their"} personality.</Text>}
      subtitle="Let's bring their unique traits into the story! 😊"
      currentStep={3}
      totalSteps={7}
      onClickContinue={onClickContinue}
      continueDisabled={!formData.description}
      continueLoading={isLoading}
    >
      <Form
        data={formData}
        onChange={onChangeFormData}
        className="mb-4"
      >
        <InputField
          type="textarea"
          name="description"
          placeholder={`Write a brief description about ${reader?.name || "your child"}`}
          className="!h-[200px]"
        />
      </Form>
    </OnboardingCard>
  );
};
