import { useState } from "react";

import { Button } from "../../components/Button";
import { RestList, RestListComponentProps } from "../../components/RestList";
import { makeRequest } from "../../utils/request";
import { Character } from "../../redux/reducers/characterSlice";
import { API_URL } from "../../config";
import { useEntity } from "../../hooks/useEntity";
import { World } from "../../redux/reducers/worldSlice";
import { Avatar } from "../../components/Avatar";
import { useNavigate } from "react-router-dom";
import { Voice } from "../../redux/reducers/voiceSlice";

type AdminCharactersPageProps = {
  className?: string;
};

const getImageUrl = (id: string) => {
  if (!id) {
    return "https://t3.ftcdn.net/jpg/01/18/01/98/360_F_118019822_6CKXP6rXmVhDOzbXZlLqEM2ya4HhYzSV.jpg";
  }

  if (id.startsWith("http")) {
    return id;
  }

  return `${API_URL}/v1/files/images/${id}.png`;
};

export const AdminCharactersPage = ({ className }: AdminCharactersPageProps) => {
  const navigate = useNavigate();
  const { entities: voiceEntities } = useEntity<Voice>("voice");
  const { entities: worldEntities } = useEntity<World>("world");
  const [isLoadingGenerateImage, setIsLoadingGenerateImage] = useState(false);

  const onClickGenerateImageHandler = (id: string, name: string, description: string, onChange: (value: string) => void) => async () => {
    setIsLoadingGenerateImage(true);

    const response = await makeRequest<{ data: string }>(`/v1/characters/${id}/generate-image`, {
      method: "POST",
      body: {
        name,
        description,
      },
    });

    onChange(response.data);
    setIsLoadingGenerateImage(false);
  };

  const onSelect = () => {
    setIsLoadingGenerateImage(false);
  };

  const onClickWorldHandler = (id: string) => () => {
    navigate(`/admin/worlds?id=${id}`);
  };

  const fields = [
    {
      name: "image",
      label: "Image",
      component: ({ formData, value, onChange }: RestListComponentProps<Character>) => (
        <div className="flex flex-col items-center justify-center mb-4">
          <div
            className="flex flex-col items-center justify-center mb-4 w-full p-2 no-repeat bg-cover bg-center bg-gray-300 rounded-lg cursor-pointer"
            style={{ backgroundImage: `url(${worldEntities[formData?.worldId]?.image})` }}
            onClick={onClickWorldHandler(formData?.worldId)}
          >
            <Avatar
              name={formData?.name}
              characterId={formData?.id}
              image={getImageUrl(value)}
              className="!w-[72px] !h-[72px] mb-2"
            />
          </div>
          <Button
            onClick={onClickGenerateImageHandler(formData?.id, formData?.name, formData?.description, onChange)}
            isLoading={isLoadingGenerateImage}
          >
            {value ? "Regenerate Image" : "Generate Image"}
          </Button>
        </div>
      ),
      visible: false,
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "pronunciation",
      label: "Pronunciation",
    },
    {
      name: "worldId",
      label: "World",
      type: "searchable_dropdown",
      visible: false,
      options: Object.values(worldEntities).map((world) => ({
        label: world.name,
        value: world.id,
      })),
    },
    {
      name: "role",
      label: "Role",
      type: "dropdown",
      visible: false,
      options: ["main", "guide", "companion", "animal"].map((key) => ({
        label: key,
        value: key,
      })),
    },
    {
      name: "voiceId",
      label: "Voice",
      type: "searchable_dropdown",
      visible: false,
      options: Object.values(voiceEntities).map((voice) => ({
        label: voice.name,
        value: voice.id,
      })),
    },
    {
      name: "image",
      label: "Image",
      visible: false,
    },
    {
      name: "isPublic",
      label: "Public",
      type: "checkbox",
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
    },
  ];

  return (
    <RestList<Character>
      className={className}
      entityName="character"
      fields={fields}
      onSelect={onSelect}
      sort={(ids, entities) => [...ids].sort((a, b) => entities[a]?.name.localeCompare(entities[b]?.name))}
    />
  );
};
