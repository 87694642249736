import clsx from "clsx";
import { FileUploader } from "react-drag-drop-files";

import { Text } from "./base/Text";
import { View } from "./base/View";

type FileFieldProps = {
  type?: "button" | "drag-drop";
  className?: string;
  fileTypes?: string[];
  onChange: (files: FileList) => void | Promise<void>;
  value?: FileList;
  size?: "sm" | "md" | "lg";
};

export const FileField = ({ className, onChange, fileTypes, value, type = "drag-drop", size = "lg" }: FileFieldProps) => {
  return (
    <View
      className={clsx(
        "relative w-full h-full flex items-center justify-center rounded-lg bg-white border-dashed border-2 border-gray-300 shadow-inner max-h-[52px]",
        type === "button" && "cursor-pointer !bg-white !text-primary !border !border-primary",
        className,
      )}
    >
      <View
        className={clsx(
          "absolute flex items-center justify-center w-full gap-2",
          size === "sm" && "flex-row text-sm",
          size === "md" && "flex-col text-base",
          size === "lg" && "flex-col text-2xl",
        )}
      >
        {value && value.length > 0 ? (
          <View className="flex flex-row items-center justify-center w-full">
            <Text className="text-primary">File Uploaded!</Text>
          </View>
        ) : type === "button" ? (
          <Text className="text-primary">Upload File</Text>
        ) : (
          <>
            <Text className="text-gray-800">Drag & Drop Files</Text>
            <Text className="text-gray-800">- or -</Text>
            <Text className="text-gray-800">Click to Upload</Text>
          </>
        )}
      </View>
      <FileUploader
        classes="absolute top-0 left-0 bottom-0 right-0 !w-full !h-full !max-w-none !max-h-[100%] z-0 opacity-0 cursor-pointer"
        handleChange={onChange}
        name="file"
        types={fileTypes}
        multiple
      />
    </View>
  );
};
