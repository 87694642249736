import { ArrowBackRounded, MenuOutlined, SettingsOutlined } from "@mui/icons-material";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";

import { useAppDispatch, useAppSelector } from "../redux";
import { MenuNavigation } from "../components/MenuNavigation";
import { Button } from "../components/Button";
import { Text } from "../components/Text";
import { View } from "../components/View";
import { Image } from "../components/Image";
import { storyActions } from "../redux/reducers/storySlice";
import { useWindowSize } from "react-use";

type AuthenticatedLayoutProps = {
  className?: string;
};

export const AuthenticatedLayout = ({ className }: AuthenticatedLayoutProps) => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.session.user);
  const reader = useAppSelector((state) => state.session.user?.account?.readers?.find((reader) => reader.id === state.session.selectedReaderId));
  const storyEntities = useAppSelector((state) => state.story.entities);
  const selectedReaderId = useAppSelector((state) => state.session.selectedReaderId);
  const [isMenuNavigationOpen, setIsMenuNavigationOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const { width: windowWidth } = useWindowSize();

  const onClickHome = () => {
    navigate("/");
  };

  const onClickMenu = () => {
    setIsMenuNavigationOpen(!isMenuNavigationOpen);
  };

  const onClickSettings = () => {
    navigate("/admin");
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const title = useMemo(() => {
    const storyId = searchParams.get("s");

    if (pathname === "/library") {
      return storyId ? storyEntities[storyId]?.title : `${reader?.name}'s Library`;
    }

    return "";
  }, [pathname, reader, searchParams, storyEntities]);

  useEffect(() => {
    if (user) {
      if (!selectedReaderId) {
        navigate("/");
      } else if (pathname === "/") {
        navigate("/library");
      }
    } else if (!user && pathname !== "/story") {
      const params = new URLSearchParams();

      searchParams.forEach((value, key) => {
        params.append(key, value);
      });

      navigate(`/signin?redirect=${pathname}?${params.toString()}`);
    }
  }, [user, selectedReaderId]);

  useEffect(() => {
    const storyId = searchParams.get("s");

    if (storyId) {
      dispatch(storyActions.getById(storyId));
    }
  }, [searchParams]);

  return user || pathname === "/story" ? (
    <View className={clsx("flex flex-row items-center justify-start w-full h-full overflow-hidden", className)}>
      {pathname !== "/" && (
        <View className={clsx("h-full transition-all overflow-hidden shadow-inner", isMenuNavigationOpen ? "w-48 min-w-48" : "w-0 min-w-0")}>
          <MenuNavigation />
        </View>
      )}
      <View className="relative flex flex-col flex-grow items-center justify-start h-full overflow-hidden">
        {pathname !== "/" && (
          <View
            className={clsx(
              "absolute flex flex-row items-center justify-between w-full h-[56px] px-4 py-2 border-b-[1px] border-b-gray-200 gap-2.5 z-10 bg-white backdrop-blur-md bg-opacity-80 z-50",
              isMenuNavigationOpen ? "" : "",
            )}
          >
            <View className="flex items-center">
              {searchParams.get("s") && windowWidth < 768 ? (
                <>
                  <Button
                    type="basic"
                    className="!w-8 !p-0"
                    onClick={onClickBack}
                  >
                    <ArrowBackRounded
                      className="!w-6 !h-6"
                      htmlColor="#454545"
                    />
                  </Button>
                  <View className="flex items-center w-8 ml-2"></View>
                </>
              ) : (
                <>
                  <Button
                    type="basic"
                    className="!w-8 !p-0"
                    onClick={onClickHome}
                  >
                    <Image
                      className="w-5 h-5 mt-0.5"
                      src="/logo.png"
                    />
                  </Button>
                  <Button
                    type="basic"
                    className="!w-8 !p-0 mr-2"
                    onClick={onClickMenu}
                  >
                    <MenuOutlined
                      className="!w-6 !h-6"
                      htmlColor="#454545"
                    />
                  </Button>
                </>
              )}
            </View>
            <Text className="flex items-center text-lg md:text-3xl sm:text-2xl text-gray-700 duration-700 truncate">{title}</Text>
            <View className="flex items-center">
              <View className="flex items-center w-8 ml-2"></View>
              <Button
                type="basic"
                className="!w-8 !p-0"
                onClick={onClickSettings}
              >
                <SettingsOutlined
                  className="!w-6 !h-6"
                  htmlColor="#454545"
                />
              </Button>
            </View>
          </View>
        )}
        <View className="relative flex flex-grow w-full h-[100vh] overflow-hidden">
          <Outlet />
        </View>
      </View>
    </View>
  ) : (
    <View className={className} />
  );
};
