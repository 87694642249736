import clsx from "clsx";

import { Button } from "./Button";
import { View } from "./View";
import { Text } from "./Text";

type ListItemProps = {
  onClick?: () => void;
  leftIcon?: React.ReactNode;
  leftIconClassName?: string;
  title?: string;
  subtitle?: string;
  className?: string;
  rightIcon?: React.ReactNode;
  rightIconClassName?: string;
};

export const ListItem = ({ onClick, leftIcon, leftIconClassName, title, subtitle, className, rightIcon, rightIconClassName }: ListItemProps) => {
  return (
    <Button
      key="new"
      type="basic"
      className={clsx("flex items-center justify-between gap-4 px-4 py-2 bg-white mb-2 border-[1px] border-gray-300", className)}
      onClick={onClick}
    >
      <View className="flex items-center justify-start gap-4">
        {leftIcon && (
          <View
            className={clsx(
              "flex items-center justify-center w-12 h-12 min-w-12 min-h-12 bg-primary border-primary border-[1px] border-dashed text-xs font-bold rounded-full",
              leftIconClassName,
            )}
          >
            {leftIcon}
          </View>
        )}
        <View className="flex flex-col items-start">
          <Text className="text-xl font-semibold line-clamp-2">{title}</Text>
          {subtitle && <Text className="text-sm text-gray-500">{subtitle}</Text>}
        </View>
      </View>
      {rightIcon && (
        <View
          className={clsx(
            "flex items-center justify-center w-12 h-12 min-w-12 min-h-12 bg-transparent border-primary border-[1px] border-dashed text-xs font-bold rounded-full",
            rightIconClassName,
          )}
        >
          {rightIcon}
        </View>
      )}
    </Button>
  );
};
