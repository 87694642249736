import { useState } from "react";

import { accountContributorActions } from "../../redux/reducers/accountContributorSlice";
import { AudioField } from "../../components/AudioField";
import { Button } from "../../components/base/Button";
import { Haptics } from "../../utils/haptics";
import { OnboardingCard } from "../../components/OnboardingCard";
import { ROUTES } from "../../utils/routes";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useNavigation } from "../../hooks/useNavigation";
import { useRoute } from "../../hooks/useRoute";

type Route = {
  params: {
    onboardingType: "contributor" | "account";
    contributorId?: string;
  };
};

export const OnboardingContributorVoicePage = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const route = useRoute<Route>();
  const isLoadingUploadVoice = useAppSelector((state) => state.accountContributor.isLoadingCreate);
  const [audioFile, setAudioFile] = useState<File>();

  const onClickContinue = async () => {
    Haptics.trigger(Haptics.HapticFeedbackTypes.impactLight);

    if (audioFile) {
      await dispatch(
        accountContributorActions.uploadVoice({
          id: route.params.contributorId || "",
          file: audioFile,
        }),
      );

      navigation.navigate(ROUTES.ONBOARDING_COMPLETE, { onboardingType: route.params.onboardingType });
    } else {
      alert("Please record or select an audio file.");
    }
  };

  const onClickSkip = () => {
    navigation.navigate(ROUTES.ONBOARDING_COMPLETE, { onboardingType: route.params.onboardingType });
  };

  return (
    <OnboardingCard
      title="Record your voice! 🎤"
      className="h-[80vh] max-h-[800px]"
      currentStep={route.params.onboardingType === "contributor" ? 3 : 5}
      totalSteps={route.params.onboardingType === "contributor" ? 3 : 5}
      onClickContinue={onClickContinue}
      continueLoading={isLoadingUploadVoice}
      continueDisabled={!audioFile}
    >
      <AudioField
        onChange={(file) => setAudioFile(file)}
        value={!!audioFile}
        hideLabel
      />
      <Button
        color="white"
        type="link"
        onClick={onClickSkip}
        className="w-full mb-4"
      >
        Skip the voice for now
      </Button>
    </OnboardingCard>
  );
};
