import { makeRequest } from "../../utils/request";
import { RestList } from "../../components/RestList";
import { User } from "../../redux/reducers/userSlice";

type AdminUsersPageProps = {
  className?: string;
};

export const AdminUsersPage = ({ className }: AdminUsersPageProps) => {
  const search = async (query: string) => {
    try {
      const { data } = await makeRequest<{ data: User[] }>(`/v1/users/search?q=${query}`);

      return data.map((user) => user.id);
    } catch (error) {
      console.error("Error searching users:", error);
    }

    return [];
  };

  return (
    <RestList
      className={className}
      entityName="user"
      search={search}
      fields={[
        {
          name: "image",
          label: "Image",
          type: "image",
          visible: false,
        },
        {
          name: "id",
          label: "ID",
          readOnly: true,
        },
        {
          name: "name",
          label: "Name",
        },
        {
          name: "email",
          label: "Email",
        },
        {
          name: "accountId",
          label: "Account Id",
        },
        {
          name: "isAdmin",
          label: "Admin",
          type: "checkbox",
        },
      ]}
    />
  );
};
