import { useEffect, useState } from "react";
import { DeleteOutlined } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../redux";
import { accountReaderActions } from "../redux/reducers/accountReaderSlice";
import { Button } from "./Button";
import { InputField } from "./InputField";
import { Modal } from "./Modal";
import { View } from "./base/View";
import { Text } from "./base/Text";

type AccountReaderModalProps = {
  accountId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const AccountReaderModal = ({ accountId, isOpen, onClose }: AccountReaderModalProps) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.accountReader.isLoading);
  const isLoadingCreate = useAppSelector((state) => state.accountReader.isLoadingCreate);
  const isLoadingDelete = useAppSelector((state) => state.accountReader.isLoadingDelete);
  const accountReeaders = useAppSelector((state) => Object.values(state.accountReader.entities).filter((contributor) => contributor.accountId === accountId));
  const [name, setName] = useState<string>("");

  const onClickAccountReader = async () => {
    dispatch(
      accountReaderActions.create({
        accountId,
        name,
      }),
    );
  };

  const onClickDeleteAccountContributorHandler = (id: string) => async () => {
    dispatch(accountReaderActions.deleteById(id));
  };

  useEffect(() => {
    if (accountId) {
      dispatch(accountReaderActions.getByAccountId(accountId));
    }
  }, [accountId]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Account Readers"
      className="min-w-[80vw]"
    >
      <View className="flex flex-col w-full">
        <View
          className="grid w-full gap-4 mb-4"
          style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr" }}
        >
          <InputField
            name="name"
            placeholder="Name"
            value={name}
            onChange={(value) => setName(value)}
          />
          <Button
            type="outlined"
            onClick={onClickAccountReader}
            isLoading={isLoadingCreate}
          >
            Create
          </Button>
        </View>
        <View
          className="grid w-full pb-2 font-semibold border-b border-gray-300"
          style={{ gridTemplateColumns: "1fr 1fr 64px 64px" }}
        >
          <View>Name</View>
        </View>
        {isLoading ? (
          <View className="flex flex-row items-center justify-center w-full h-64">Loading...</View>
        ) : (
          <View className="w-full overflow-y-auto">
            {accountReeaders.map((contributor) => (
              <View
                className="grid items-center border-b border-gray-300 p-2"
                style={{ gridTemplateColumns: "1fr 1fr 64px 64px" }}
              >
                <Text>{contributor.name}</Text>
                <Button
                  type="basic"
                  onClick={onClickDeleteAccountContributorHandler(contributor.id)}
                  isLoading={isLoadingDelete}
                >
                  <DeleteOutlined className="text-red-500" />
                </Button>
              </View>
            ))}
            {accountReeaders.length === 0 && <View className="flex flex-row items-center justify-center w-full h-64">No readers found.</View>}
          </View>
        )}
      </View>
    </Modal>
  );
};
