import { createRestSlice } from "./restSlice";

export type StoryTemplate = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

const storyTemplateSlice = createRestSlice<StoryTemplate>("storyTemplate", "/v1/storyTemplates");

export const storyTemplateActions = storyTemplateSlice.actions;
export const storyTemplateReducer = storyTemplateSlice.reducer;
