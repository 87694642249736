import { CheckOutlined, ChevronLeftOutlined } from "@mui/icons-material";

import { FieldLabel } from "./FieldLabel";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { View } from "./View";
import { Text } from "./Text";

export type DropdownFieldOption = {
  label: string;
  value: string;
};

export type DropdownFieldProps = {
  rootClassName?: string;
  className?: string;
  label?: string;
  options?: DropdownFieldOption[];
  onChange?: (value: string) => void;
  value?: string;
  readOnly?: boolean;
  searchable?: boolean;
  type?: string;
};

export const DropdownField = ({ rootClassName, className, label, onChange = () => {}, options, value, readOnly, searchable, type }: DropdownFieldProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClick = () => {
    if (!readOnly) {
      setIsOpen(!isOpen);
    }

    requestAnimationFrame(() => {
      inputRef.current?.focus();
    });
  };

  const onClickItemHandler = (value: string) => () => {
    onChange(value);
    setSearchText("");
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const onFocusInput = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    const onClickOff = (event: MouseEvent) => {
      const clickedInputRef = inputRef.current && inputRef.current.contains(event.target as Node);
      const clickedButtonRef = buttonRef.current && buttonRef.current.contains(event.target as Node);

      if (!clickedInputRef && !clickedButtonRef) {
        setIsOpen(false);
        setSearchText("");
      }
    };

    document.addEventListener("click", onClickOff);

    return () => {
      document.removeEventListener("click", onClickOff);
    };
  });

  return (
    <FieldLabel
      className={rootClassName}
      label={label}
    >
      <div className={clsx("relative w-full admin-layout-dropdown", className)}>
        <View className={clsx("relative h-full", searchable && isOpen ? "flex" : "hidden")}>
          <input
            className={clsx(
              "w-full h-full flex items-center justify-between rounded-md p-2 text-xl font-medium text-gray-700 focus:outline-none indent-2",
              type !== "basic" && (!isOpen ? "border-[1px]  border-gray-300 px-2" : "border-2 border-secondary px-[7px]"),
              readOnly ? "bg-gray-200" : "bg-white",
            )}
            value={searchText}
            onChange={onChangeInput}
            onFocus={onFocusInput}
            ref={inputRef}
          />
          {!readOnly && (
            <ChevronLeftOutlined
              className="absolute top-1/2 -translate-y-1/2 right-[9px] ml-2 h-5 w-5 -rotate-90 text-gray-700"
              aria-hidden="true"
            />
          )}
        </View>
        <button
          type="button"
          className={clsx(
            "h-full flex items-center justify-between w-full rounded-md p-2 text-xl font-medium text-gray-700 focus:outline-none",
            type !== "basic" && (!isOpen ? "border-[1px]  border-gray-300 px-2" : "border-2 border-secondary px-[7px]"),
            readOnly ? "bg-gray-200" : "bg-white",
            !searchable ? "flex" : !isOpen ? "flex" : "hidden",
          )}
          id="options-menu"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={onClick}
          ref={buttonRef}
        >
          {value ? (
            <span className="pl-2 truncate">{options?.find((option) => option.value === value)?.label}</span>
          ) : (
            <span className="placeholder">{readOnly ? "" : "Select an option"}</span>
          )}
          {!readOnly && (
            <ChevronLeftOutlined
              className="ml-2 h-5 w-5 -rotate-90 text-gray-700"
              aria-hidden="true"
            />
          )}
        </button>
        {isOpen && (
          <div
            className="origin-top-left absolute z-50 left-0 mt-2 w-full max-h-[200px] overflow-y-scroll min-w-fit rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none border-[1px] border-gray-300"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options
              ?.filter((option) => {
                return searchable ? option.label.toLowerCase().includes(searchText.toLowerCase()) : true;
              })
              .map((option) => (
                <button
                  key={option.value}
                  onClick={onClickItemHandler(option.value)}
                  className="relative block w-full text-left px-8 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  {value === option.value && (
                    <span className="absolute left-0 flex items-center pl-2">
                      <CheckOutlined
                        className="!h-[20px] !w-[20px] text-secondary"
                        aria-hidden="true"
                      />
                    </span>
                  )}
                  <Text className="truncate">{option.label}</Text>
                </button>
              ))}
          </div>
        )}
      </div>
    </FieldLabel>
  );
};
