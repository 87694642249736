import { clsx } from "clsx";

import { Image } from "./Image";
import { API_URL } from "../config";
import { Button } from "./Button";
import { useAudioPlayer } from "../hooks/useAudioPlayer";
import { View } from "./View";
import { Person2, PetsOutlined, StarOutlined, Visibility } from "@mui/icons-material";
import { useRef, useState } from "react";
import { CircularProgress } from "@mui/material";

type AvatarProps = {
  className?: string;
  image?: string;
  name?: string;
  role?: string;
  characterId?: string;
  isDisabled?: boolean;
  isDisabledSpeaking?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
};

export const Avatar = ({
  className,
  characterId,
  image,
  name,
  role,
  onClick = () => {},
  style,
  isDisabled = false,
  isDisabledSpeaking = false,
}: AvatarProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const onClickInternal = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (isDisabled) return;

    onClick();

    if (isDisabledSpeaking) return;

    setIsPlaying(true);
    setIsLoading(true);
  };

  const getAudioUrl = () => {
    const content = [`Hello, my name is ${name}`, `Hey there, I'm ${name}`, `Greetings, I'm ${name}`, `Hi, I'm ${name}`][Math.floor(Math.random() * 4)];

    return `${API_URL}/v1/voices/tts?characterId=${characterId}&text=${encodeURIComponent(content)}`;
  };

  const onStart = () => {
    setIsLoading(false);
  };

  const onEnded = () => {
    setIsLoading(false);
    setIsPlaying(false);
  };

  return (
    <Button
      type="basic"
      className={clsx(
        "relative flex items-center justify-center bg-white border-4 border-white cursor-pointer shadow-avatar !rounded-full aspect-square",
        className,
      )}
      style={style}
      onClick={onClickInternal}
    >
      <Image
        className={clsx("w-full h-full rounded-full", isLoading ? "opacity-50" : "opacity-100")}
        src={image}
      />
      {role && (
        <View
          className={clsx(
            "absolute -bottom-2 -right-2 p-1 flex items-center justify-center text-white rounded-full z-10",
            role === "main" && "bg-yellow-500",
            role === "guide" && "bg-secondary",
            role === "companion" && "bg-blue-500",
            role === "animal" && "bg-red-500",
          )}
        >
          {role === "main" ? (
            <StarOutlined className="!h-5 !w-5" />
          ) : role === "guide" ? (
            <Visibility className="!h-5 !w-5" />
          ) : role === "companion" ? (
            <Person2 className="!h-5 !w-5" />
          ) : role === "animal" ? (
            <PetsOutlined className="!h-5 !w-5" />
          ) : (
            <View />
          )}
        </View>
      )}
      {isLoading && <CircularProgress className="absolute !h-6 !w-6 !text-primary" />}
      {isPlaying && (
        <audio
          onPlay={onStart}
          onEnded={onEnded}
          onError={onEnded}
          src={getAudioUrl()}
          autoPlay
        />
      )}
    </Button>
  );
};
