export const selectFile = async (accept: string = "audio/*") => {
  return new Promise<File>((resolve, reject) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = accept;

    input.onchange = (e) => {
      const target = e.target as HTMLInputElement;
      const file = target.files?.[0];
      if (file) {
        // Verify it's an audio file
        if (!file.type.startsWith("audio/")) {
          reject(new Error("Please select an audio file"));
          return;
        }
        resolve(file);
      } else {
        reject(new Error("No file selected"));
      }
    };

    input.click();
  });
};
