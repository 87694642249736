import { AccountContributor } from "../../redux/reducers/accountContributorSlice";
import { CodeInputField } from "../../components/CodeInputField";
import { ErrorMessage } from "../../components/ErrorMessage";
import { Haptics } from "../../utils/haptics";
import { OnboardingCard } from "../../components/OnboardingCard";
import { ROUTES } from "../../utils/routes";
import { Spinner } from "../../components/base/Spinner";
import { Text } from "../../components/base/Text";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useNavigation } from "../../hooks/useNavigation";
import { useSearchParam } from "../../hooks/useSearchParam";
import { View } from "../../components/base/View";

export const OnboardingContributorCodePage = () => {
  const navigation = useNavigation();
  const contributorCodeRequest = useApiRequest<{ data: { contributorId: string; readerName: string } }>();
  const code = useSearchParam("code");

  const onChangeCode = async (value?: string) => {
    if (value?.length === 6) {
      Haptics.trigger(Haptics.HapticFeedbackTypes.impactLight);

      const { data } = await contributorCodeRequest.fetch("/v1/accounts/acceptInvite", {
        method: "POST",
        body: {
          code: value,
        },
      });

      if (data?.data?.contributorId) {
        navigation.navigate(ROUTES.ONBOARDING_CREATE_CONTRIBUTOR, {
          contributorId: data.data.contributorId,
          readerName: data.data.readerName,
          onboardingType: "contributor",
        });
      }
    }
  };

  const onClickBack = () => {
    navigation.goBack();
  };

  return (
    <OnboardingCard
      onClickBack={onClickBack}
      backText="Don't have a code?"
    >
      <View className="w-full flex flex-col items-center justify-center mb-8">
        <Text className="text-3xl text-center text-title mb-2">Let's set up your account</Text>
        <Text className="text-lg text-center text-subtitle">Enter the code that was shared with you:</Text>
      </View>
      <CodeInputField
        onChange={onChangeCode}
        isDisabled={contributorCodeRequest.isLoading}
        className="mb-4"
        value={code ?? undefined}
      />
      {contributorCodeRequest.isLoading && <Spinner />}
      {contributorCodeRequest.error && <ErrorMessage text={contributorCodeRequest.error.message} />}
    </OnboardingCard>
  );
};
