import { createRestSlice } from "./restSlice";

export type AudioStoryPlaylist = {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  tags: string;
  audioStoryIds: string[];
  isPublic: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
};

const audioStoryPlaylistSlice = createRestSlice<AudioStoryPlaylist>("audioStoryPlaylist", "/v1/audioStoryPlaylists");

export const audioStoryPlaylistActions = audioStoryPlaylistSlice.actions;
export const audioStoryPlaylistReducer = audioStoryPlaylistSlice.reducer;
