import { createRestSlice } from "./restSlice";

export type Persona = {
  id: string;
  name: string;
  voiceName: string;
  personality: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: null | string;
};

const personaSlice = createRestSlice<Persona>("persona", "/v1/personas");

export const personaActions = personaSlice.actions;
export const personaReducer = personaSlice.reducer;
