import { createRestSlice } from "./restSlice";

export type StoryTemplatePrompt = {
  id: string;
  storyTemplateId: string;
  index: number;
  model: string;
  prompt: string;
};

const storyTemplatePromptSlice = createRestSlice<StoryTemplatePrompt>(
  "storyTemplatePrompt",
  "/v1/storyTemplatePrompts",
);

export const storyTemplatePromptActions = storyTemplatePromptSlice.actions;
export const storyTemplatePromptReducer = storyTemplatePromptSlice.reducer;
