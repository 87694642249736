import { createRestSlice } from "./restSlice";

export type World = {
  id: string;
  name: string;
  description: string;
  image: string;
};

const worldSlice = createRestSlice<World>("world", "/v1/worlds");

export const worldActions = worldSlice.actions;
export const worldReducer = worldSlice.reducer;
