import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { createRestSlice, ItemState } from "./restSlice";
import { makeRequest } from "../../utils/request";
import { AccountReader } from "./accountReaderSlice";
import { AccountContributor } from "./accountContributorSlice";

export type Account = {
  id: string;
  name?: string;
  readers?: AccountReader[];
  contributors?: AccountContributor[];
};

export type User = {
  id: string;
  email: string;
  phone?: string;
  name?: string;
  image?: string;
  relationship?: string;
  avatar?: string;
  isAdmin: boolean;
  accountId?: string;
  account?: Account;
};

const getByAccountId = createAsyncThunk("user/getByAccountId", async (payload: string) => {
  const { data } = await makeRequest<{ data: User[] }>(`/v1/accounts/${payload}/users`);

  return data;
});

const userSlice = createRestSlice<User>("user", "/v1/users", {
  extraReducers: (builder: ActionReducerMapBuilder<ItemState<User>>) => {
    builder.addCase(getByAccountId.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getByAccountId.fulfilled, (state, action) => {
      const ids = action.payload.map((item) => item.id);
      const entities = action.payload.reduce(
        (acc, item) => {
          acc[item.id] = item;

          return acc;
        },
        {} as Record<string, User>,
      );

      state.isLoading = false;
      state.ids = [...state.ids, ...ids];
      state.entities = { ...state.entities, ...entities };
    });
  },
});

export const userReducer = userSlice.reducer;

export const userActions = {
  ...userSlice.actions,
  getByAccountId,
};
