import clsx from "clsx";

import { View } from "./base/View";
import { Text } from "./base/Text";
import { Style } from "../utils/types";

type FieldLabelProps = {
  className?: string;
  children: React.ReactNode;
  label?: string;
  style?: Style;
};

export const FieldLabel = ({ className, children, label, style }: FieldLabelProps) => {
  return label ? (
    <View
      className={clsx("w-full flex flex-col items-start justify-start mb-4 relative", className)}
      style={style}
    >
      <Text className="mb-1 text-xl">{label}</Text>
      {children}
    </View>
  ) : (
    <View
      className={clsx("relative", className)}
      style={style}
    >
      {children}
    </View>
  );
};
