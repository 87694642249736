import clsx from "clsx";
import { forwardRef } from "react";

type ScrollViewProps = {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  contentContainerStyle?: React.CSSProperties;
};

export const ScrollView = forwardRef<HTMLDivElement, ScrollViewProps>(({ className, children, style, contentContainerStyle }, ref) => {
  return (
    <div
      ref={ref}
      className={clsx("overflow-y-auto", className)}
      style={{ ...style, ...contentContainerStyle }}
    >
      {children}
    </div>
  );
});
