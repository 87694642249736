import AceEditor from "react-ace";

import { FieldLabel } from "./FieldLabel";

import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-handlebars";
import "ace-builds/src-noconflict/mode-json";

type CodeFieldProps = {
  rootClassName?: string;
  className?: string;
  label?: string;
  name?: string;
  onChange?: (value: string) => void;
  value?: string;
  readOnly?: boolean;
  mode?: string;
};

export const CodeField = ({ rootClassName, className, label, name, onChange = () => {}, value, readOnly, mode = "handlebars" }: CodeFieldProps) => {
  return (
    <FieldLabel
      label={label}
      className={rootClassName}
    >
      <AceEditor
        mode={mode}
        theme="monokai"
        onChange={onChange}
        onPaste={onChange}
        name={name}
        setOptions={{ useWorker: false }}
        editorProps={{ $blockScrolling: true }}
        value={value}
        readOnly={readOnly}
        className={className}
        style={{ width: "100%" }}
        wrapEnabled
      />
    </FieldLabel>
  );
};
