import { AutoFixHighRounded, Close, PlayArrowRounded } from "@mui/icons-material";

import { Button } from "./Button";
import { Card } from "./Card";
import { Story } from "../redux/reducers/storySlice";
import { Text } from "./Text";
import { View } from "./View";
import { InputField } from "./InputField";
import { useState } from "react";

type StorySetCreateCardProps = {
  className?: string;
  isLoading?: boolean;
  onClickBack: () => void;
  onClickCreate: (storyId: string) => void;
};

export const StorySetCreateCard = ({ className, isLoading = false, onClickBack = () => {}, onClickCreate = () => {} }: StorySetCreateCardProps) => {
  const [detail, setDetail] = useState("");

  const onClickCreateInternal = () => {
    onClickCreate(detail);
  };

  return (
    <Card className={className}>
      <View className="flex items-center justify-start gap-4 mb-4 w-full pt-4 sm:pt-8 px-4 sm:px-8">
        <Button
          type="basic"
          className="!w-8 !h-8 sm:!w-10 sm:!h-10"
        >
          <Close
            className="!w-8 !h-8 sm:!w-10 sm:!h-10 text-gray-800"
            onClick={onClickBack}
          />
        </Button>
        <Text className="text-2xl sm:text-4xl font-semibold text-gray-800 line-clamp-2">Create a collection</Text>
      </View>
      <View className="flex flex-col flex-grow overflow-y-auto px-4 sm:px-8 pb-4 sm:pb-8">
        <Text className="text-2xl font-semibold text-primary line-clamp-2 mb-2">Add as little or as much detail as you want and select your performers</Text>
        <InputField
          type="textarea"
          className="mb-4 w-[calc(100%-4rem)] flex-grow !text-gray-800"
          placeholder="Riding a plane for the first time"
          value={detail}
          onChange={(value) => setDetail(value)}
        />
        <Button
          className="!w-auto px-4"
          onClick={onClickCreateInternal}
          isLoading={isLoading}
        >
          Create
          <AutoFixHighRounded className="ml-4" />
        </Button>
      </View>
    </Card>
  );
};
