import clsx from "clsx";
import { View } from "./View";

type SlideProps = {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

export const Slide = ({ children, className, style }: SlideProps) => {
  return (
    <View
      className={clsx("absolute top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center transition-all duration-300", className)}
      style={style}
    >
      {children}
    </View>
  );
};
