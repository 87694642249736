import { createAsyncThunk, Draft, PayloadAction } from "@reduxjs/toolkit";
import { createRestSlice, ItemState } from "./restSlice";
import { makeRequest } from "../../utils/request";
import { StoryAnalysis } from "./storySlice";

export type StoryChunk = {
  id: string;
  characterId: string;
  index: number;
  content: string;
  paragraph: number;
  audio: string;
  video: string;
  storyId: string;
  analysis?: StoryAnalysis;
  color?: string;
};

const getByStoryId = createAsyncThunk("storyChunk/getByStoryId", async ({ storyId, languageCode }: { storyId: string; languageCode: string }) => {
  const { data } = await makeRequest<{ data: StoryChunk[] }>(`/v1/storyChunks?filters[storyId]=${storyId}&filters[languageCode]=${languageCode}`);

  return data;
});

const storyChunkSlice = createRestSlice<StoryChunk>("storyChunk", "/v1/storyChunks", {
  extraReducers: (builder) => {
    builder.addCase(getByStoryId.fulfilled, (state: ItemState<StoryChunk>, action: PayloadAction<StoryChunk[]>) => {
      state.isLoading = false;
      state.ids = action.payload.map((item: StoryChunk) => item.id);
      state.entities = action.payload.reduce(
        (acc: Record<string, Draft<StoryChunk>>, item: StoryChunk) => {
          acc[item.id] = item as Draft<StoryChunk>;

          return acc;
        },
        {} as Record<string, Draft<StoryChunk>>,
      );
    });
  },
});

export const storyChunkActions = {
  ...storyChunkSlice.actions,
  getByStoryId,
};

export const storyChunkReducer = storyChunkSlice.reducer;
