import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux";
import { View } from "../components/View";
import { Text } from "../components/Text";
import { InputField } from "../components/InputField";
import { Button } from "../components/Button";
import { StoryTitleCard } from "../components/StoryTitleCard";
import { useEntity } from "../hooks/useEntity";
import { Story, storyActions } from "../redux/reducers/storySlice";
import { Character } from "../redux/reducers/characterSlice";
import { CircularProgress } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

export const SearchPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { entities: storyEntities } = useEntity<Story>("story");
  const { entities: characterEntities } = useEntity<Character>("character");

  useEffect(() => {
    dispatch(storyActions.get());
  }, [dispatch]);

  const filteredStories = Object.values(storyEntities).filter((story) => story.title.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleSearch = () => {
    setIsLoading(true);
    // Simulating search delay
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleStoryClick = (storyId: string) => {
    navigate(`/story?id=${storyId}`);
  };

  return (
    <View className="flex flex-col items-center w-full h-full p-4 bg-gray-100">
      <Text className="text-4xl font-bold mb-6">Search Stories</Text>
      <View className="flex w-full max-w-2xl mb-8">
        <InputField
          className="flex-grow mr-2"
          placeholder="Enter story title..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e)}
        />
        <Button onClick={handleSearch}>{isLoading ? <CircularProgress size={24} /> : <SearchIcon />}</Button>
      </View>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <View className="w-full max-w-4xl">
          {filteredStories.map((story) => (
            <StoryTitleCard
              key={story.id}
              title={story.title}
              characters={Object.values(characterEntities).filter((character) => character.worldId === story.worldId)}
              onClick={() => handleStoryClick(story.id)}
              className="mb-4"
            />
          ))}
          {filteredStories.length === 0 && <Text className="text-center text-gray-500">No stories found</Text>}
        </View>
      )}
    </View>
  );
};
