import clsx from "clsx";

import { View } from "./View";

type CardProps = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export const Card = ({ children, className, style }: CardProps) => {
  return (
    <View
      className={clsx("flex flex-col bg-white rounded-lg shadow-avatar backdrop-blur-md bg-opacity-90", className)}
      style={style}
    >
      {children}
    </View>
  );
};
