import { useDebouncedCallback } from "use-debounce";
import clsx from "clsx";
import React, { useState } from "react";

import { Button } from "../../components/base/Button";
import { ErrorMessage } from "../../components/ErrorMessage";
import { Form } from "../../components/base/Form";
import { InputField } from "../../components/base/InputField";
import { OnboardingCard } from "../../components/OnboardingCard";
import { openUrl } from "../../utils/linking";
import { ProgressBar } from "../../components/ProgressBar";
import { ROUTES } from "../../utils/routes";
import { sessionActions } from "../../redux/reducers/sessionSlice";
import { Text } from "../../components/base/Text";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useAppDispatch } from "../../redux";
import { useNavigation } from "../../hooks/useNavigation";
import { useRoute } from "../../hooks/useRoute";
import { View } from "../../components/base/View";

type FormData = {
  email?: string;
  password?: string;
  confirmPassword?: string;
  name?: string;
};

type Route = {
  params: {
    onboardingType: "account" | "contributor";
  };
};

export const OnboardingCreateAccountPage = () => {
  const navigation = useNavigation();
  const route = useRoute<Route>();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<FormData>({});
  const passwordStrengthRequest = useApiRequest<{ strength: string; score: number }>();
  const createAccountApiRequest = useApiRequest();

  const onChangePassword = useDebouncedCallback((password) => {
    passwordStrengthRequest.fetch("/v1/auth/strength", {
      method: "POST",
      body: {
        password,
      },
    });
  }, 300);

  const onChangeFormData = (newFormData: FormData) => {
    setFormData(newFormData);

    if (!passwordStrengthRequest.isLoading && newFormData.password) {
      onChangePassword(newFormData.password);
    }

    if (createAccountApiRequest.error) {
      passwordStrengthRequest.clear();
      createAccountApiRequest.clear();
    }
  };

  const onClickContinue = async () => {
    const { error } = await createAccountApiRequest.fetch("/v1/auth/signup", {
      method: "POST",
      body: {
        email: formData.email,
        password: formData.password,
        name: formData.name,
      },
    });

    if (!error) {
      await dispatch(sessionActions.signIn({ email: formData.email, password: formData.password }));

      navigation.navigate(ROUTES.ONBOARDING_CREATE_READER, { onboardingType: route.params.onboardingType });
    }
  };

  return (
    <OnboardingCard
      title="Let's get started!"
      subtitle="Please enter an email and password to be tied to the account."
      onClickContinue={onClickContinue}
      currentStep={1}
      totalSteps={5}
      continueText="Create Account"
      continueLoading={createAccountApiRequest.isLoading}
      continueDisabled={
        !formData.email ||
        !formData.password ||
        !formData.confirmPassword ||
        formData.password !== formData.confirmPassword ||
        passwordStrengthRequest.data?.strength !== "strong"
      }
      footer={
        <View className="flex flex-row flex-wrap justify-center items-center gap-1 mt-4">
          <Text className="text-xs text-gray-500">By creating an account you agree to our</Text>
          <Button
            type="basic"
            onClick={() => openUrl("https://www.primstories.com/terms")}
            className="!w-auto"
          >
            <Text className="text-xs text-blue-500">Terms of Use</Text>
          </Button>
          <Text className="text-xs text-gray-500">and</Text>
          <Button
            type="basic"
            onClick={() => openUrl("https://www.primstories.com/privacy")}
            className="!w-auto"
          >
            <Text className="text-xs text-blue-500">Privacy Policy</Text>
          </Button>
        </View>
      }
    >
      <Form
        className="mb-4"
        data={formData}
        onChange={onChangeFormData}
      >
        <InputField
          name="name"
          placeholder="Name"
          className="mb-4"
        />
        <InputField
          name="email"
          placeholder="Email"
          className="mb-4"
        />
        <InputField
          name="password"
          placeholder="Password"
          type="password"
          className="mb-4"
        />
        <InputField
          name="confirmPassword"
          placeholder="Confirm Password"
          type="password"
        />
      </Form>
      <View className="w-full flex flex-col items-start justify-center h-12 mb-4">
        {!passwordStrengthRequest.data?.strength || (formData.password?.length || 0) < 10 ? (
          <Text className="text-gray-500">Password must be at least 10 characters long and include at least one number or one symbol.</Text>
        ) : createAccountApiRequest.error ? (
          <ErrorMessage
            className="mb-4"
            text={createAccountApiRequest.error?.message}
          />
        ) : passwordStrengthRequest.data ? (
          <>
            <ProgressBar
              className="w-full mb-2"
              color={passwordStrengthRequest.data?.strength === "weak" ? "red" : passwordStrengthRequest.data?.strength === "medium" ? "yellow" : "green"}
              progress={(passwordStrengthRequest.data?.score || 0) * 25}
            />
            <Text
              className={clsx(
                passwordStrengthRequest.data.strength === "weak" && "text-rose-500",
                passwordStrengthRequest.data.strength === "medium" && "text-yellow-600",
                passwordStrengthRequest.data.strength === "strong" && "text-secondary",
              )}
            >
              {passwordStrengthRequest.data.strength === "strong" ? "Strong Password" : "Please Strengthen Password"}
            </Text>
            <Text className="text-rose-500">{formData.confirmPassword && formData.password !== formData.confirmPassword ? "Passwords don't match" : " "}</Text>
          </>
        ) : null}
      </View>
    </OnboardingCard>
  );
};
