import { createSlice } from "@reduxjs/toolkit";

export type OnboardingState = {
  formData: Record<string, string>;
};

const initialState: OnboardingState = {
  formData: {},
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const onboardingReducer = onboardingSlice.reducer;

export const onboardingActions = {
  ...onboardingSlice.actions,
};
