import { useEffect, useState } from "react";

import { AvatarField } from "./AvatarField";
import { Form } from "./Form";
import { InputField } from "./InputField";
import { Modal } from "./Modal";
import { useApiRequest } from "../hooks/useApiRequest";
import { useAppDispatch, useAppSelector } from "../redux";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { AccountReader } from "../redux/reducers/accountReaderSlice";

type ReaderInfoModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  readerId?: string;
};

export const ReaderInfoModal = ({ isOpen = false, onClose = () => {}, readerId }: ReaderInfoModalProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.session.user);
  const [formData, setFormData] = useState<Partial<AccountReader>>({});
  const readerApiRequest = useApiRequest();
  const deleteApiRequest = useApiRequest();

  const onChangeFormData = (formData: Record<string, unknown>) => {
    setFormData(formData);
  };

  const onCloseModal = () => {
    setFormData({});
    onClose();
  };

  const onConfirmReaderModal = async () => {
    if (readerId) {
      await readerApiRequest.fetch(`/v1/accountReaders/${formData.id}`, {
        method: "PATCH",
        body: {
          ...formData,
          accountId: user?.account?.id,
          id: undefined,
        },
      });
    } else {
      await readerApiRequest.fetch("/v1/accountReaders", {
        method: "POST",
        body: {
          ...formData,
          accountId: user?.account?.id,
        },
      });
    }

    dispatch(sessionActions.getSession());
    onCloseModal();
  };

  const onDeleteReader = async () => {
    await deleteApiRequest.fetch(`/v1/accountReaders/${readerId}`, {
      method: "DELETE",
    });

    dispatch(sessionActions.getSession());
    onCloseModal();
  };

  useEffect(() => {
    if (readerId) {
      setFormData(user?.account?.readers?.find((reader) => reader.id === readerId) || {});
    }
  }, [isOpen, readerId, user]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      onDelete={onDeleteReader}
      onDeleteLoading={deleteApiRequest.isLoading}
      onConfirm={onConfirmReaderModal}
      onConfirmLoading={readerApiRequest.isLoading}
      title={formData.id ? "Edit Reader" : "Add Reader"}
      className="flex flex-col flex-grow"
    >
      <Form
        data={formData}
        onChange={onChangeFormData}
        className="flex flex-col flex-grow items-center"
      >
        <AvatarField name="image" />
        <InputField
          label="Name"
          name="name"
          className="w-full"
          placeholder="Reader's name"
        />
        <InputField
          label="Description"
          name="description"
          className="flex flex-grow w-full"
          labelClassName="flex flex-grow !mb-0"
          placeholder="Brief description of the reader"
          type="textarea"
        />
      </Form>
    </Modal>
  );
};
