import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";

import { AdminAudioStoriesPage } from "./pages/admin/AdminAudioStoriesPage";
import { AdminAccountsPage } from "./pages/admin/AdminAccountsPage";
import { AdminAudioStoryPlaylistsPage } from "./pages/admin/AdminAudioStoryPlaylistsPage";
import { AdminCharactersPage } from "./pages/admin/AdminCharactersPage";
import { AdminLayout } from "./layouts/AdminLayout";
import { AdminStoriesPage } from "./pages/admin/AdminStoriesPage";
import { AdminStoryBuilderPage } from "./pages/admin/AdminStoryBuilderPage";
import { AdminStorySetsPage } from "./pages/admin/AdminStorySetsPage";
import { AdminStoryTemplateSetsPage } from "./pages/admin/AdminStoryTemplateSetsPage";
import { AdminStoryTemplatesPage } from "./pages/admin/AdminStoryTemplatesPage";
import { AdminSubscriptionsPage } from "./pages/admin/AdminSubscriptionsPage";
import { AdminThemesPage } from "./pages/admin/AdminThemesPage";
import { AdminUsersPage } from "./pages/admin/AdminUsersPage";
import { AdminVoicesPage } from "./pages/admin/AdminVoicesPage";
import { AdminWorldsPage } from "./pages/admin/AdminWorldsPage";
import { AppLayout } from "./layouts/AppLayout";
import { AuthenticatedLayout } from "./layouts/AuthenticatedLayout";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { HomePage } from "./pages/HomePage";
import { LibraryPage } from "./pages/LibraryPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { ProfilePage } from "./pages/ProfilePage";
import { RedirectPage } from "./pages/RedirectPage";
import { SearchPage } from "./pages/SearchPage";
import { SignInPage } from "./pages/SignInPage";
import { store } from "./redux";
import { StoryPage } from "./pages/StoryPage";
import { OnboardingAccountTypePage } from "./pages/onboarding/OnboardingAccountTypePage";
import { OnboardingCompletePage } from "./pages/onboarding/OnboardingCompletePage";
import { OnboardingContributorCodePage } from "./pages/onboarding/OnboardingContributorCodePage";
import { OnboardingContributorPhotoPage } from "./pages/onboarding/OnboardingContributorPhotoPage";
import { OnboardingContributorVoicePage } from "./pages/onboarding/OnboardingContributorVoicePage";
import { OnboardingCreateAccountPage } from "./pages/onboarding/OnboardingCreateAccountPage";
import { OnboardingCreateContributorPage } from "./pages/onboarding/OnboardingCreateContributorPage";
import { OnboardingLayout } from "./layouts/OnboardingLayout";
import { AdminPersonasPage } from "./pages/admin/AdminPersonasPage";
import { OnboardingCreateReaderPage } from "./pages/onboarding/OnboardingCreateReaderPage";
import { OnboardingReaderDescriptionPage } from "./pages/onboarding/OnboardingReaderDescriptionPage";
import { OnboardingReaderGenderPage } from "./pages/onboarding/OnboardingReaderGenderPage";
import { OnboardingReaderLevelPage } from "./pages/onboarding/OnboardingReaderLevelPage";
import { AdminBackgroundTracksPage } from "./pages/admin/AdminBackgroundTracksPage";
import { SignOutPage } from "./pages/SignOutPage";
import { AdminFeedbackPage } from "./pages/admin/AdminFeedbackPage";

export const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<AppLayout />}
          >
            <Route
              path="/"
              element={<AuthenticatedLayout />}
            >
              <Route
                path="/"
                element={<HomePage />}
              />
              <Route
                path="/library"
                element={<LibraryPage />}
              />
              <Route
                path="/search"
                element={<SearchPage />}
              />
              <Route
                path="/story"
                element={<StoryPage />}
              />
              <Route
                path="/profile"
                element={<ProfilePage />}
              />
            </Route>
            <Route
              path="/onboarding"
              element={<OnboardingLayout />}
            >
              <Route
                path="/onboarding"
                element={<OnboardingAccountTypePage />}
              />
              <Route
                path="/onboarding/create-account"
                element={<OnboardingCreateAccountPage />}
              />
              <Route
                path="/onboarding/create-contributor"
                element={<OnboardingCreateContributorPage />}
              />
              <Route
                path="/onboarding/create-reader"
                element={<OnboardingCreateReaderPage />}
              />
              <Route
                path="/onboarding/reader-description"
                element={<OnboardingReaderDescriptionPage />}
              />
              <Route
                path="/onboarding/reader-gender"
                element={<OnboardingReaderGenderPage />}
              />
              <Route
                path="/onboarding/reader-level"
                element={<OnboardingReaderLevelPage />}
              />
              <Route
                path="/onboarding/contributor-code"
                element={<OnboardingContributorCodePage />}
              />
              <Route
                path="/onboarding/contributor-photo"
                element={<OnboardingContributorPhotoPage />}
              />
              <Route
                path="/onboarding/contributor-voice"
                element={<OnboardingContributorVoicePage />}
              />
              <Route
                path="/onboarding/complete"
                element={<OnboardingCompletePage />}
              />
            </Route>
            <Route
              path="/admin"
              element={<AdminLayout />}
            >
              <Route
                path="/admin"
                element={<RedirectPage path="/admin/audioStories" />}
              />
              <Route
                path="/admin/stories"
                element={<AdminStoriesPage />}
              />
              <Route
                path="/admin/storySets"
                element={<AdminStorySetsPage />}
              />
              <Route
                path="/admin/storyTemplates"
                element={<AdminStoryTemplatesPage />}
              />
              <Route
                path="/admin/storyTemplateSets"
                element={<AdminStoryTemplateSetsPage />}
              />
              <Route
                path="/admin/themes"
                element={<AdminThemesPage />}
              />
              <Route
                path="/admin/characters"
                element={<AdminCharactersPage />}
              />
              <Route
                path="/admin/users"
                element={<AdminUsersPage />}
              />
              <Route
                path="/admin/personas"
                element={<AdminPersonasPage />}
              />
              <Route
                path="/admin/accounts"
                element={<AdminAccountsPage />}
              />
              <Route
                path="/admin/voices"
                element={<AdminVoicesPage />}
              />
              <Route
                path="/admin/worlds"
                element={<AdminWorldsPage />}
              />
              <Route
                path="/admin/storyBuilder"
                element={<AdminStoryBuilderPage />}
              />
              <Route
                path="/admin/audioStories"
                element={<AdminAudioStoriesPage />}
              />
              <Route
                path="/admin/audioStoryPlaylists"
                element={<AdminAudioStoryPlaylistsPage />}
              />
              <Route
                path="/admin/subscriptions"
                element={<AdminSubscriptionsPage />}
              />
              <Route
                path="/admin/backgroundTracks"
                element={<AdminBackgroundTracksPage />}
              />
              <Route
                path="/admin/feedback"
                element={<AdminFeedbackPage />}
              />
            </Route>
            <Route
              path="/signin"
              element={<SignInPage />}
            />
            <Route
              path="/signout"
              element={<SignOutPage />}
            />
            <Route
              path="/forgot-password"
              element={<ForgotPasswordPage />}
            />
            <Route
              path="*"
              element={<NotFoundPage />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};
