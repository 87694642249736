import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { makeRequest } from "../../utils/request";
import { Character } from "./characterSlice";
import { Story } from "./storySlice";
import { StorySet } from "./storySetSlice";
import { World } from "./worldSlice";

type ContentLibraryResponse = {
  data: {
    characters: Character[];
    stories: Story[];
    storySets: StorySet[];
    worlds: World[];
  };
};

export type ContentState = {
  isLoading: boolean;
  error: string | null;
  data: ContentLibraryResponse["data"];
};

const initialState: ContentState = {
  isLoading: false,
  error: null,
  data: {
    characters: [],
    stories: [],
    storySets: [],
    worlds: [],
  },
};

const getContent = createAsyncThunk("content/getContent", async () => {
  const response = await makeRequest<ContentLibraryResponse>("/v1/content/library");

  return response.data;
});

const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getContent.fulfilled, (state, action) => {
      state.data = action.payload;
    });

    builder.addMatcher(
      (action) => action.type.startsWith("content/") && action.type.endsWith("/pending"),
      (state) => {
        state.isLoading = true;
        state.error = null;
      },
    );

    builder.addMatcher(
      (action) => action.type.startsWith("content/") && action.type.endsWith("/rejected"),
      (state) => {
        state.isLoading = false;
      },
    );

    builder.addMatcher(
      (action) => action.type.startsWith("content/") && action.type.endsWith("/fulfilled"),
      (state) => {
        state.isLoading = false;
      },
    );
  },
});

export const contentReducer = contentSlice.reducer;

export const contentActions = {
  ...contentSlice.actions,
  getContent,
};
