import { CloseRounded } from "@mui/icons-material";

import { Button } from "./Button";
import { DropdownField, DropdownFieldOption } from "./DropdownField";
import { FieldLabel } from "./FieldLabel";
import { Text } from "./Text";
import { View } from "./View";

type MultiselectFieldProps = {
  label?: string;
  name?: string;
  onChange?: (value: string[]) => void;
  options?: DropdownFieldOption[];
  value?: string[];
};

export const MultiselectField = ({ label, onChange = () => {}, options = [], value = [] }: MultiselectFieldProps) => {
  const onClickRemoveHandler = (id: string) => () => {
    const newValue = [...value].filter((storyId) => storyId !== id);
    onChange(newValue);
  };

  const onChangeDropdown = (id: string) => {
    const newValue = [...value, id];
    onChange(newValue);
  };

  return (
    <FieldLabel label={label}>
      <View className="border-[1px] border-gray-300 w-full rounded-md z-10">
        {value?.map((id) => (
          <View className="flex items-center justify-between w-full pl-4 pr-2 pt-2 pb-2 border-b-[1px] border-gray-300">
            <Text className="truncate">{options?.find((option) => option.value === id)?.label}</Text>
            <Button
              type="basic"
              onClick={onClickRemoveHandler(id)}
              className="!w-8"
            >
              <CloseRounded
                className="!w-6 !h-6"
                color="error"
                fontSize="small"
              />
            </Button>
          </View>
        ))}
        <DropdownField
          className="!h-[50px]"
          type="basic"
          onChange={onChangeDropdown}
          options={options}
          searchable
        />
      </View>
    </FieldLabel>
  );
};
