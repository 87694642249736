import { useEffect, useState } from "react";
import clsx from "clsx";

import { BookPage } from "./BookPage";
import { View } from "./View";
import { splitIntoPages } from "../utils/strings";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { StoryAnalysisWord } from "../redux/reducers/storySlice";

type BookProps = {
  className?: string;
  characterColors?: Record<string, string>;
  currentWordIndex?: number;
  onClickNextPage?: () => void;
  onClickPrevPage?: () => void;
  onClickPlayWord?: (index: number) => void;
  onClickDefineWord?: (index: number) => void;
  words?: StoryAnalysisWord[];
};

const getCurrentWordIndexOffset = (currentPage: number, pages: StoryAnalysisWord[][]) => {
  let wordCount = 0;

  for (let i = 0; i < currentPage; i++) {
    wordCount += pages[i]?.length || 0;
  }

  return wordCount;
};

export const Book = ({
  characterColors = {},
  currentWordIndex = -1,
  className,
  onClickNextPage = () => {},
  onClickPrevPage = () => {},
  onClickPlayWord = () => {},
  onClickDefineWord = () => {},
  words = [],
}: BookProps) => {
  const [pageHeight, setPageHeight] = useState<number>(0);
  const [pageWidth, setPageWidth] = useState<number>(0);
  const [pages, setPages] = useState<StoryAnalysisWord[][]>([]);
  const [pagesVisible, setPagesVisible] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isTransitioningPrev, setIsTransitioningPrev] = useState<boolean>(false);
  const [isTransitioningNext, setIsTransitioningNext] = useState<boolean>(false);
  const [isTransitioningPrevMidPoint, setTransitioningPrevMidPoint] = useState<boolean>(false);
  const [isTransitioningNextMidPoint, setTransitioningNextMidPoint] = useState<boolean>(false);
  const { windowWidth } = useWindowDimensions();

  const onClickPrevPageInternal = () => {
    if (pagesVisible === 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setIsTransitioningPrev(true);

      setTimeout(() => {
        setTransitioningPrevMidPoint(true);
      }, 500);

      setTimeout(() => {
        setIsTransitioningPrev(false);
        setTransitioningPrevMidPoint(false);
        setCurrentPage(currentPage - 2);
      }, 1000);
    }

    onClickPrevPage();
  };

  const onClickNextPageInternal = () => {
    if (pagesVisible === 1) {
      setCurrentPage(currentPage + 1);
    } else {
      setIsTransitioningNext(true);

      setTimeout(() => {
        setTransitioningNextMidPoint(true);
      }, 500);

      setTimeout(() => {
        setIsTransitioningNext(false);
        setTransitioningNextMidPoint(false);
        setCurrentPage(currentPage + 2);
      }, 1000);
    }

    onClickNextPage();
  };

  const onLayout = (height: number, width: number) => {
    if (height && width) {
      setPageHeight(height);
      setPageWidth(width);
    }
  };

  useEffect(() => {
    const newPages = splitIntoPages(words, pageHeight - 56, pageWidth);

    setPages(newPages);
  }, [words, pageHeight, pageWidth]);

  useEffect(() => {
    setPagesVisible(windowWidth < 768 ? 1 : 2);
  }, [windowWidth]);

  // useEffect(() => {
  //   if (pagesVisible === 1) {
  //     if (currentWordIndex > getCurrentWordIndexOffset(currentPage + 1, pages)) {
  //       onClickNextPageInternal();
  //     } else if (currentWordIndex < getCurrentWordIndexOffset(currentPage, pages)) {
  //       onClickPrevPageInternal();
  //     }
  //   }

  //   if (pagesVisible === 2) {
  //     if (currentWordIndex > getCurrentWordIndexOffset(currentPage + 2, pages)) {
  //       onClickNextPageInternal();
  //     } else if (currentWordIndex < getCurrentWordIndexOffset(currentPage - 1, pages)) {
  //       onClickPrevPageInternal();
  //     }
  //   }
  // }, [currentWordIndex, currentPage, pages, pagesVisible]);

  return (
    <View className={clsx("relative flex items-center justify-center h-full", className)}>
      {pagesVisible === 1 && (
        <BookPage
          characterColors={characterColors}
          words={pages[currentPage]}
          prevParagraphIndex={pages[currentPage - 1]?.[pages[currentPage - 1]?.length - 1]?.paragraph}
          currentWordIndex={currentWordIndex}
          currentWordIndexOffset={getCurrentWordIndexOffset(currentPage, pages)}
          onClickPlayWord={onClickPlayWord}
          onClickDefineWord={onClickDefineWord}
          onClickNextPage={onClickNextPageInternal}
          onClickPrevPage={onClickPrevPageInternal}
          onLayout={onLayout}
          pageNumber={currentPage}
          pageNumberTotal={pages.length}
          className="absolute top-0 left-1/2 -translate-x-1/2 md:-translate-x-full h-full md:!w-[50%] max-w-[552px] transition-all ease-linear duration-500 z-0"
        />
      )}
      {pagesVisible === 2 && (
        <>
          <BookPage
            characterColors={characterColors}
            words={pages[currentPage - 2]}
            prevParagraphIndex={pages[currentPage - 3]?.[pages[currentPage - 3]?.length - 1]?.paragraph}
            currentWordIndex={currentWordIndex}
            currentWordIndexOffset={getCurrentWordIndexOffset(currentPage - 2, pages)}
            onClickPrevPage={onClickPrevPageInternal}
            onLayout={onLayout}
            pageNumber={currentPage - 2}
            pageNumberTotal={pages.length}
            isTransitioning={isTransitioningPrev || isTransitioningNext}
            className={clsx(
              "absolute top-0 left-1/2 -translate-x-1/2 md:-translate-x-full h-full md:!w-[50%] max-w-[552px] transition-all ease-linear duration-500 z-0",
              isTransitioningPrev ? "!bg-white" : "!bg-gray-400",
            )}
          />
          <View
            className={clsx(
              "absolute top-0 right-1/2 h-full w-[50%] max-w-[552px] origin-right",
              isTransitioningPrev ? "transition-all ease-linear duration-1000 [transform:rotateY(-180deg)] z-20" : "z-10",
            )}
          >
            <View
              className={clsx(
                "relative w-full h-full origin-right transition-all ease-linear duration-500",
                isTransitioningPrev && (isTransitioningPrevMidPoint ? "[transform:skewY(0deg)]" : "[transform:skewY(4deg)]"),
              )}
            >
              <BookPage
                characterColors={characterColors}
                words={pages[currentPage]}
                prevParagraphIndex={pages[currentPage - 1]?.[pages[currentPage - 1]?.length - 1]?.paragraph}
                currentWordIndex={currentWordIndex}
                currentWordIndexOffset={getCurrentWordIndexOffset(currentPage, pages)}
                onClickPlayWord={onClickPlayWord}
                onClickDefineWord={onClickDefineWord}
                onClickPrevPage={onClickPrevPageInternal}
                pageNumber={currentPage}
                pageNumberTotal={pages.length}
                isTransitioning={isTransitioningPrev || isTransitioningNext}
                className={clsx("absolute top-0 left-0", isTransitioningPrevMidPoint ? "z-0" : "z-10")}
              />
              <BookPage
                characterColors={characterColors}
                words={pages[currentPage - 1]}
                prevParagraphIndex={pages[currentPage - 2]?.[pages[currentPage - 2]?.length - 1]?.paragraph}
                currentWordIndex={currentWordIndex}
                currentWordIndexOffset={getCurrentWordIndexOffset(currentPage - 1, pages)}
                onClickNextPage={onClickNextPageInternal}
                pageNumber={currentPage - 1}
                pageNumberTotal={pages.length}
                isTransitioning={isTransitioningPrev || isTransitioningNext}
                className={clsx("absolute top-0 left-0 [transform:rotateY(-180deg)]", isTransitioningPrevMidPoint ? "z-10" : "z-0")}
              />
            </View>
          </View>
          <View className="absolute top-0 left-1/2 h-[calc(100%-16px)] -translate-x-[1px] my-2 border-[1px] border-gray-400 border-dashed opacity-20 z-30" />
          <View
            className={clsx(
              "absolute top-0 left-1/2 h-full w-[50%] max-w-[552px] origin-left",
              isTransitioningNext ? "transition-all ease-linear duration-1000 [transform:rotateY(-180deg)] z-20" : "z-10",
            )}
          >
            <View
              className={clsx(
                "relative w-full h-full origin-left transition-all ease-linear duration-500",
                isTransitioningNext && (isTransitioningNextMidPoint ? "[transform:skewY(0deg)]" : "[transform:skewY(-4deg)]"),
              )}
            >
              <BookPage
                characterColors={characterColors}
                words={pages[currentPage + 1]}
                prevParagraphIndex={pages[currentPage]?.[pages[currentPage]?.length - 1]?.paragraph}
                currentWordIndex={currentWordIndex}
                currentWordIndexOffset={getCurrentWordIndexOffset(currentPage + 1, pages)}
                onClickPlayWord={onClickPlayWord}
                onClickDefineWord={onClickDefineWord}
                onClickNextPage={onClickNextPageInternal}
                pageNumber={currentPage + 1}
                pageNumberTotal={pages.length}
                isTransitioning={isTransitioningPrev || isTransitioningNext}
                className={clsx("absolute top-0 left-0", isTransitioningNextMidPoint ? "z-0" : "z-10")}
              />
              <BookPage
                characterColors={characterColors}
                words={pages[currentPage + 2]}
                prevParagraphIndex={pages[currentPage + 1]?.[pages[currentPage + 1]?.length - 1]?.paragraph}
                currentWordIndex={currentWordIndex}
                currentWordIndexOffset={getCurrentWordIndexOffset(currentPage + 2, pages)}
                onClickPrevPage={onClickPrevPageInternal}
                pageNumber={currentPage + 2}
                pageNumberTotal={pages.length}
                isTransitioning={isTransitioningPrev || isTransitioningNext}
                className={clsx("absolute top-0 left-0 [transform:rotateY(-180deg)]", isTransitioningNextMidPoint ? "z-10" : "z-0")}
              />
            </View>
          </View>
          <BookPage
            characterColors={characterColors}
            words={pages[currentPage + 3]}
            prevParagraphIndex={pages[currentPage + 2]?.[pages[currentPage + 2]?.length - 1]?.paragraph}
            currentWordIndex={currentWordIndex}
            currentWordIndexOffset={getCurrentWordIndexOffset(currentPage + 3, pages)}
            onClickNextPage={onClickNextPageInternal}
            onLayout={onLayout}
            pageNumber={currentPage + 3}
            pageNumberTotal={pages.length}
            isTransitioning={isTransitioningPrev || isTransitioningNext}
            className={clsx(
              "absolute top-0 left-1/2 h-full !w-[50%] max-w-[552px] transition-all ease-linear duration-500 z-0",
              isTransitioningNext ? "!bg-white" : "!bg-gray-400",
            )}
          />
        </>
      )}
    </View>
  );
};
