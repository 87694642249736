import { createRestSlice } from "./restSlice";

export type StoryTemplateSet = {
  id: string;
  name: string;
  description: string;
  storyTemplateIds: string[];
  data: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

const storyTemplateSetSlice = createRestSlice<StoryTemplateSet>("storyTemplateSet", "/v1/storyTemplateSets");

export const storyTemplateSetActions = storyTemplateSetSlice.actions;
export const storyTemplateSetReducer = storyTemplateSetSlice.reducer;
