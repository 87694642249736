import clsx from "clsx";
import React, { useRef, useState } from "react";

import { useRecording } from "../hooks/useRecording";
import { Button } from "./base/Button";
import { ScrollView } from "./base/ScrollView";
import { Text } from "./base/Text";
import { View } from "./base/View";
import { selectFile } from "../utils/files";
import { Icon } from "./base/Icon";
import { Style } from "../utils/types";

type AudioFieldProps = {
  className?: string;
  onChange: (file: File) => void;
  value?: boolean;
  hideLabel?: boolean;
  style?: Style;
};

export const AudioField = ({ className, onChange, value, hideLabel = false, style }: AudioFieldProps) => {
  const countdownTimer = useRef<NodeJS.Timeout | null>(null);
  const startRecordingTimeout = useRef<NodeJS.Timeout | null>(null);
  const [recordingCountdown, setRecordingCountdown] = useState<number>(65);
  const [isLoadingRecording, setIsLoadingRecording] = useState<boolean>(false);
  const [isRecordingComplete, setIsRecordingComplete] = useState<boolean>(false);
  const [startRecordingCountdown, setStartRecordingCountdown] = useState<number>(0);
  const recording = useRecording();

  const sampleText =
    "Once upon a time, in a land far, far away, there was a magical kingdom filled with wonder and enchantment. The sky was always a brilliant shade of blue, and the grass was the greenest you've ever seen. In this kingdom, there lived a brave knight named Sir Galahad, who was known for his courage and kindness. One day, Sir Galahad received a message from the wise old wizard who lived in the tallest tower of the castle. The wizard told him about a dangerous dragon that was threatening the peaceful villages on the outskirts of the kingdom. Without hesitation, Sir Galahad mounted his trusty steed and set off on a perilous journey to face the dragon and protect the innocent villagers. Along the way, he encountered many challenges and made new friends, including a clever fox and a gentle giant. Together, they devised a plan to outsmart the dragon and bring peace back to the kingdom. As they approached the dragon's lair, Sir Galahad's heart raced with excitement and fear. But he knew that he had to be brave for the sake of the kingdom. With his sword in hand and his friends by his side, Sir Galahad prepared to face his greatest challenge yet. Just as Sir Galahad and his companions drew closer to the dragon's lair, a sudden storm brewed overhead. Thunder rumbled, and lightning illuminated the path, casting eerie shadows on the rocky terrain. The clever fox suggested they wait for a break in the storm, but the gentle giant insisted they press on, sensing that the dragon would be least expectant in such conditions. They moved cautiously, their steps muffled by the rain-soaked ground, until they reached the mouth of the dragon's cave. The air was thick with the scent of smoke and brimstone, and a faint, fiery glow pulsed from within, revealing the formidable size of the beast they were about to face.";

  const updateRecordingCountdown = async (countdown: number) => {
    const newCountdown = countdown - 1;

    setRecordingCountdown(newCountdown);

    if (newCountdown <= 0) {
      setIsRecordingComplete(true);

      await onClickStopRecording();
    } else {
      countdownTimer.current = setTimeout(() => {
        updateRecordingCountdown(newCountdown);
      }, 1000);
    }
  };

  const onClickStartRecording = async (retry = 0) => {
    await recording.start();

    setStartRecordingCountdown(3);

    startRecordingTimeout.current = setTimeout(() => {
      setStartRecordingCountdown(2);

      startRecordingTimeout.current = setTimeout(() => {
        setStartRecordingCountdown(1);

        startRecordingTimeout.current = setTimeout(async () => {
          try {
            setStartRecordingCountdown(0);
            updateRecordingCountdown(65);
          } catch (error) {
            if (retry < 3) {
              setTimeout(() => {
                onClickStartRecording(retry + 1);
              }, 1000);
            } else {
              setIsLoadingRecording(false);
              alert("Failed to record your voice. Please try again later.");
            }
          }
        }, 1000);
      }, 1000);
    }, 1000);
  };

  const onClickStopRecording = async () => {
    setRecordingCountdown(0);
    setStartRecordingCountdown(0);

    if (countdownTimer.current) {
      clearTimeout(countdownTimer.current);
    }

    if (startRecordingTimeout.current) {
      clearTimeout(startRecordingTimeout.current);
    }

    const file = await recording.stop();
    if (file) {
      onChange(file);
    }
  };

  const onClickPickFile = async () => {
    try {
      const file = await selectFile("audio/*");
      if (file) {
        onChange(file);
      }
    } catch (error) {
      alert("Failed to pick audio file. Please try again.");
    }
  };

  return (
    <View
      className={clsx("relative flex-1 mb-4 w-full", className)}
      style={style}
    >
      {!hideLabel && <Text className="mb-1 text-xl">Voice</Text>}
      <View className="flex flex-row items-center justify-start w-full h-12 border-[1px] border-gray-300 rounded-md mb-2 bg-white px-4">
        {value || isRecordingComplete ? (
          <>
            <Icon
              name="check"
              size={24}
              className="text-secondary mr-2"
            />
            <Text
              className="text-xl text-secondary"
              style={{ fontFamily: "inherit" }}
            >
              Audio Sample
            </Text>
          </>
        ) : (
          <>
            <Icon
              name="info"
              size={24}
              className="text-subtitle mr-2"
            />
            <Text
              className="text-xl text-subtitle"
              style={{ fontFamily: "inherit" }}
            >
              No Audio Sample
            </Text>
          </>
        )}
      </View>
      <View className="flex flex-row items-center justify-center mb-2 gap-2">
        <Button
          color="danger"
          onClick={recording.isRecording || startRecordingCountdown > 0 ? onClickStopRecording : (onClickStartRecording as () => Promise<void>)}
          isLoading={isLoadingRecording}
          className="flex-1 rounded-full"
          iconLeft={
            startRecordingCountdown === 0 && (
              <Icon
                size={20}
                name={recording.isRecording ? "stop" : "mic"}
                className="text-white mr-2"
              />
            )
          }
        >
          {startRecordingCountdown > 0 ? startRecordingCountdown : recording.isRecording ? `Stop Recording (${recordingCountdown}s)` : "Record"}
        </Button>
        {!recording.isRecording && startRecordingCountdown === 0 && (
          <Button
            color="primary"
            onClick={onClickPickFile}
            className="flex-1 rounded-full"
            iconLeft={
              <Icon
                size={20}
                name="folder"
                className="text-white mr-2"
              />
            }
          >
            Select
          </Button>
        )}
      </View>
      {recording.isRecording ? (
        <View className="flex-1 flex flex-col items-center justify-center">
          <ScrollView
            className="flex-1 my-4 max-h-[25vh]"
            contentContainerStyle={{
              paddingLeft: 16,
              paddingRight: 16,
            }}
          >
            <Text className="text-lg text-subtitle">{sampleText}</Text>
          </ScrollView>
          <Text className="text-base text-subtitle text-center">(Scroll to read)</Text>
        </View>
      ) : isRecordingComplete ? (
        <Text className="text-lg text-subtitle mb-2">
          Your voice has been recorded! Click the 'Record' to record again or the 'Select' to pick an existing file instead. Otherwise, click 'Save' to upload
          your voice.
        </Text>
      ) : (
        <Text className="text-lg text-subtitle mb-2">
          Upon clicking record, please read out loud for 65 seconds. You can use the sample text that appears or any text you want.
        </Text>
      )}
    </View>
  );
};
