import { Outlet } from "react-router-dom";
import clsx from "clsx";

import { View } from "../components/View";
import { Image } from "../components/Image";

type OnboardingLayoutProps = {
  className?: string;
};

export const OnboardingLayout = ({ className }: OnboardingLayoutProps) => {
  return (
    <View className={clsx("relative w-full h-full flex flex-col items-center justify-center transition-all", className)}>
      <Image
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="https://api.primstories.com/v1/files/images/822ff9a5-68ba-4de1-87d1-ab159f970e37.webp"
      />
      <Outlet />
    </View>
  );
};
