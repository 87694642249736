import { RestList } from "../../components/RestList";
import { Persona } from "../../redux/reducers/personaSlice";

type AdminPersonasPageProps = {
  className?: string;
};

export const AdminPersonasPage = ({ className }: AdminPersonasPageProps) => {
  const fields = [
    {
      name: "name",
      label: "Name",
    },
    {
      name: "voiceName",
      label: "Voice Name",
      type: "dropdown",
      options: ["alloy", "echo", "nova", "shimmer", "fable", "onyx", "sage", "verse", "ash", "ballad", "coral"].map((voice) => ({
        label: voice,
        value: voice,
      })),
    },
    {
      name: "personality",
      label: "Personality",
      type: "textarea",
      visible: false,
    },
  ];

  return (
    <RestList<Persona>
      className={className}
      entityName="persona"
      fields={fields}
    />
  );
};
