import clsx from "clsx";

import { View } from "./View";

type ProgressBarProps = {
  className?: string;
  progress?: number;
  color?: string;
};

export const ProgressBar = ({ className, color = "green", progress = 0 }: ProgressBarProps) => {
  return (
    <View className={clsx("w-full h-2 bg-gray-200 rounded-full", className)}>
      <View
        className={clsx(
          "h-full rounded-full transition-all",
          color === "red" && "bg-rose-500",
          color === "yellow" && "bg-yellow-400",
          color === "green" && "bg-secondary",
        )}
        style={{
          width: `${Math.min(Math.max(0, progress), 100)}%` || "0%",
        }}
      />
    </View>
  );
};
