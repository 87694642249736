import { useState } from "react";
import { makeRequest, MakeRequestOptions } from "../utils/request";

export const useApiRequest = <T extends {}>() => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const clear = () => {
    setData(null);
    setError(null);
  };

  const fetch = async (url: string, options?: MakeRequestOptions) => {
    setError(null);
    setIsLoading(true);

    let newData, newError;

    try {
      newData = await makeRequest<T>(url, options);

      setData(newData);
    } catch (error) {
      newError = error as Error;

      setError(newError);
    }

    setIsLoading(false);

    return {
      data: newData,
      error: newError,
    };
  };

  return {
    data,
    error,
    clear,
    fetch,
    isLoading,
  };
};
