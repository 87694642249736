import { VolumeUp } from "@mui/icons-material";

import { RestList, RestListComponentProps } from "../../components/RestList";
import { Voice } from "../../redux/reducers/voiceSlice";
import { Button } from "../../components/Button";
import { useAudioPlayer } from "../../hooks/useAudioPlayer";
import { API_URL } from "../../config";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useEffect, useState } from "react";

type GetAvailableVoicesResponse = {
  data: {
    id: string;
    name: string;
  }[];
};

type AdminVoicesPageProps = {
  className?: string;
};

export const AdminVoicesPage = ({ className }: AdminVoicesPageProps) => {
  const { play } = useAudioPlayer();
  const availableVoicesApiRequest = useApiRequest<GetAvailableVoicesResponse>();
  const [currentProviderType, setCurrentProviderType] = useState<string>("cartesia");

  const onClickVoicePreviewHandler = (providerType: string, providerId: string) => () => {
    const content = "Once upon a time, in a galaxy far, far away...";
    const audioUrl = `${API_URL}/v1/voices/tts?providerType=${providerType}&providerId=${providerId}&text=${encodeURIComponent(content)}`;

    play(audioUrl);
  };

  const onChangeFormData = (formData: Voice) => {
    setCurrentProviderType(formData?.providerType);
  };

  useEffect(() => {
    availableVoicesApiRequest.fetch(`/v1/voices/available?providerType=${currentProviderType}`);
  }, [currentProviderType]);

  const fields = [
    {
      name: "voicePreview",
      label: "Voice Preview",
      component: ({ formData }: RestListComponentProps<Voice>) => (
        <Button
          type="basic"
          className="flex flex-col items-center justify-center mb-4 w-full h-24 no-repeat bg-cover bg-center bg-gray-300 rounded-lg"
          onClick={onClickVoicePreviewHandler(formData?.providerType, formData?.providerId)}
        >
          <VolumeUp className="text-6xl text-gray-700" />
        </Button>
      ),
      visible: false,
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "description",
      label: "Description",
    },
    {
      name: "providerType",
      label: "Provider Type",
      type: "dropdown",
      options: [
        { label: "Cartesia", value: "cartesia" },
        { label: "Elevenlabs", value: "elevenlabs" },
      ],
      visible: false,
      defaultValue: "cartesia",
    },
    {
      name: "providerId",
      label: "Provider Id",
      type: "searchable_dropdown",
      visible: false,
      options: availableVoicesApiRequest.data?.data?.map((voice) => ({
        label: voice.name,
        value: voice.id,
      })),
    },
    {
      name: "trainingAudioUrl",
      label: "Training Audio Url",
      visible: false,
    },
  ];

  return (
    <RestList<Voice>
      className={className}
      entityName="voice"
      fields={fields}
      onChange={onChangeFormData}
      sort={(ids, entities) => [...ids].sort((a, b) => entities[a]?.name.localeCompare(entities[b]?.name))}
    />
  );
};
