import clsx from "clsx";
import React, { useEffect, useRef } from "react";

import { FieldLabel } from "./FieldLabel";

type InputFieldProps = {
  className?: string;
  label?: string;
  name?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  value?: string;
  type?: string;
  readOnly?: boolean;
  autoGrow?: boolean;
  labelClassName?: string;
};

export const InputField = ({
  className,
  label,
  name,
  onChange = () => {},
  placeholder,
  value,
  type = "text",
  readOnly,
  autoGrow = true,
  labelClassName,
}: InputFieldProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  useEffect(() => {
    if (textareaRef.current && autoGrow) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value, autoGrow]);

  return (
    <FieldLabel
      label={label}
      className={labelClassName}
    >
      {type === "textarea" ? (
        <textarea
          ref={textareaRef}
          className={clsx(
            "indent-2 w-full rounded-md p-2 text-secondary text-2xl outline-secondary border-2 border-gray-300",
            readOnly ? "bg-gray-200" : "bg-white",
            className,
          )}
          onChange={onChangeInternal}
          name={name}
          placeholder={placeholder}
          value={value || ""}
        />
      ) : (
        <input
          className={clsx(
            "indent-2 w-full rounded-md p-2 text-secondary text-2xl outline-secondary border-2 border-gray-300",
            readOnly ? "bg-gray-200" : "bg-white",
            className,
          )}
          type={type}
          onChange={onChangeInternal}
          name={name}
          placeholder={placeholder}
          value={value || ""}
          disabled={readOnly}
        />
      )}
    </FieldLabel>
  );
};
