import { StoryAnalysisWord } from "../redux/reducers/storySlice";

export const base64Encode = (str = "") => {
  return btoa(str);
};

export const capitalize = (str = "") => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const generate = (str: string, count: number, delimiter = "") => {
  return Array(count).fill(str).join(delimiter);
};

export const clean = (str = "") => {
  let formattedStr = str.toLowerCase().replace(/([.,!?:-_\"\'])/g, "");

  formattedStr = formattedStr.replace(/zero/g, "0");
  formattedStr = formattedStr.replace(/one/g, "1");
  formattedStr = formattedStr.replace(/two/g, "2");
  formattedStr = formattedStr.replace(/three/g, "3");
  formattedStr = formattedStr.replace(/four/g, "4");
  formattedStr = formattedStr.replace(/five/g, "5");
  formattedStr = formattedStr.replace(/six/g, "6");
  formattedStr = formattedStr.replace(/seven/g, "7");
  formattedStr = formattedStr.replace(/eight/g, "8");
  formattedStr = formattedStr.replace(/nine/g, "9");

  return formattedStr;
};

export const splitSentences = (str = "") => {
  const sentenceEndings = /([.!?](?:\s|"|'|"|'))/g;
  const markedParagraph = str.replace(sentenceEndings, "$1|");
  const sentences = markedParagraph
    .split("|")
    .map((sentence) => sentence.trim())
    .filter((sentence) => sentence.length > 0);

  return sentences;
};

export const splitParagraphs = (words: StoryAnalysisWord[]) => {
  let lastParagraph = -1;

  return words.reduce((acc, word) => {
    if (lastParagraph !== word.paragraph) {
      lastParagraph = word.paragraph;

      return [...acc, [word]];
    }

    acc[acc.length - 1].push(word);

    return acc;
  }, [] as StoryAnalysisWord[][]);
};

export const splitIntoPages = (words: StoryAnalysisWord[], containerHeight: number, containerWidth: number): StoryAnalysisWord[][] => {
  if (containerHeight === 0) {
    return [];
  }

  const tempPages: StoryAnalysisWord[][] = [];

  let currentPage: StoryAnalysisWord[] = [];

  const dummyDiv = document.createElement("div");

  dummyDiv.className = "z-10 bg-white absolute top-0 left-0 flex flex-wrap content-start items-start justify-start text-3xl text-gray-500 text-opacity-80";
  dummyDiv.style.width = `${containerWidth}px`;

  document.body.appendChild(dummyDiv);

  splitParagraphs(words).forEach((paragraph) => {
    const dummyParagraph = document.createElement("span");

    dummyParagraph.className = "indent-8";

    dummyDiv.appendChild(dummyParagraph);

    paragraph?.forEach((word, index) => {
      const dummySpan = document.createElement("span");

      dummySpan.className = "indent-0";
      dummySpan.style.display = "inline-block";

      dummySpan.innerHTML = `${word.text}&nbsp;`;

      dummyParagraph.appendChild(dummySpan);

      if (dummyDiv.clientHeight > containerHeight - 8) {
        tempPages.push(currentPage);

        dummyDiv.innerHTML = "";
        dummyParagraph.innerHTML = "";

        dummyDiv.appendChild(dummyParagraph);

        if (index > 0) {
          dummyParagraph.className = "";
        }

        currentPage = [word];
      } else {
        currentPage.push(word);
      }
    });
  });

  if (currentPage.length > 0) {
    tempPages.push(currentPage);
  }

  document.body.removeChild(dummyDiv);

  return tempPages;
};
