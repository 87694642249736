import { Home, HomeOutlined, LibraryBooks, LibraryBooksOutlined, Person, Search, SearchOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import React, { FunctionComponent } from "react";

import { Avatar } from "./Avatar";
import { View } from "./View";

type MenuNavigationButtonProps = {
  icon: FunctionComponent<{ className: string }>;
  selectedIcon: FunctionComponent<{ className: string }>;
  label: string;
  path: string;
};

const MenuNavigationButton = ({ selectedIcon, icon, label, path }: MenuNavigationButtonProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onClick = () => {
    navigate(path);
  };

  return (
    <button
      className={clsx(
        "flex flex-row items-center justify-start w-full p-4 hover:bg-white hover:bg-opacity-25 text-white",
        pathname === path && "bg-white bg-opacity-25",
      )}
      onClick={onClick}
    >
      {React.createElement(pathname === path ? selectedIcon : icon, { className: "!text-[24px]" })}
      <span className="ml-4 text-xl">{label}</span>
    </button>
  );
};

type MenuNavigationProps = {
  className?: string;
};

export const MenuNavigation = ({ className }: MenuNavigationProps) => {
  return (
    <View className={clsx("flex flex-col items-start justify-between w-48 min-w-48 h-full bg-primary transition-all duration-300 shadow-inner", className)}>
      <View className="w-full">
        <MenuNavigationButton
          icon={SearchOutlined}
          selectedIcon={Search}
          label="Search"
          path="/search"
        />
        <MenuNavigationButton
          icon={HomeOutlined}
          selectedIcon={Home}
          label="Home"
          path="/"
        />
        <MenuNavigationButton
          icon={LibraryBooksOutlined}
          selectedIcon={LibraryBooks}
          label="My Library"
          path="/library"
        />
      </View>
      <View className="w-full">
        <MenuNavigationButton
          icon={() => <Person className="border-2 border-white rounded-full" />}
          selectedIcon={() => <Person className="border-2 border-white rounded-full" />}
          label="Profile"
          path="/profile"
        />
      </View>
    </View>
  );
};
