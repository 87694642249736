import { RestList } from "../../components/RestList";
import { Subscription } from "../../redux/reducers/subscriptionSlice";

type AdminSubscriptionsPageProps = {
  className?: string;
};

export const AdminSubscriptionsPage = ({ className }: AdminSubscriptionsPageProps) => {
  const fields = [
    {
      name: "imageUrl",
      label: "Image Url",
      type: "image",
      visible: false,
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "price",
      label: "Price (cents)",
      type: "number",
    },
    {
      name: "discountPrice",
      label: "Discount Price (cents)",
      type: "number",
    },
    {
      name: "voiceCount",
      label: "Voice Count",
      type: "number",
    },
    {
      name: "appleProductId",
      label: "Apple Product ID",
      type: "text",
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
      visible: false,
    },
  ];

  return (
    <RestList<Subscription>
      className={className}
      entityName="subscription"
      fields={fields}
    />
  );
};
