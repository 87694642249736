import clsx from "clsx";
import { CheckRounded } from "@mui/icons-material";

import { View } from "./View";
import { Text } from "./Text";
import { Button } from "./Button";

type SelectableCardProps = {
  className?: string;
  onClick?: () => void;
  progress?: number;
  title?: string;
  subtitle?: string;
  isSelected?: boolean;
};

export const SelectableCard = ({ className, onClick, title, subtitle, isSelected = false }: SelectableCardProps) => {
  return (
    <Button
      className={clsx(
        "flex justify-between w-full rounded-md bg-white",
        isSelected ? "border-secondary border-[3px] p-2" : "border-gray-200 border-2 p-[9px]",
        !title || !subtitle ? "items-center" : "items-start",
        className,
      )}
      onClick={onClick}
      type="basic"
    >
      <View className="flex flex-col items-start justify-center mr-4">
        {title && <Text className="text-xl text-secondary">{title}</Text>}
        {subtitle && <Text className="font-light text-left text-gray-400">{subtitle}</Text>}
      </View>
      <View
        className={clsx(
          "flex items-center justify-center w-8 h-8 min-w-8 min-h-8 rounded-full",
          isSelected ? "bg-secondary border-[1px] border-secondary" : "bg-white border-[1px] border-gray-200",
        )}
      >
        <CheckRounded htmlColor="#ffffff" />
      </View>
    </Button>
  );
};
