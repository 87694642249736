import { CircularProgress } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";

import { Book } from "../components/Book";
import { Character } from "../redux/reducers/characterSlice";
import { CHARACTER_COLORS } from "../constants/colors";
import { Image } from "../components/Image";
import { Slide } from "../components/Slide";
import { SlideGroup } from "../components/SlideGroup";
import { Story, storyActions } from "../redux/reducers/storySlice";
import { storyChunkActions } from "../redux/reducers/storyChunkSlice";
import { StoryTitleCard } from "../components/StoryTitleCard";
import { useAppDispatch, useAppSelector } from "../redux";
import { useEntity } from "../hooks/useEntity";
import { useSearchParam } from "../hooks/useSearchParam";
import { useSearchParams } from "react-router-dom";
import { View } from "../components/View";
import { World } from "../redux/reducers/worldSlice";
import { useApiRequest } from "../hooks/useApiRequest";
import { AudioPlayer, AudioPlayerRef } from "../components/AudioPlayer";
import { BookAvatarBar } from "../components/BookAvatarBar";
import { QuestionBar } from "../components/QuestionBar";

type StoryPageProps = {
  className?: string;
};

export const StoryPage = ({ className }: StoryPageProps) => {
  const dispatch = useAppDispatch();
  const storyId = useSearchParam("id");
  const currentSlide = useSearchParam("p") ? 1 : 0;

  const { entity: story } = useEntity<Story>("story", storyId);
  const { entity: world } = useEntity<World>("world", story?.worldId);
  const storyChunkMap = useAppSelector((state) => state.storyChunk.entities);

  const [_, setSearchParams] = useSearchParams();
  const [bookAvatarBarIsOpen, setBookAvatarBarIsOpen] = useState<boolean>(true);
  const [currentWordIndex, setCurrentWordIndex] = useState<number>(-1);
  const [isQuestionActive, setIsQuestionActive] = useState<boolean>(false);
  const [isDefinitionOpen, setDefinitionIsOpen] = useState<boolean>(false);
  const defineWordApiRequest = useApiRequest<{ audioUrl: string; definition: string }>();
  const audioRef = useRef<AudioPlayerRef>(null);

  const storyCharacters = useMemo<Character[]>(() => {
    if (!story) {
      return [];
    }

    return [story.narrator].concat(story.speakingCharacters);
  }, [story]);

  const storyChunks = useMemo(() => {
    return Object.values(storyChunkMap)
      .filter((chunk) => chunk.storyId === storyId)
      .sort((a, b) => a.index - b.index)
      .map((chunk) => ({
        ...chunk,
        color: CHARACTER_COLORS[storyCharacters.findIndex((character) => character?.id === chunk.characterId) || 0],
      }));
  }, [storyCharacters, storyChunkMap, storyId]);

  const currentCharacter = useMemo(() => {
    const characterId = story?.analysis?.words[currentWordIndex]?.characterId;

    return storyCharacters.find((character) => characterId === character?.id);
  }, [storyCharacters, storyChunks, currentWordIndex]);

  const currentCharacterColors = useMemo(() => {
    return storyCharacters.reduce(
      (acc, character, index) => {
        acc[character?.id] = CHARACTER_COLORS[index];

        return acc;
      },
      {} as Record<string, string>,
    );
  }, [storyCharacters]);

  const onClickCharacter = (characterId: string) => {};

  const onClickRead = () => {
    setSearchParams({ id: storyId, p: "1" });
  };

  const onClickBookAvatarBarTab = () => {
    setBookAvatarBarIsOpen((isOpen) => !isOpen);
  };

  const onClickWord = async (index: number) => {
    if (isQuestionActive) {
      const word = story?.analysis?.words[index]?.text;

      setIsQuestionActive(false);
      setDefinitionIsOpen(true);

      defineWordApiRequest.fetch(`/v1/stories/${storyId}/define-word`, {
        method: "POST",
        body: {
          word,
        },
      });
    } else {
      audioRef.current?.setCurrentTime(((story?.analysis?.words[index].start || 0) + 10) / 1000);
    }
  };

  const onClickQuestion = () => {
    setIsQuestionActive(!isQuestionActive);
  };

  const onChangeWordIndex = (wordIndex: number) => {
    setCurrentWordIndex(wordIndex);
  };

  const onClickQuestionBarClose = () => {
    setDefinitionIsOpen(false);
  };

  useEffect(() => {
    if (storyId) {
      dispatch(storyChunkActions.getByStoryId({ storyId, languageCode: "en-us" }));
    }

    dispatch(storyActions.getById(storyId));
  }, []);

  return (
    <>
      <View className={clsx("flex flex-col items-center justify-center w-full h-full", className)}>
        {!story || !world ? (
          <CircularProgress />
        ) : (
          <>
            <Image
              className="absolute top-0 bottom-0 left-0 right-0 z-0"
              src={world.image}
            />
            <SlideGroup currentSlide={currentSlide}>
              <Slide className="!pt-[96px]">
                <StoryTitleCard
                  characters={storyCharacters}
                  title={story.title}
                  onClick={onClickRead}
                  showReadButton
                />
              </Slide>
              <Slide className="!pt-[96px]">
                <View className={clsx("w-full h-full transition-all duration-700", bookAvatarBarIsOpen && "pb-32")}>
                  <Book
                    characterColors={currentCharacterColors}
                    currentWordIndex={currentWordIndex}
                    onClickPlayWord={onClickWord}
                    words={story?.analysis?.words}
                  />
                </View>
              </Slide>
            </SlideGroup>
          </>
        )}
      </View>
      <BookAvatarBar
        className={clsx(
          "absolute bottom-0 left-1/2 -translate-x-1/2 z-10 transition-all duration-700 w-[100vw] md:w-auto md:max-w-[80vw]",
          currentSlide === 0 ? "translate-y-[500px]" : bookAvatarBarIsOpen ? "translate-y-0" : "translate-y-full",
        )}
        characters={storyCharacters}
        currentCharacterId={currentCharacter?.id}
        characterColors={currentCharacterColors}
        onClickCharacter={onClickCharacter}
        onClickTab={onClickBookAvatarBarTab}
        isOpen={bookAvatarBarIsOpen}
      />
      <AudioPlayer
        ref={audioRef}
        analysis={story?.analysis}
        audioUrl={story?.audioUrl}
        className={clsx(
          "absolute right-1/2 translate-x-1/2 md:bottom-4 md:right-4 md:translate-x-0 bg-white bg-opacity-80 backdrop-blur-md rounded-full transition-all duration-700",
          currentSlide === 0 ? "translate-y-[500px] bottom-0" : "translate-y-0 bottom-24",
        )}
        currentWordIndex={currentWordIndex}
        onChangeWordIndex={onChangeWordIndex}
        isCollapsable
      />
      <QuestionBar
        audioUrl={defineWordApiRequest.data?.audioUrl}
        definition={defineWordApiRequest.data?.definition}
        isOpen={isDefinitionOpen}
        onClickClose={onClickQuestionBarClose}
      />
    </>
  );
};
