import { AddCircleOutline, Edit, Person } from "@mui/icons-material";

import { Button } from "./Button";
import { Image } from "./Image";
import { ImageField } from "./ImageField";
import { View } from "./View";

type AvatarFieldProps = {
  className?: string;
  name?: string;
  value?: string;
  onChange?: (value: string) => void;
};

export const AvatarField = ({ name, value, onChange = () => {}, className }: AvatarFieldProps) => {
  return (
    <ImageField
      name={name}
      onChange={onChange}
    >
      <Button
        type="basic"
        className="relative !w-32 !h-32 flex flex-col items-center justify-center bg-white !rounded-full mb-4"
      >
        {value ? (
          <>
            <Image
              src={value}
              alt="avatar"
              className="w-full h-full rounded-full object-cover"
            />
            <View className="absolute top-0 -right-1 border-4 border-white rounded-full !w-10 !h-10">
              <Edit className="!w-full !h-full p-1 text-primary bg-white rounded-full border-[3px] border-primary" />
            </View>
          </>
        ) : (
          <>
            <View className="relative">
              <View className="flex items-center justify-center w-32 h-32 rounded-full border-4 border-gray-300 bg-gray-300 overflow-hidden">
                <Person className="mt-10 !w-40 !h-40 text-white" />
              </View>
            </View>
            <AddCircleOutline className="absolute top-0 -right-1 !w-10 !h-10 text-primary bg-white rounded-full" />
          </>
        )}
      </Button>
    </ImageField>
  );
};
