import { useEffect, useState } from "react";
import { RefreshOutlined, AddCircleOutline, Edit, Check, Close, Delete } from "@mui/icons-material";

import { AudioPlayer } from "./AudioPlayer";
import { audioStoryActions } from "../redux/reducers/audioStorySlice";
import { AudioStoryChunk } from "../redux/reducers/audioStorySlice";
import { Button } from "./Button";
import { Modal } from "./Modal";
import { Text } from "./Text";
import { useApiRequest } from "../hooks/useApiRequest";
import { useAppDispatch, useAppSelector } from "../redux";
import { View } from "./View";
import { InputField } from "./base/InputField";

type AudioStoryChunksModalProps = {
  audioStoryId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const AudioStoryChunksModal = ({ audioStoryId, isOpen, onClose }: AudioStoryChunksModalProps) => {
  const dispatch = useAppDispatch();
  const audioStory = useAppSelector((state) => state.audioStory.entities[audioStoryId]);
  const apiRequestRegenerateAudioStory = useApiRequest<never>();
  const apiRequestRegenerateChunk = useApiRequest<never>();
  const apiRequestGetChunks = useApiRequest<{ data: AudioStoryChunk[] }>();
  const apiRequestAddChunk = useApiRequest<never>();
  const apiRequestUpdateChunk = useApiRequest<never>();
  const apiRequestDeleteChunk = useApiRequest<never>();
  const [currentChunkId, setCurrentChunkId] = useState<string>("");
  const [editingChunkId, setEditingChunkId] = useState<string>("");
  const [editingContent, setEditingContent] = useState<string>("");
  const [editingDirection, setEditingDirection] = useState<string>("");

  const onClickRegenerateAudioStory = async () => {
    await apiRequestRegenerateAudioStory.fetch(`/v1/audioStories/${audioStoryId}/generate`, {
      method: "POST",
    });

    dispatch(audioStoryActions.getById(audioStoryId));
  };

  const onClickRegenerateChunkHandler = (chunkId: string) => async () => {
    setCurrentChunkId(chunkId);

    await apiRequestRegenerateChunk.fetch(`/v1/audioStories/${audioStoryId}/chunks/${chunkId}/generate`, {
      method: "POST",
    });

    await apiRequestGetChunks.fetch(`/v1/audioStories/${audioStoryId}/chunks`);

    setCurrentChunkId("");
  };

  const onClickAddChunkHandler = (index: number) => async () => {
    await apiRequestAddChunk.fetch(`/v1/audioStories/${audioStoryId}/chunks`, {
      method: "POST",
      body: { index },
    });

    await apiRequestGetChunks.fetch(`/v1/audioStories/${audioStoryId}/chunks`);
  };

  const onClickEditChunk = (chunk: AudioStoryChunk) => () => {
    setEditingChunkId(chunk.id);
    setEditingContent(chunk.content);
    setEditingDirection(chunk.direction);
  };

  const onClickSaveChunk = async () => {
    await apiRequestUpdateChunk.fetch(`/v1/audioStories/${audioStoryId}/chunks/${editingChunkId}`, {
      method: "PATCH",
      body: {
        content: editingContent,
        direction: editingDirection,
      },
    });

    await apiRequestGetChunks.fetch(`/v1/audioStories/${audioStoryId}/chunks`);

    setEditingChunkId("");
    setEditingContent("");
  };

  const onClickCancelEdit = () => {
    setEditingChunkId("");
    setEditingContent("");
  };

  const onClickDeleteChunk = (chunkId: string) => async () => {
    await apiRequestDeleteChunk.fetch(`/v1/audioStories/${audioStoryId}/chunks/${chunkId}`, {
      method: "DELETE",
    });

    await apiRequestGetChunks.fetch(`/v1/audioStories/${audioStoryId}/chunks`);
  };

  useEffect(() => {
    if (audioStoryId) {
      apiRequestGetChunks.fetch(`/v1/audioStories/${audioStoryId}/chunks`);
    }
  }, [audioStoryId]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Audio Story Chunks"
      className="min-w-[90vw] min-h-[90vh]"
    >
      <View className="flex flex-col w-full">
        <View
          className="grid w-full pb-2 font-semibold border-b border-gray-300"
          style={{ gridTemplateColumns: "1fr 1fr 160px" }}
        >
          <View>Base Audio</View>
          <View>Narrator Audio</View>
          <View></View>
        </View>
        <View
          className="grid w-full gap-4 mb-4 items-center"
          style={{ gridTemplateColumns: "1fr 1fr 160px" }}
        >
          <AudioPlayer
            audioUrl={audioStory?.baseAudioUrl}
            size="small"
            className="mr-4"
          />
          <AudioPlayer
            audioUrl={audioStory?.audioUrl}
            size="small"
            className="mr-4"
          />
          <Button
            type="outlined"
            onClick={onClickRegenerateAudioStory}
            isLoading={apiRequestRegenerateAudioStory.isLoading}
            className="h-12"
          >
            Regenerate
          </Button>
        </View>
        <View
          className="grid w-full pb-2 font-semibold border-b border-gray-300"
          style={{ gridTemplateColumns: "1fr 192px 192px" }}
        >
          <View>Content</View>
          <View>Audio</View>
          <View>Actions</View>
        </View>
        {apiRequestGetChunks.isLoading ? (
          <View className="flex flex-row items-center justify-center w-full h-64">Loading...</View>
        ) : (
          <View className="w-full overflow-y-auto max-h-[60vh] pt-8">
            {apiRequestGetChunks.data?.data
              ?.sort((a, b) => a.index - b.index)
              .map((chunk) => (
                <>
                  <View
                    className="grid items-center border-b border-gray-300 pb-8"
                    style={{ gridTemplateColumns: "1fr 192px 192px" }}
                  >
                    {editingChunkId === chunk.id ? (
                      <View className="flex flex-col gap-2">
                        <InputField
                          label="Direction"
                          value={editingDirection}
                          onChange={(value) => setEditingDirection(value)}
                          className="w-full mr-4 p-2 border border-gray-300 rounded"
                          type="textarea"
                        />
                        <InputField
                          label="Script"
                          value={editingContent}
                          onChange={(value) => setEditingContent(value)}
                          className="w-full mr-4 p-2 border border-gray-300 rounded"
                          type="textarea"
                        />
                      </View>
                    ) : (
                      <Text className="mr-4">{chunk.content}</Text>
                    )}
                    <AudioPlayer
                      audioUrl={chunk.audioUrl}
                      size="small"
                      className="mx-4"
                      showProgressBar={false}
                    />
                    <View className="flex flex-row gap-2">
                      {editingChunkId === chunk.id ? (
                        <>
                          <Button
                            type="outlined"
                            onClick={onClickSaveChunk}
                            isLoading={apiRequestUpdateChunk.isLoading}
                          >
                            <Check className="text-primary" />
                          </Button>
                          <Button
                            type="outlined"
                            onClick={onClickCancelEdit}
                          >
                            <Close className="text-primary" />
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type="outlined"
                            onClick={onClickEditChunk(chunk)}
                          >
                            <Edit className="text-primary" />
                          </Button>
                          <Button
                            type="outlined"
                            onClick={onClickRegenerateChunkHandler(chunk.id)}
                            isLoading={apiRequestRegenerateChunk.isLoading && currentChunkId === chunk.id}
                          >
                            <RefreshOutlined className="text-primary" />
                          </Button>
                          <Button
                            type="outlined"
                            onClick={onClickDeleteChunk(chunk.id)}
                            isLoading={apiRequestDeleteChunk.isLoading}
                          >
                            <Delete className="text-primary" />
                          </Button>
                        </>
                      )}
                    </View>
                  </View>
                  <View className="flex flex-row justify-center w-full -translate-y-4">
                    <Button
                      type="text"
                      onClick={onClickAddChunkHandler(chunk.index + 1)}
                      isLoading={apiRequestAddChunk.isLoading}
                      className="!w-12 !m-auto py-1 bg-gray-300"
                    >
                      <AddCircleOutline className="text-primary" />
                    </Button>
                  </View>
                </>
              ))}
            {apiRequestGetChunks.data?.data?.length === 0 && <View className="flex flex-row items-center justify-center w-full h-64">No chunks found.</View>}
          </View>
        )}
      </View>
    </Modal>
  );
};
