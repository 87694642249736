import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import clsx from "clsx";
import ReactFlagsSelect from "react-flags-select";

import { Button } from "../components/base/Button";
import { COUNTRY_TO_LANGUAGE_CODES, LANGUAGE_TO_COUNTRY_CODES } from "../constants/language";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { Text } from "../components/Text";
import { useAppDispatch, useAppSelector } from "../redux";
import { View } from "../components/base/View";

const MENU_ITEMS = [
  {
    title: "Audio Stories",
    path: "/admin/audioStories",
  },
  {
    title: "Audio Story Playlists",
    path: "/admin/audioStoryPlaylists",
  },
  {
    title: "Background Tracks",
    path: "/admin/backgroundTracks",
  },
  {
    title: "Voices",
    path: "/admin/voices",
  },
  {
    title: "Users",
    path: "/admin/users",
  },
  {
    title: "Accounts",
    path: "/admin/accounts",
  },
  {
    title: "Subscriptions",
    path: "/admin/subscriptions",
  },
  {
    title: "Personas",
    path: "/admin/personas",
  },
  {
    title: "Story Builder",
    path: "/admin/storyBuilder",
  },
  {
    title: "Feedback",
    path: "/admin/feedback",
  },
];

type AdminLayoutProps = {
  className?: string;
};

export const AdminLayout = ({ className }: AdminLayoutProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.session.user);
  const languageCode = useAppSelector((state) => state.session.languageCode);

  const onClickMenuItemHandler = (path: string) => () => {
    navigate(path);
  };

  const onSelectCountry = (countryCode: string) => {
    dispatch(sessionActions.setLanguage(COUNTRY_TO_LANGUAGE_CODES[countryCode]));
  };

  useEffect(() => {
    if (!user) {
      navigate(`/signin?redirect=${pathname}`);
    }

    if (user && !user.isAdmin) {
      navigate("/");
    }
  }, [user]);

  return user ? (
    <View className={clsx(className, "flex flex-row items-center justify-start w-full h-full overflow-hidden bg-white text-gray-900 admin-layout")}>
      <View className="flex flex-col items-center justify-between w-[200px] min-w-[200px] h-full bg-gray-200">
        <View className="flex flex-col items-center w-full h-full">
          {MENU_ITEMS.map((item) => (
            <Button
              key={item.title}
              type="basic"
              className={clsx("w-full p-4 !justify-start !rounded-none", pathname === item.path ? "bg-gray-300" : "")}
              onClick={onClickMenuItemHandler(item.path)}
            >
              <Text className="text-left">{item.title}</Text>
            </Button>
          ))}
        </View>
        <ReactFlagsSelect
          selectButtonClassName="mt-1 w-full h-full !border-none"
          selected={LANGUAGE_TO_COUNTRY_CODES[languageCode || "en-us"]}
          onSelect={onSelectCountry}
          countries={Object.keys(COUNTRY_TO_LANGUAGE_CODES)}
          showSelectedLabel={false}
          showSecondarySelectedLabel={false}
          showOptionLabel={false}
          showSecondaryOptionLabel={false}
          rfsKey="language"
        />
      </View>
      <Outlet />
    </View>
  ) : (
    <View className={className} />
  );
};
