import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { View } from "../components/View";

type NotFoundPageProps = {
  className?: string;
};

export const NotFoundPage = ({ className }: NotFoundPageProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname.indexOf("admin") > -1) {
      navigate("/admin");
    } else {
      navigate("/");
    }
  }, []);

  return <View className={className} />;
};
