import { ErrorOutlineOutlined } from "@mui/icons-material";
import { View } from "./View";
import { Text } from "./Text";
import clsx from "clsx";

type ErrorMessageProps = {
  className?: string;
  text?: string | null;
};

export const ErrorMessage = ({ className, text }: ErrorMessageProps) => (
  <View className={clsx("w-full flex items-center justify-center", text ? "opacity-100" : "opacity-0", className)}>
    <ErrorOutlineOutlined className="text-red-500 mr-2" />
    <Text className="text-red-500 text-center">{text}</Text>
  </View>
);
