import { Haptics } from "../../utils/haptics";
import { OnboardingCard } from "../../components/OnboardingCard";
import { ROUTES } from "../../utils/routes";
import { Text } from "../../components/base/Text";
import { useNavigation } from "../../hooks/useNavigation";
import { useRoute } from "../../hooks/useRoute";
import { View } from "../../components/base/View";

type Route = {
  params: {
    onboardingType: "contributor" | "account";
  };
};

export const OnboardingCompletePage = () => {
  const navigation = useNavigation();
  const route = useRoute<Route>();

  const onClickContinue = () => {
    Haptics.trigger(Haptics.HapticFeedbackTypes.impactLight);

    if (route.params.onboardingType === "contributor") {
      navigation.navigate(ROUTES.SIGN_IN);
    } else {
      navigation.navigate(ROUTES.AUDIO_LIBRARY);
    }
  };

  return (
    <OnboardingCard
      className="p-8 max-w-sm w-full"
      continueText={route.params.onboardingType === "contributor" ? "Back to Sign In" : "Start Listening!"}
      onClickContinue={onClickContinue}
    >
      <View className="flex flex-col flex-grow items-center justify-center">
        <Text className="text-3xl text-center text-title">You're all set! 🎉</Text>
        {route.params.onboardingType === "contributor" && <Text className="text-lg text-center text-subtitle">Thank you for your time and support!</Text>}
        <Text className="text-lg text-center text-subtitle mb-4">Your family will be thrilled to hear your voice and others in a story.</Text>
      </View>
    </OnboardingCard>
  );
};
