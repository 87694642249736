import { useState } from "react";

import { Account } from "../../redux/reducers/accountSlice";
import { AccountContributorModal } from "../../components/AccountContributorModal";
import { AccountReaderModal } from "../../components/AccountReaderModal";
import { AccountUserModal } from "../../components/AccountUserModal";
import { Button } from "../../components/Button";
import { makeRequest } from "../../utils/request";
import { RestList, RestListComponentProps } from "../../components/RestList";
import { Subscription } from "../../redux/reducers/subscriptionSlice";
import { useEntity } from "../../hooks/useEntity";
import { User } from "../../redux/reducers/userSlice";

export const AdminAccountsPage = () => {
  const [selectedAccountId, setSelectedAccountId] = useState<string>("");
  const [isUsersModalOpen, setIsUsersModalOpen] = useState<boolean>(false);
  const [isAccountContributorModalOpen, setIsAccountContributorModalOpen] = useState<boolean>(false);
  const [isAccountReaderModalOpen, setIsAccountReaderModalOpen] = useState<boolean>(false);
  const { entities: subscriptionEntities } = useEntity<Subscription>("subscription");

  const search = async (query: string): Promise<string[]> => {
    try {
      const { data } = await makeRequest<{ data: User[] }>(`/v1/users/search?q=${query}`);

      return data.map((user) => user.accountId ?? "").filter((accountId) => accountId !== "");
    } catch (error) {
      console.error("Error searching users:", error);
    }

    return [];
  };

  const onClickUsersHandler = (id: string) => () => {
    setSelectedAccountId(id);
    setIsUsersModalOpen(true);
  };

  const onClickCloseUsersModal = () => {
    setSelectedAccountId("");
    setIsUsersModalOpen(false);
  };

  const onClickAccountContributorHandler = (id: string) => () => {
    setSelectedAccountId(id);
    setIsAccountContributorModalOpen(true);
  };

  const onClickAccountReaderHandler = (id: string) => () => {
    setSelectedAccountId(id);
    setIsAccountReaderModalOpen(true);
  };

  const onClickCloseAccountContributorModal = () => {
    setSelectedAccountId("");
    setIsAccountContributorModalOpen(false);
  };

  const onClickCloseAccountReaderModal = () => {
    setSelectedAccountId("");
    setIsAccountReaderModalOpen(false);
  };

  return (
    <>
      <RestList
        entityName="account"
        search={search}
        fields={[
          {
            name: "id",
            label: "ID",
            readOnly: true,
          },
          {
            name: "subscriptionId",
            label: "Subscription",
            type: "dropdown",
            options: Object.values(subscriptionEntities).map((subscription) => ({
              label: subscription.name,
              value: subscription.id,
            })),
          },
          {
            name: "users",
            label: "Users",
            type: "button",
            visible: false,
            component: ({ id }: RestListComponentProps<Account>) => (
              <Button
                className="mt-2"
                onClick={onClickUsersHandler(id)}
                type="outlined"
              >
                Users
              </Button>
            ),
          },
          {
            name: "contributors",
            label: "Contributors",
            type: "button",
            visible: false,
            component: ({ id }: RestListComponentProps<Account>) => (
              <Button
                className="mt-2"
                onClick={onClickAccountContributorHandler(id)}
                type="outlined"
              >
                Contributors
              </Button>
            ),
          },
          {
            name: "readers",
            label: "Readers",
            type: "button",
            visible: false,
            component: ({ id }: RestListComponentProps<Account>) => (
              <Button
                className="mt-2"
                onClick={onClickAccountReaderHandler(id)}
                type="outlined"
              >
                Readers
              </Button>
            ),
          },
        ]}
      />
      <AccountUserModal
        accountId={selectedAccountId}
        isOpen={isUsersModalOpen}
        onClose={onClickCloseUsersModal}
      />
      <AccountContributorModal
        accountId={selectedAccountId}
        isOpen={isAccountContributorModalOpen}
        onClose={onClickCloseAccountContributorModal}
      />
      <AccountReaderModal
        accountId={selectedAccountId}
        isOpen={isAccountReaderModalOpen}
        onClose={onClickCloseAccountReaderModal}
      />
    </>
  );
};
