import { createRestSlice } from "./restSlice";

export type Feedback = {
  id: string;
  email: string;
  content: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: null | string;
};

const feedbackSlice = createRestSlice<Feedback>("feedback", "/v1/users/feedback");

export const feedbackActions = feedbackSlice.actions;
export const feedbackReducer = feedbackSlice.reducer;
