import React from "react";
import { View } from "./View";
import { Text } from "./Text";
import { Button } from "./Button";
import clsx from "clsx";
import { Close } from "@mui/icons-material";
import { Card } from "./base/Card";

interface ModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  onDelete?: () => void;
  onConfirmLoading?: boolean;
  onDeleteLoading?: boolean;
  onConfirmText?: string;
  onDeleteText?: string;
  children?: React.ReactNode;
  title?: string;
  className?: string;
}

export const Modal = ({
  children,
  className,
  isOpen = false,
  onClose,
  onConfirm,
  onDelete,
  onDeleteLoading = false,
  onConfirmLoading = false,
  onConfirmText = "Save",
  onDeleteText = "Delete",
  title = "",
}: ModalProps) => {
  if (!isOpen) return null;

  return (
    <View className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <Card className={clsx("flex flex-col bg-white p-4 pt-3 w-full h-full !bg-opacity-90 md:rounded-lg md:shadow-xl md:max-w-md md:h-auto", className)}>
        <View className="flex justify-between items-center mb-4">
          <Text className="text-xl font-bold">{title}</Text>
          <Button
            onClick={onClose}
            type="basic"
            className="!w-8 !h-8"
          >
            <Close
              className="!w-6 !h-6"
              htmlColor="#454545"
            />
          </Button>
        </View>
        <View className="flex flex-grow mb-4 overflow-y-auto md:max-h-none">{children}</View>
        <View className="flex justify-end">
          {onDelete && (
            <Button
              onClick={onDelete}
              isLoading={onDeleteLoading}
              className="mr-2"
              color="danger"
              type="outlined"
            >
              {onDeleteText}
            </Button>
          )}
          {onConfirm && (
            <Button
              onClick={onConfirm}
              isLoading={onConfirmLoading}
            >
              {onConfirmText}
            </Button>
          )}
        </View>
      </Card>
    </View>
  );
};
