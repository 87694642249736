import { useEffect, useRef, useState } from "react";
import { Button } from "./Button";
import { Text } from "./Text";
import { PlayArrowRounded, QuestionMark } from "@mui/icons-material";
import { View } from "./View";
import clsx from "clsx";

type BookPageTextProps = {
  className?: string;
  color?: string;
  isActiveSentence?: boolean;
  isActiveWord?: boolean;
  onClickPlay?: () => void;
  onClickDefine?: () => void;
  text?: string;
};

export const BookPageText = ({
  color = "#8A61FB",
  isActiveSentence,
  isActiveWord,
  onClickPlay = () => {},
  onClickDefine = () => {},
  text = "",
}: BookPageTextProps) => {
  const endsWithPunctuation = [".", "!", "?"].includes(text.slice(-1));
  const word = text.match(/[\w']+/)?.[0] || "";
  const startWithoutSymbolsIndex = text.indexOf(word);
  const endWithoutSymboldIndex = startWithoutSymbolsIndex + word.length;
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onClick = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const onClickPlayInternal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    onClickPlay();
    setIsTooltipOpen(false);
  };

  const onClickDefineInternal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    onClickDefine();
    setIsTooltipOpen(false);
  };

  useEffect(() => {
    const closeTooltip = (event: MouseEvent) => {
      if (buttonRef.current && !buttonRef.current?.contains(event.target as Node)) {
        setIsTooltipOpen(false);
      }
    };

    if (isTooltipOpen) {
      window.addEventListener("click", closeTooltip);
    }

    return () => {
      window.removeEventListener("click", closeTooltip);
    };
  }, [isTooltipOpen]);

  return (
    <Button
      ref={buttonRef}
      type="basic"
      className="!w-auto !p-0 active:!scale-100 transition-none"
      onClick={onClick}
    >
      <Text
        className="indent-0"
        style={
          isActiveSentence && startWithoutSymbolsIndex > 0
            ? {
                textDecoration: "underline",
                textDecorationSkipInk: "none",
                textDecorationThickness: 4,
                textDecorationColor: `${color}80`,
              }
            : {}
        }
      >
        {text.slice(0, startWithoutSymbolsIndex)}
      </Text>
      <Text
        className="indent-0"
        style={
          isActiveSentence
            ? {
                textDecoration: "underline",
                textDecorationSkipInk: "none",
                textDecorationThickness: 4,
                textDecorationColor: isActiveWord ? color : `${color}80`,
              }
            : {}
        }
      >
        {word}
      </Text>
      <Text
        className="indent-0"
        style={
          isActiveSentence && endWithoutSymboldIndex < text.length
            ? {
                textDecoration: "underline",
                textDecorationSkipInk: "none",
                textDecorationThickness: 4,
                textDecorationColor: `${color}80`,
              }
            : {}
        }
      >
        {text.slice(endWithoutSymboldIndex)}
      </Text>
      <Text
        className="indent-0"
        style={
          isActiveSentence && !endsWithPunctuation
            ? {
                textDecoration: "underline",
                textDecorationSkipInk: "none",
                textDecorationThickness: 4,
                textDecorationColor: `${color}80`,
              }
            : {}
        }
      >
        &nbsp;
      </Text>
      <View
        className={clsx(
          "absolute bottom-full left-1/2 transform -translate-x-1/2 bg-white bg-opacity-50 backdrop-blur-sm rounded-lg shadow-md p-2 flex items-center space-x-2 transition-all",
          isTooltipOpen ? "scale-100 translate-y-0 opacity-100" : "scale-0 translate-y-full opacity-0",
        )}
      >
        <Button
          type="basic"
          className="!w-8 !h-8 bg-primary rounded-full flex items-center justify-center"
          onClick={onClickPlayInternal}
        >
          <PlayArrowRounded className="!w-5 !h-5 text-white" />
        </Button>
        <Button
          type="basic"
          className="!w-8 !h-8 bg-secondary rounded-full flex items-center justify-center"
          onClick={onClickDefineInternal}
        >
          <QuestionMark className="!w-5 !h-5 text-white" />
        </Button>
      </View>
    </Button>
  );
};
