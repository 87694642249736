import { DeleteOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";

import { AudioPlayer } from "../../components/AudioPlayer";
import { AudioStory, AudioStoryVersion } from "../../redux/reducers/audioStorySlice";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";
import { RestList, RestListComponentProps } from "../../components/RestList";
import { Text } from "../../components/Text";
import { useApiRequest } from "../../hooks/useApiRequest";
import { View } from "../../components/View";
import { InputField } from "../../components/InputField";
import { useEntity } from "../../hooks/useEntity";
import { Persona } from "../../redux/reducers/personaSlice";
import { AudioStoryChunksModal } from "../../components/AudioStoryChunksModal";
import { AudioStoryVersionsModal } from "../../components/AudioStoryVersionsModal";

type AdminAudioStoriesPageProps = {
  className?: string;
};

export const AdminAudioStoriesPage = ({ className }: AdminAudioStoriesPageProps) => {
  const { entities: personaEntities } = useEntity<Persona>("persona");
  const [selectedAudioStoryId, setSelectedAudioStoryId] = useState<string>("");
  const [versionsModalIsOpen, setVersionsModalIsOpen] = useState<boolean>(false);
  const [chunksModalIsOpen, setChunksModalIsOpen] = useState<boolean>(false);

  const fields = [
    {
      name: "title",
      label: "Title",
    },
    {
      name: "personaId",
      label: "Persona",
      type: "dropdown",
      options: Object.values(personaEntities).map((persona) => ({
        label: persona.name,
        value: persona.id,
      })),
    },
    {
      name: "content",
      label: "Content",
      type: "textarea",
    },
    {
      name: "audioPlayer",
      label: "Audio Player",
      component: ({ formData }: RestListComponentProps<AudioStory>) =>
        formData?.audioUrl ? (
          <AudioPlayer
            className="flex-grow mb-4 px-0"
            audioUrl={formData?.audioUrl}
          />
        ) : null,
      visible: false,
    },
    {
      name: "actions",
      label: "Actions",
      visible: false,
      component: ({ id }: RestListComponentProps<AudioStory>) => {
        const onClickViewVersions = () => {
          setVersionsModalIsOpen(true);
          setSelectedAudioStoryId(id);
        };

        const onClickViewChunks = () => {
          setChunksModalIsOpen(true);
          setSelectedAudioStoryId(id);
        };

        return id !== "new" ? (
          <View className="flex flex-col items-center justify-center w-full">
            <Button
              className="mb-2"
              type="outlined"
              onClick={onClickViewChunks}
            >
              View Chunks
            </Button>
            <Button
              className="mb-2"
              type="outlined"
              onClick={onClickViewVersions}
            >
              View Versions
            </Button>
          </View>
        ) : null;
      },
    },
  ];

  const onClickCloseChunksModal = () => {
    setChunksModalIsOpen(false);
    setSelectedAudioStoryId("");
  };

  const onClickCloseVersionsModal = () => {
    setVersionsModalIsOpen(false);
    setSelectedAudioStoryId("");
  };

  return (
    <>
      <RestList<AudioStory>
        className={className}
        entityName="audioStory"
        fields={fields}
        sort={(ids, entities) => [...ids].sort((a, b) => entities[a]?.title.localeCompare(entities[b]?.title))}
      />
      <AudioStoryChunksModal
        audioStoryId={selectedAudioStoryId}
        isOpen={chunksModalIsOpen}
        onClose={onClickCloseChunksModal}
      />
      <AudioStoryVersionsModal
        audioStoryId={selectedAudioStoryId}
        isOpen={versionsModalIsOpen}
        onClose={onClickCloseVersionsModal}
      />
    </>
  );
};
