import clsx from "clsx";

import { Character } from "../redux/reducers/characterSlice";
import { Avatar } from "./Avatar";
import { Text } from "./Text";
import { View } from "./View";
import { useWindowDimensions } from "../hooks/useWindowDimensions";

type BookAvatarBarProps = {
  className?: string;
  characters?: Character[];
  currentCharacterId?: string;
  characterColors?: Record<string, string>;
  onClickCharacter?: (id: string) => void;
  onClickTab?: () => void;
  isOpen?: boolean;
};

export const BookAvatarBar = ({ characterColors = {}, characters, className, currentCharacterId, onClickCharacter = () => {} }: BookAvatarBarProps) => {
  const { windowWidth } = useWindowDimensions();

  const onClickCharacterHandler = (id: string) => () => {
    onClickCharacter(id);
  };

  return (
    <View
      className={clsx(
        "flex items-center justify-center gap-4 px-4 py-2 md:px-8 md:py-4 md:rounded-t-md bg-white bg-opacity-80 backdrop-blur-md shadow-avatar-bar",
        className,
      )}
    >
      {characters?.slice(0, windowWidth < 768 ? -1 : Math.floor((windowWidth - 428) / 128)).map((character) => (
        <View
          key={character?.id}
          className="flex flex-col w-full h-full items-center justify-end cursor-pointer min-w-[72px] md:min-w-[144px] md:max-w-[144px]"
        >
          <Avatar
            className="!w-[64px] !h-[64px] md:!w-[92px] md:!h-[92px] transition-all !duration-700"
            style={{
              borderColor: characterColors[character?.id],
            }}
            image={character?.image}
            name={character?.name}
            characterId={character?.id}
            onClick={onClickCharacterHandler(character?.id)}
          />
          <View
            className="w-full hidden md:flex items-center justify-center text-lg font-semibold mt-2 p-2 rounded-full shadow-lg transition-all duration-700"
            style={{
              color: currentCharacterId === character?.id ? "#FFFFFF" : characterColors[character?.id],
              backgroundColor: currentCharacterId === character?.id ? characterColors[character?.id] : "#FFFFFF",
            }}
          >
            <Text className="truncate">{character?.name}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};
