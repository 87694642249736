import { Add, Book, EmojiEmotions, FitnessCenter, Forest, Group, Handshake, HealthAndSafety, Lightbulb, Person, Public, Science } from "@mui/icons-material";

import { StorySet } from "../redux/reducers/storySetSlice";
import { Text } from "./Text";
import { View } from "./View";
import { Card } from "./Card";
import { ListItem } from "./ListItem";

type StorySetListCardProps = {
  className?: string;
  title: string;
  storySets: StorySet[];
  onClickStorySet: (storySetId: string) => void;
};

export const StorySetListCard = ({ className, title, storySets = [], onClickStorySet = () => {} }: StorySetListCardProps) => {
  const onClickStorySetHandler = (storySetId: string) => () => {
    onClickStorySet(storySetId);
  };

  const getIcon = (icon: string) => {
    switch (icon) {
      case "social_development":
        return <Group className="!w-8 !h-8 text-white" />;
      case "health_and_wellness":
        return <HealthAndSafety className="!w-8 !h-8 text-white" />;
      case "character_building":
        return <FitnessCenter className="!w-8 !h-8 text-white" />;
      case "emotional_growth":
        return <EmojiEmotions className="!w-8 !h-8 text-white" />;
      case "science_and_engineering":
        return <Science className="!w-8 !h-8 text-white" />;
      case "environmental_awareness":
        return <Forest className="!w-8 !h-8 text-white" />;
      case "cultural_awareness_and_diversity":
        return <Public className="!w-8 !h-8 text-white" />;
      case "teamwork":
        return <Handshake className="!w-8 !h-8 text-white" />;
      case "self-sufficiency":
        return <Person className="!w-8 !h-8 text-white" />;
      case "cognitive_development":
        return <Lightbulb className="!w-8 !h-8 text-white" />;
      default:
        return <Book className="!w-8 !h-8 text-white" />;
    }
  };

  return (
    <Card className={className}>
      <View className="flex items-center justify-start gap-4 mb-4 w-full pt-4 sm:pt-8 px-4 sm:px-8">
        <Text className="text-2xl sm:text-4xl font-semibold text-gray-800 line-clamp-2">{title}</Text>
      </View>
      <View className="flex flex-col flex-grow overflow-y-auto px-4 sm:px-8 pb-4 sm:pb-8">
        <ListItem
          key="new"
          className="bg-white bg-opacity-30 !border-primary border-dashed border-[1px] text-primary"
          onClick={onClickStorySetHandler("new")}
          leftIcon={<Add className="!w-8 !h-8 text-primary" />}
          leftIconClassName="bg-transparent border-dashed"
          title="Create a new collection"
        />
        {storySets?.map((storySet) => (
          <ListItem
            key={storySet.id}
            onClick={onClickStorySetHandler(storySet.id)}
            leftIcon={getIcon(storySet.icon)}
            title={storySet.name}
          />
        ))}
      </View>
    </Card>
  );
};
