import { useState } from "react";

import { ErrorMessage } from "../../components/ErrorMessage";
import { Form } from "../../components/base/Form";
import { Haptics } from "../../utils/haptics";
import { InputField } from "../../components/base/InputField";
import { OnboardingCard } from "../../components/OnboardingCard";
import { ROUTES } from "../../utils/routes";
import { sessionActions } from "../../redux/reducers/sessionSlice";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useNavigation } from "../../hooks/useNavigation";
import { useRoute } from "../../hooks/useRoute";

type FormData = {
  name?: string;
};

type Route = {
  params: {
    onboardingType: "account" | "contributor";
  };
};

export const OnboardingCreateReaderPage = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const route = useRoute<Route>();
  const user = useAppSelector((state) => state.session.user);
  const [formData, setFormData] = useState<FormData>({});
  const createAccountReaderApiRequest = useApiRequest();

  const onChangeFormData = (newFormData: FormData) => {
    setFormData(newFormData);
  };

  const onClickContinue = async () => {
    Haptics.trigger(Haptics.HapticFeedbackTypes.impactLight);

    const { error } = await createAccountReaderApiRequest.fetch("/v1/accountReaders", {
      method: "POST",
      body: {
        name: formData.name,
        accountId: user?.account?.id,
      },
    });

    if (!error) {
      dispatch(sessionActions.getSession());

      navigation.navigate(ROUTES.ONBOARDING_CREATE_CONTRIBUTOR, { onboardingType: route.params.onboardingType });
    }
  };

  return (
    <OnboardingCard
      title="What's your little one's name?"
      subtitle="In the future, stories will be able to feature your child's name."
      currentStep={2}
      totalSteps={5}
      onClickContinue={onClickContinue}
      continueDisabled={!formData.name}
      continueLoading={createAccountReaderApiRequest.isLoading}
    >
      <Form
        data={formData}
        onChange={onChangeFormData}
        className="mb-4"
      >
        <InputField
          name="name"
          placeholder="Enter first name"
        />
      </Form>
      <ErrorMessage text={createAccountReaderApiRequest.error?.message} />
    </OnboardingCard>
  );
};
