import { AudioStory } from "../../redux/reducers/audioStorySlice";
import { RestList } from "../../components/RestList";
import { useEntity } from "../../hooks/useEntity";
import { AudioStoryPlaylist } from "../../redux/reducers/audioStoryPlaylistSlice";

type AdminAudioStoriesPageProps = {
  className?: string;
};

export const AdminAudioStoryPlaylistsPage = ({ className }: AdminAudioStoriesPageProps) => {
  const { entities: audioStoryEntities } = useEntity<AudioStory>("audioStory");

  const fields = [
    {
      name: "imageUrl",
      label: "Image Url",
      type: "image",
      visible: false,
    },
    {
      name: "title",
      label: "Title",
    },
    {
      name: "tags",
      label: "Tags (comma separated)",
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
    },
    {
      name: "isPublic",
      label: "Public",
      type: "checkbox",
    },
    {
      name: "audioStoryIds",
      label: "Audio Stories",
      type: "multiselect",
      options: Object.values(audioStoryEntities).map((audioStory) => ({
        label: audioStory.title,
        value: audioStory.id,
      })),
      visible: false,
    },
  ];

  return (
    <RestList<AudioStoryPlaylist>
      className={className}
      entityName="audioStoryPlaylist"
      fields={fields}
      sort={(ids, entities) => [...ids].sort((a, b) => entities[a]?.title.localeCompare(entities[b]?.title))}
    />
  );
};
