export const COUNTRY_TO_LANGUAGE_CODES: Record<string, string> = {
  US: "en-us",
  AU: "en-au",
  BR: "pt-br",
  CA: "en-ca",
  CN: "zh-cn",
  DE: "de-de",
  ES: "es-es",
  FR: "fr-fr",
  GB: "en-gb",
  IN: "hi-in",
  IT: "it-it",
  JP: "ja-jp",
  KR: "ko-kr",
  MX: "es-mx",
  PT: "pt-pt",
  RU: "ru-ru",
};

export const LANGUAGE_TO_COUNTRY_CODES: Record<string, string> = Object.keys(COUNTRY_TO_LANGUAGE_CODES).reduce(
  (acc, countryCode) => {
    acc[COUNTRY_TO_LANGUAGE_CODES[countryCode]] = countryCode;

    return acc;
  },
  {} as Record<string, string>,
);
