import { createRestSlice } from "./restSlice";

export type BackgroundTrack = {
  id: string;
  name: string;
  audioUrl: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: null | string;
};

const backgroundTrackSlice = createRestSlice<BackgroundTrack>("backgroundTrack", "/v1/backgroundTracks");

export const backgroundTrackActions = backgroundTrackSlice.actions;
export const backgroundTrackReducer = backgroundTrackSlice.reducer;
