import { useEffect, useMemo, useState } from "react";

import { accountContributorActions } from "../../redux/reducers/accountContributorSlice";
import { Form } from "../../components/base/Form";
import { Haptics } from "../../utils/haptics";
import { InputField } from "../../components/base/InputField";
import { OnboardingCard } from "../../components/OnboardingCard";
import { ROUTES } from "../../utils/routes";
import { sessionActions } from "../../redux/reducers/sessionSlice";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useNavigation } from "../../hooks/useNavigation";
import { useRoute } from "../../hooks/useRoute";
import { accountReaderActions } from "../../redux/reducers/accountReaderSlice";
import { Text } from "../../components/base/Text";
import { SelectField } from "../../components/base/SelectField";

type FormData = {
  name?: string;
  description?: string;
  otherDescription?: string;
};

type Route = {
  params: {
    onboardingType: "account" | "contributor";
    contributorId?: string;
    readerName?: string;
  };
};

export const OnboardingCreateContributorPage = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const route = useRoute<Route>();
  const user = useAppSelector((state) => state.session.user);
  const accountReaderEntities = useAppSelector((state) => state.accountReader.entities);
  const [formData, setFormData] = useState<FormData>({});
  const [isLoading, setIsLoading] = useState(false);

  const readerName = useMemo(() => {
    if (route.params.readerName) {
      return route.params.readerName;
    }

    const accountReaders = Object.values(accountReaderEntities).filter((reader) => reader.accountId === user?.accountId);

    return accountReaders[0]?.name || "listener";
  }, [route, accountReaderEntities]);

  const onChangeFormData = (newFormData: FormData) => {
    setFormData(newFormData);
  };

  const onClickContinue = async () => {
    Haptics.trigger(Haptics.HapticFeedbackTypes.impactLight);

    setIsLoading(true);

    let contributorId = route.params.contributorId;

    if (route.params.onboardingType === "contributor") {
      await dispatch(
        accountContributorActions.updateById({
          id: contributorId,
          name: formData.name,
          description: formData.description,
        }),
      );
    } else {
      const newContributor = await dispatch(
        accountContributorActions.create({
          name: formData.name,
          description: formData.description === "Other" ? formData.otherDescription : formData.description,
          accountId: user?.accountId,
        }),
      ).unwrap();

      contributorId = newContributor.id;

      dispatch(sessionActions.getSession());
    }

    setIsLoading(false);

    navigation.navigate(ROUTES.ONBOARDING_CONTRIBUTOR_PHOTO, {
      contributorId,
      onboardingType: route.params.onboardingType,
    });
  };

  useEffect(() => {
    if (user?.accountId) {
      dispatch(accountReaderActions.getByAccountId(user.accountId));
    }
  }, [user]);

  return (
    <OnboardingCard
      title={
        <Text className="text-title">
          What does <Text className="text-secondary">{readerName}</Text> call you?
        </Text>
      }
      subtitle="Your name will show up in the app!"
      currentStep={route.params.onboardingType === "contributor" ? 1 : 3}
      totalSteps={route.params.onboardingType === "contributor" ? 3 : 5}
      onClickContinue={onClickContinue}
      continueDisabled={!formData.name || !formData.description}
      continueLoading={isLoading}
    >
      <Form
        data={formData}
        onChange={onChangeFormData}
        className="mb-4"
      >
        <InputField
          name="name"
          placeholder="Nickname"
          className="mb-4"
        />
        <SelectField
          name="description"
          options={[
            { label: "Mother", value: "Mother" },
            { label: "Father", value: "Father" },
            { label: "Grandmother", value: "Grandmother" },
            { label: "Grandfather", value: "Grandfather" },
            { label: "Aunt", value: "Aunt" },
            { label: "Uncle", value: "Uncle" },
            { label: "Other", value: "Other" },
          ]}
          placeholder="Relationship to the listener"
          className="mb-4"
        />
        {formData.description === "Other" && (
          <InputField
            name="otherDescription"
            placeholder="Other relationship"
          />
        )}
      </Form>
    </OnboardingCard>
  );
};
