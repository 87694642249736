import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { makeRequest } from "../../utils/request";

type BeginChatPayload = {
  model?: string;
  characterId?: string;
  refresh?: boolean;
};

type SendMessagePayload = {
  model?: string;
  characterId?: string;
  message: string;
};

type Message = {
  id: string;
  content: string;
  role: string;
  model?: string;
  createdAt?: string;
};

type Chat = {
  id: string;
  userId: string;
  messages: Message[];
};

type Metadata = Record<string, Record<string, string>>;

export type ChatState = {
  data: Chat | null;
  metadata: Metadata | null;
  isLoading: boolean;
};

const initialState: ChatState = {
  data: null,
  metadata: null,
  isLoading: false,
};

const beginChat = createAsyncThunk("chat/beginChat", async (payload?: BeginChatPayload) => {
  if (payload?.refresh) {
    const response = await makeRequest<{ data: Chat }>("/v1/chats/begin", {
      method: "POST",
      body: {
        model: payload?.model,
        characterId: payload?.characterId,
      },
    });

    return response.data;
  } else {
    try {
      const response = await makeRequest<{ data: Chat }>("/v1/chats/load", {
        method: "POST",
      });

      return response.data;
    } catch (error) {
      const response = await makeRequest<{ data: Chat }>("/v1/chats/begin", {
        method: "POST",
        body: {
          model: payload?.model,
          characterId: payload?.characterId,
        },
      });

      return response.data;
    }
  }
});

const sendMessage = createAsyncThunk("chat/sendMessage", async (payload: SendMessagePayload) => {
  const response = await makeRequest<{ data: Chat; metadata: Metadata }>("/v1/chats/send", {
    method: "POST",
    body: payload,
  });

  return response;
});

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(beginChat.pending, (state) => {
      state.isLoading = true;
      state.data = null;
      state.metadata = null;
    });

    builder.addCase(beginChat.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(sendMessage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.metadata = action.payload.metadata;
    });

    builder.addCase(sendMessage.pending, (state, action) => {
      state.isLoading = false;
      state.data = {
        ...state.data,
        messages: [
          ...(state.data?.messages || []),
          {
            id: Math.random().toString(),
            content: action.meta.arg.message,
            role: "user",
          },
        ],
      } as Chat;
    });

    builder.addMatcher(
      (action) => action.type.startsWith("chat/") && action.type.endsWith("/pending"),
      (state) => {
        state.isLoading = true;
      },
    );

    builder.addMatcher(
      (action) => action.type.startsWith("chat/") && action.type.endsWith("/rejected"),
      (state) => {
        state.isLoading = false;
      },
    );
  },
});

export const chatReducer = chatSlice.reducer;

export const chatActions = {
  ...chatSlice.actions,
  beginChat,
  sendMessage,
};
