import { AddCircleOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import { Button } from "../components/Button";
import { Image } from "../components/Image";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { Text } from "../components/Text";
import { useAppDispatch, useAppSelector } from "../redux";
import { View } from "../components/View";
import { useCallback, useState } from "react";
import { ReaderInfoModal } from "../components/ReaderInfoModal";
import { AccountReader } from "../redux/reducers/accountReaderSlice";
import { Card } from "../components/Card";

type HomePageProps = {
  className?: string;
};

export const HomePage = ({ className }: HomePageProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const readers = useAppSelector((state) => state.session.user?.account?.readers);
  const [isReaderModalOpen, setIsReaderModalOpen] = useState(false);

  const onClickReaderHandler = (readerId: string) => () => {
    dispatch(sessionActions.setSelectedReaderId(readerId));
    navigate("/library");
  };

  const onClickAddReader = useCallback(() => {
    setIsReaderModalOpen(true);
  }, []);

  const onCloseReaderModal = useCallback(() => {
    setIsReaderModalOpen(false);
  }, []);

  return (
    <View className={clsx("relative flex flex-col items-center justify-center w-full h-full", className)}>
      <Image
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="https://api.primstories.com/v1/files/images/aaad7a1c-03d2-4bc9-afd1-bc453347b817.webp"
      />
      <View className="flex items-center justify-center ribbon bg-secondary mb-8">
        {!readers || !readers.length ? (
          <Text className="text-4xl font-bold m-4">Please add a reader to your account</Text>
        ) : (
          <Text className="text-4xl font-bold m-4">Select a Reader</Text>
        )}
      </View>
      <View className="w-full flex flex-wrap items-center justify-center gap-4">
        {readers?.map((reader) => (
          <Button
            key={reader.id}
            type="basic"
            className="!w-auto"
            onClick={onClickReaderHandler(reader.id)}
          >
            <Card className="px-6 py-4 sm:px-8 sm:py-6">
              <View className="w-24 h-24 sm:w-28 sm:h-28 flex items-center justify-center bg-gradient-to-b from-secondary to-primary rounded-full p-1 mx-2 mb-4">
                <Image
                  src={reader.imageUrl}
                  alt={reader.name || "Reader"}
                  className="w-full h-full rounded-full object-cover border-4 border-white"
                />
              </View>
              <Text className="text-2xl font-semibold">{reader.name || "Unnamed Reader"}</Text>
            </Card>
          </Button>
        ))}
        {(!readers || readers.length === 0) && (
          <Button
            type="basic"
            className="!w-1/3 sm:!w-48 h-52 flex flex-col items-center justify-center p-4 rounded-xl bg-white shadow-avatar backdrop-blur-md bg-opacity-80"
            onClick={onClickAddReader}
          >
            <AddCircleOutline className="mx-4 mb-2 !w-24 !h-24 text-primary" />
            <Text className="text-2xl font-semibold">Add Reader</Text>
          </Button>
        )}
      </View>
      <ReaderInfoModal
        isOpen={isReaderModalOpen}
        onClose={onCloseReaderModal}
      />
    </View>
  );
};
