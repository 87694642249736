import { ArrowForwardRounded } from "@mui/icons-material";

import { View } from "./View";
import { Button } from "./Button";
import { Text } from "./Text";
import { Character } from "../redux/reducers/characterSlice";
import { Avatar } from "./Avatar";
import { WrapContainer } from "./WrapContainer";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { Card } from "./Card";

type StoryTitleCardProps = {
  className?: string;
  characters?: Character[];
  onClick?: () => void;
  title?: string;
  showReadButton?: boolean;
};

export const StoryTitleCard = ({ className, characters, title, onClick = () => {}, showReadButton = false }: StoryTitleCardProps) => {
  const { windowWidth } = useWindowDimensions();
  const titleParts = title?.split(":") || [];

  return (
    <Card className={className}>
      <View className="absolute top-0 right-1/2 translate-x-1/2 -translate-y-1/4 !w-64 flex items-center justify-center ribbon bg-secondary">
        <Text className="text-center">PERFORMED BY</Text>
      </View>
      <WrapContainer
        className="w-full overflow-hidden px-8 pt-16 pb-4"
        maxRows={2}
        itemSize={windowWidth > 768 ? 144 : 88}
        items={
          characters?.map(
            (character, index) =>
              character && (
                <View
                  className="flex flex-col items-center justify-around mb-2"
                  key={character.id}
                >
                  <View className="flex items-center justify-center bg-gradient-to-b from-secondary to-primary rounded-full p-1 mx-2 my-1">
                    <Avatar
                      name={character.name}
                      image={character.image}
                      characterId={character.id}
                      className="!w-20 !h-20 md:!w-24 md:!h-24 !shadow-none"
                      role={index === 0 ? "main" : ""}
                    />
                  </View>
                  <View className="w-full hidden md:flex items-center justify-center text-lg font-semibold mt-2 p-2 rounded-full shadow-lg transition-all duration-700 bg-white">
                    <Text className="truncate">{character.name}</Text>
                  </View>
                </View>
              ),
          ) || []
        }
      />
      <View className="flex flex-col items-center justify-center mb-12">
        <Text className="text-4xl mb-2 text-center">{titleParts[1] || titleParts[0]}</Text>
        {titleParts[1] && <Text className="text-2xl mb-2 text-center text-gray-400">{titleParts[0]}</Text>}
      </View>
      {showReadButton && (
        <Button
          className="!absolute bottom-0 right-1/2 translate-x-1/2 translate-y-1/2 !w-64 pl-6"
          onClick={onClick}
        >
          Let's Read
          <ArrowForwardRounded className="ml-2" />
        </Button>
      )}
    </Card>
  );
};
