import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";

import { createRestSlice, ItemState } from "./restSlice";
import { makeRequest } from "../../utils/request";

export type AccountReader = {
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  gender: string;
  level: string;
  accountId: string;
  createdAt: string;
  updatedAt: string;
};

const getByAccountId = createAsyncThunk("accountReader/getByAccountId", async (payload: string) => {
  const { data } = await makeRequest<{ data: AccountReader[] }>(`/v1/accounts/${payload}/readers`);

  return data;
});

const accountReaderSlice = createRestSlice<AccountReader>("accountReader", "/v1/accountReaders", {
  extraReducers: (builder: ActionReducerMapBuilder<ItemState<AccountReader>>) => {
    builder.addCase(getByAccountId.fulfilled, (state, action) => {
      const ids = action.payload.map((item) => item.id);
      const entities = action.payload.reduce(
        (acc, item) => {
          acc[item.id] = item;

          return acc;
        },
        {} as Record<string, AccountReader>,
      );

      state.isLoading = false;
      state.ids = [...state.ids, ...ids];
      state.entities = { ...state.entities, ...entities };
    });
  },
});

export const accountReaderReducer = accountReaderSlice.reducer;

export const accountReaderActions = {
  ...accountReaderSlice.actions,
  getByAccountId,
};
