import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../redux";
import { Modal } from "./Modal";
import { View } from "./base/View";
import { Text } from "./base/Text";
import { userActions } from "../redux/reducers/userSlice";
import { Button } from "./Button";
import { LinkOutlined } from "@mui/icons-material";
import { useNavigation } from "../hooks/useNavigation";

type AccountUserModalProps = {
  accountId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const AccountUserModal = ({ accountId, isOpen, onClose }: AccountUserModalProps) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const isLoading = useAppSelector((state) => state.accountReader.isLoading);
  const accountUsers = useAppSelector((state) => Object.values(state.user.entities).filter((user) => user.accountId === accountId));

  const onClickUserIdHandler = (id: string) => () => {
    navigation.navigate("/admin/users", { id });
  };

  useEffect(() => {
    if (accountId) {
      dispatch(userActions.getByAccountId(accountId));
    }
  }, [accountId]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Account Users"
      className="min-w-[80vw]"
    >
      <View className="flex flex-col w-full">
        <View
          className="grid w-full pb-2 font-semibold border-b border-gray-300"
          style={{ gridTemplateColumns: "1fr 1fr 64px" }}
        >
          <View>Name</View>
          <View>Email</View>
          <View></View>
        </View>
        {isLoading ? (
          <View className="flex flex-row items-center justify-center w-full h-64">Loading...</View>
        ) : (
          <View className="w-full overflow-y-auto">
            {accountUsers.map((user) => (
              <View
                className="grid items-center border-b border-gray-300 p-2"
                style={{ gridTemplateColumns: "1fr 1fr 64px" }}
              >
                <Text>{user.name}</Text>
                <Text>{user.email}</Text>
                <Button
                  type="basic"
                  onClick={onClickUserIdHandler(user.id)}
                  className="flex justify-start"
                >
                  <LinkOutlined className="!h-8! w-8 text-gray-500" />
                </Button>
              </View>
            ))}
            {accountUsers.length === 0 && <View className="flex flex-row items-center justify-center w-full h-64">No users found.</View>}
          </View>
        )}
      </View>
    </Modal>
  );
};
