import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { makeRequest } from "../../utils/request";
import { createRestSlice, ItemState } from "./restSlice";

export type AccountContributor = {
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  audioSampleUrl: string;
  accountId: string;
  voiceId: string;
  createdAt: string;
  updatedAt: string;
};

const getByAccountId = createAsyncThunk("accountContributor/getByAccountId", async (payload: string) => {
  const { data } = await makeRequest<{ data: AccountContributor[] }>(`/v1/accounts/${payload}/contributors`);

  return data;
});

const uploadVoice = createAsyncThunk("accountContributor/uploadVoice", async (payload: { id: string; file: File }) => {
  const { data } = await makeRequest<{ data: AccountContributor }>(`/v1/accountContributors/${payload.id}/uploadVoice`, {
    method: "POST",
    file: payload.file,
  });

  return data;
});

const accountContributorSlice = createRestSlice<AccountContributor>("accountContributor", "/v1/accountContributors", {
  extraReducers: (builder: ActionReducerMapBuilder<ItemState<AccountContributor>>) => {
    builder.addCase(getByAccountId.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getByAccountId.fulfilled, (state, action) => {
      const ids = action.payload.map((item) => item.id);
      const entities = action.payload.reduce(
        (acc, item) => {
          acc[item.id] = item;

          return acc;
        },
        {} as Record<string, AccountContributor>,
      );

      state.isLoading = false;
      state.ids = [...state.ids, ...ids];
      state.entities = { ...state.entities, ...entities };
    });

    builder.addCase(uploadVoice.pending, (state) => {
      state.isLoadingCreate = true;
    });

    builder.addCase(uploadVoice.fulfilled, (state, action) => {
      state.isLoadingCreate = false;
      state.entities = { ...state.entities, [action.payload.id]: action.payload };
    });
  },
});

export const accountContributorReducer = accountContributorSlice.reducer;

export const accountContributorActions = {
  ...accountContributorSlice.actions,
  getByAccountId,
  uploadVoice,
};
