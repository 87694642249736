import { createRestSlice } from "./restSlice";

export type Memory = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

const memorySlice = createRestSlice<Memory>("memory", "/v1/memories");

export const memoryActions = memorySlice.actions;
export const memoryReducer = memorySlice.reducer;
