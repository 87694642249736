import { FunctionComponent, memo } from "react";

type MemoizeOptions = {
  debugName?: string;
  disableRerender?: string[];
};

export const memoize = <T>(component: FunctionComponent<T>, options: MemoizeOptions = {}) => {
  return memo(component, (prevProps, nextProps) => {
    const keys = Object.keys(nextProps);

    for (const key of keys) {
      if (prevProps[key as keyof T] !== nextProps[key as keyof T]) {
        if (options.disableRerender?.includes(key)) {
          continue;
        }

        if (options.debugName) {
          console.log(`${options.debugName} render`, key, prevProps[key as keyof T], nextProps[key as keyof T]);
        }

        return false;
      }
    }

    return true;
  });
};
