import { RestList } from "../../components/RestList";
import { Theme } from "../../redux/reducers/themeSlice";

type AdminThemesPageProps = {
  className?: string;
};

export const AdminThemesPage = ({ className }: AdminThemesPageProps) => {
  const fields = [
    {
      name: "name",
      label: "Name",
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
    },
  ];

  return (
    <RestList<Theme>
      className={className}
      entityName="theme"
      fields={fields}
      sort={(ids, entities) => [...ids].sort((a, b) => entities[a]?.name.localeCompare(entities[b]?.name))}
    />
  );
};
