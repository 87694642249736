import clsx from "clsx";
import React, { useEffect, useState } from "react";

type FormProps = {
  className?: string;
  onChange?: (formData: Record<string, unknown>) => void;
  children: React.ReactNode;
  data: Record<string, unknown>;
};

export const Form = ({ className, onChange, children, data }: FormProps) => {
  const [formData, setFormData] = useState<Record<string, unknown>>(data);

  const onChangeHandler = (key: string) => (value: string) => {
    const newFormData = {
      ...formData,
      [key]: value,
    };

    setFormData(newFormData);

    if (onChange) {
      onChange(newFormData);
    }
  };

  useEffect(() => {
    setFormData(data);
  }, [data]);

  return (
    <div className={clsx("w-full flex flex-col", className)}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement<HTMLInputElement>(child)) {
          return React.cloneElement(child, {
            // @ts-ignore
            onChange: onChangeHandler(child.props.name),
            value: formData[child.props.name] as string,
          });
        }

        return child;
      })}
    </div>
  );
};
