import { AudioPlayer } from "../../components/AudioPlayer";
import { RestList, RestListComponentProps } from "../../components/RestList";
import { BackgroundTrack } from "../../redux/reducers/backgroundTrackSlice";

type AdminBackgroundTracksPageProps = {
  className?: string;
};

export const AdminBackgroundTracksPage = ({ className }: AdminBackgroundTracksPageProps) => {
  const fields = [
    {
      name: "name",
      label: "Name",
    },
    {
      name: "audioUrl",
      label: "Track",
      type: "audio",
      visible: false,
    },
    {
      name: "audioPlayer",
      label: "Audio Player",
      component: ({ formData }: RestListComponentProps<BackgroundTrack>) =>
        formData?.audioUrl ? (
          <AudioPlayer
            className="flex-grow mb-4 px-0"
            audioUrl={formData?.audioUrl}
          />
        ) : null,
      visible: false,
    },
  ];

  return (
    <RestList<BackgroundTrack>
      className={className}
      entityName="backgroundTrack"
      fields={fields}
    />
  );
};
