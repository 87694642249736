import { CircularProgress } from "@mui/material";

type SpinnerProps = {
  className?: string;
  color?: string;
  size?: number;
};

export const Spinner = ({ color = "#000000", size = 24, className }: SpinnerProps) => {
  return (
    <CircularProgress
      className={className}
      size={size}
    />
  );
};
