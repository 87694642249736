import clsx from "clsx";

import { FieldLabel } from "../FieldLabel";
import { Style } from "../../utils/types";
import { memoize } from "../../utils/performance";
import { View } from "./View";

type SelectFieldProps = {
  name?: string;
  options: Array<{ label: string; value: string }>;
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  label?: string;
  placeholder?: string;
  style?: Style;
};

export const SelectField = memoize(({ className, label, onChange = () => {}, options = [], placeholder, style, value }: SelectFieldProps) => {
  const onSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  return (
    <FieldLabel
      label={label}
      className={label ? "max-h-[84px] overflow-hidden" : "max-h-[64px] overflow-hidden"}
    >
      <View className="relative">
        <select
          value={value || ""}
          onChange={onSelect}
          style={{
            ...style,
            WebkitAppearance: "none",
            MozAppearance: "none",
            appearance: "none",
          }}
          className={clsx(
            "w-full rounded-md pt-2 pb-2 px-4 text-xl outline-secondary border-[1px] border-gray-300 bg-white",
            value ? "text-secondary" : "text-[#C8C8C8] font-light",
            className,
          )}
        >
          {placeholder && (
            <option
              value=""
              disabled
            >
              {placeholder}
            </option>
          )}
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
      </View>
    </FieldLabel>
  );
});
