export const ROUTES: Record<string, string> = {
  AUDIO_LIBRARY: "https://apps.apple.com/us/app/prim-stories/id6736834775",
  FORGOT_PASSWORD: "/forgot-password",
  ONBOARDING_ACCOUNT_TYPE: "/onboarding",
  ONBOARDING_COMPLETE: "/onboarding/complete",
  ONBOARDING_CONTRIBUTOR_CODE: "/onboarding/contributor-code",
  ONBOARDING_CONTRIBUTOR_PHOTO: "/onboarding/contributor-photo",
  ONBOARDING_CONTRIBUTOR_VOICE: "/onboarding/contributor-voice",
  ONBOARDING_CREATE_ACCOUNT: "/onboarding/create-account",
  ONBOARDING_CREATE_CONTRIBUTOR: "/onboarding/create-contributor",
  ONBOARDING_CREATE_READER: "/onboarding/create-reader",
  ONBOARDING_READER_DESCRIPTION: "/onboarding/reader-description",
  ONBOARDING_READER_GENDER: "/onboarding/reader-gender",
  ONBOARDING_READER_LEVEL: "/onboarding/reader-level",
  SIGN_IN: "/signin",
};
