import { createRestSlice } from "./restSlice";

export type Subscription = {
  id: string;
  name: string;
  price: number;
  discountPrice: number;
  voiceCount: number;
  description: string;
  imageUrl: string;
  appleProductId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
};

const subscriptionSlice = createRestSlice<Subscription>("subscription", "/v1/subscriptions");

export const subscriptionActions = subscriptionSlice.actions;
export const subscriptionReducer = subscriptionSlice.reducer;
