import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { clsx } from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useMeasure } from "react-use";

import { BookPageText } from "./BookPageText";
import { Button } from "./Button";
import { StoryAnalysisWord } from "../redux/reducers/storySlice";
import { Text } from "./Text";
import { View } from "./View";
import { splitParagraphs, splitSentences } from "../utils/strings";

type BookPageProps = {
  characterColors?: Record<string, string>;
  className?: string;
  currentWordIndex?: number;
  currentWordIndexOffset?: number;
  isTransitioning?: boolean;
  onClickNextPage?: () => void;
  onClickPrevPage?: () => void;
  onClickPlayWord?: (index: number) => void;
  onClickDefineWord?: (index: number) => void;
  onLayout?: (height: number, width: number) => void;
  pageNumber?: number;
  pageNumberTotal?: number;
  prevParagraphIndex?: number;
  style?: React.CSSProperties;
  words?: StoryAnalysisWord[];
};

export const BookPage = ({
  characterColors = {},
  className,
  currentWordIndex = 0,
  currentWordIndexOffset = 0,
  isTransitioning,
  onClickNextPage,
  onClickPrevPage,
  onClickPlayWord = () => {},
  onClickDefineWord = () => {},
  onLayout = () => {},
  pageNumber = 0,
  pageNumberTotal = 0,
  prevParagraphIndex,
  style,
  words = [],
}: BookPageProps) => {
  const [containerRef, { width: containerWidth, height: containerHeight }] = useMeasure<HTMLDivElement>();
  const paragraphs = useMemo(() => splitParagraphs(words), [words]);

  const wordToSentenceIndex = useMemo(() => {
    const wordsText = words?.map((word) => word.text).join(" ") || "";
    const sentences = splitSentences(wordsText);

    return sentences.reduce((acc, sentence, index) => {
      const words = sentence.split(" ");

      const indexes = words.map(() => {
        return index;
      });

      return [...acc, ...indexes];
    }, [] as number[]);
  }, [words]);

  const onClickPlayWordHandler = (index: number) => () => {
    onClickPlayWord(index);
  };

  const onClickDefineWordHandler = (index: number) => () => {
    onClickDefineWord(index);
  };

  useEffect(() => {
    onLayout(containerHeight, containerWidth);
  }, [containerHeight, containerWidth]);

  return (
    <View
      className={clsx("flex flex-col w-full h-full p-6 md:p-8 bg-white rounded-xl shadow-page", className)}
      style={style}
      ref={containerRef}
    >
      {pageNumber < pageNumberTotal ? (
        <>
          <View className="flex flex-wrap flex-grow content-start items-start justify-start text-3xl text-gray-500 text-opacity-80 indent-8">
            {paragraphs?.map((currentWords, index) => {
              return (
                <Text
                  key={index}
                  className={prevParagraphIndex !== currentWords[0].paragraph ? "indent-8" : "indent-0"}
                >
                  {currentWords.map((word, wordIndex) => {
                    const indexInParagraphs = paragraphs.slice(0, index).reduce((acc, paragraph) => acc + paragraph.length, 0) + wordIndex;

                    return (
                      <BookPageText
                        key={word.text + indexInParagraphs}
                        isActiveSentence={wordToSentenceIndex[currentWordIndex - currentWordIndexOffset] === wordToSentenceIndex[indexInParagraphs]}
                        isActiveWord={currentWordIndex - currentWordIndexOffset === indexInParagraphs}
                        onClickPlay={onClickPlayWordHandler(currentWordIndexOffset + indexInParagraphs)}
                        onClickDefine={onClickDefineWordHandler(currentWordIndexOffset + indexInParagraphs)}
                        text={word.text}
                        color={characterColors[word.characterId]}
                      />
                    );
                  })}
                </Text>
              );
            })}
          </View>
          <View className="flex w-full items-center justify-between">
            {onClickPrevPage && pageNumber - 1 >= 0 ? (
              <Button
                className="!rounded-full !w-12 !h-12 !opacity-100"
                onClick={onClickPrevPage}
                isDisabled={isTransitioning}
              >
                <ArrowBack />
              </Button>
            ) : (
              <View className="!w-12 !h-12" />
            )}
            <Text className="text-gray-400 font-light select-nonec">
              Page {pageNumber + 1} of {pageNumberTotal}
            </Text>
            {onClickNextPage && pageNumber + 1 < pageNumberTotal ? (
              <Button
                className="!rounded-full !w-12 !h-12 !opacity-100"
                onClick={onClickNextPage}
                isDisabled={isTransitioning}
              >
                <ArrowForward />
              </Button>
            ) : (
              <View className="!w-12 !h-12" />
            )}
          </View>
        </>
      ) : (
        <Text className="text-6xl text-gray-400 font-light text-center m-auto select-none">The End</Text>
      )}
    </View>
  );
};
