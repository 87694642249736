import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { View } from "./View";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import clsx from "clsx";

type WrapContainerProps = {
  className?: string;
  itemSize: number;
  items: ReactNode[];
  maxRows?: number;
};

export const WrapContainer = ({ className, itemSize, items, maxRows = 2 }: WrapContainerProps) => {
  const { windowWidth } = useWindowDimensions();
  const contaienrRef = useRef<HTMLDivElement>(null);
  const [isMounted, setIsMounted] = useState(false);

  const itemRows = useMemo(() => {
    const containerWidth = contaienrRef.current?.offsetWidth || 0;

    let itemsPerRow = items?.length / maxRows;

    if (containerWidth > 0) {
      itemsPerRow = Math.floor(containerWidth / (itemSize + 32));

      if (itemsPerRow === 3 && items?.length === 4) {
        itemsPerRow = 2;
      }

      if (itemsPerRow === 4 && (items?.length === 5 || items?.length === 6)) {
        itemsPerRow = 3;
      }
    } else {
      itemsPerRow = items?.length;
    }

    return items?.reduce((acc, item, index) => {
      const rowIndex = Math.floor(index / itemsPerRow);
      acc[rowIndex] = acc[rowIndex] || [];
      acc[rowIndex].push(item);

      return acc;
    }, [] as unknown[][]);
  }, [windowWidth, items, isMounted]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <View
      ref={contaienrRef}
      className={className}
    >
      {itemRows?.slice(0, maxRows).map((itemRow) => (
        <View
          key={uuidv4()}
          className={clsx("flex items-start justify-center w-full mb-4 gap-12", isMounted ? "opacity-100" : "opacity-0")}
        >
          {itemRow?.map((item) => (
            <View
              key={uuidv4()}
              style={{ width: itemSize }}
            >
              {item as React.ReactElement}
            </View>
          ))}
        </View>
      ))}
    </View>
  );
};
