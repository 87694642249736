import { useEffect, useRef } from "react";
import { getAudioContext } from "../utils/AudioContext";

export const useAudioPlayer = () => {
  const audioContextRef = useRef<AudioContext>();
  const audioGainNodeRef = useRef<GainNode>();
  const audioSourceRef = useRef<AudioBufferSourceNode>();

  const mute = () => {
    if (audioGainNodeRef.current) {
      audioGainNodeRef.current.gain.value = 0;
    }
  };

  const unmute = () => {
    if (audioGainNodeRef.current) {
      audioGainNodeRef.current.gain.value = 1;
    }
  };

  const pause = () => {
    audioContextRef.current?.suspend();
  };

  const suspend = () => {
    if (audioSourceRef.current) {
      try {
        audioSourceRef.current.onended = null;
        audioSourceRef.current.stop();
      } catch (error) {
        // do nothing
      }
    }

    audioContextRef.current?.suspend();
  };

  const play = (audioUrl: string, options?: { offset: number; onStart: () => void }): Promise<void> => {
    if (audioContextRef.current?.state === "suspended") {
      audioContextRef.current?.resume();
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      audioContextRef.current = getAudioContext();
      audioSourceRef.current = audioContextRef.current?.createBufferSource();
      audioGainNodeRef.current = audioContextRef.current?.createGain();

      audioContextRef.current.onstatechange = () => {
        if (audioContextRef.current?.state === "running") {
          options?.onStart();
        }
      };

      fetch(audioUrl)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => audioContextRef.current?.decodeAudioData(arrayBuffer))
        .then((audioBuffer) => {
          if (audioContextRef.current && audioSourceRef.current) {
            audioSourceRef.current.buffer = audioBuffer || null;

            if (audioGainNodeRef.current) {
              audioSourceRef.current.connect(audioGainNodeRef.current);
              audioGainNodeRef.current.connect(audioContextRef.current?.destination);
            }

            audioSourceRef.current.onended = () => {
              suspend();
              resolve();
            };

            audioSourceRef.current?.start();
          }
        });
    });
  };

  useEffect(() => {
    return () => {
      suspend();
    };
  }, []);

  return {
    mute,
    unmute,
    suspend,
    pause,
    play,
  };
};
