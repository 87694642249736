import { forwardRef } from "react";

type ViewProps = {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

export const View = forwardRef<HTMLDivElement, ViewProps>(({ className, children, style }, ref) => {
  return (
    <div
      ref={ref}
      className={className}
      style={style}
    >
      {children}
    </div>
  );
});
