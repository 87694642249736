import { CircularProgress } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import clsx from "clsx";

import { Button } from "./Button";
import { Text } from "./Text";
import { View } from "./View";
import { Character } from "../redux/reducers/characterSlice";
import { Avatar } from "./Avatar";

type QuestionBarProps = {
  narrator?: Character;
  className?: string;
  audioUrl?: string;
  word?: string;
  definition?: string;
  isLoading?: boolean;
  isOpen?: boolean;
  onClickClose?: () => void;
};

export const QuestionBar = ({ narrator, className, audioUrl, word, definition, isLoading, isOpen, onClickClose = () => {} }: QuestionBarProps) => {
  return (
    <View
      className={clsx(
        "absolute bottom-0 left-1/2 -translate-x-1/2 z-10 transition-all duration-700 w-[100vw] md:w-[50vw] md:max-w-[80vw]",
        "flex flex-col items-center justify-center gap-2 px-4 py-2 md:px-8 md:py-4 md:rounded-t-md bg-white bg-opacity-80 backdrop-blur-md shadow-avatar-bar",
        isOpen ? "tranlate-y-0" : "translate-y-[500px]",
        className,
      )}
    >
      <Button
        type="basic"
        className="!absolute top-4 right-4 !w-8 !h-8 !p-0"
        onClick={onClickClose}
      >
        <CloseRounded className="!w-8 !h-8" />
      </Button>
      <View className="w-full flex items-center justify-start">
        {narrator && (
          <View className="flex items-center justify-center bg-gradient-to-b from-secondary to-primary rounded-full p-1 my-1 mr-4">
            <Avatar
              name={narrator.name}
              image={narrator.image}
              characterId={narrator.id}
              className="!w-16 !h-16 !shadow-none"
            />
          </View>
        )}
        <Text className="text-4xl font-bold">Let's learn the word</Text>
        <Text className="text-4xl font-bold text-secondary">&nbsp;"{word}"</Text>
      </View>
      {isLoading ? (
        <CircularProgress className="!text-primary mb-4" />
      ) : (
        <View className="flex flex-col justify-start px-2">
          {definition?.split("\n").map((definition, index) => (
            <Text
              key={index}
              className="text-lg mb-4"
            >
              {definition}
            </Text>
          ))}
          {isOpen && (
            <audio
              src={audioUrl}
              autoPlay
            />
          )}
        </View>
      )}
    </View>
  );
};
