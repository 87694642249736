import { RestList } from "../../components/RestList";
import { Feedback } from "../../redux/reducers/feedbackSlice";

type AdminFeedbackPageProps = {
  className?: string;
};

export const AdminFeedbackPage = ({ className }: AdminFeedbackPageProps) => {
  const fields = [
    {
      name: "email",
      label: "Email",
      readOnly: true,
    },
    {
      name: "content",
      label: "Content",
      readOnly: true,
    },
  ];

  return (
    <RestList<Feedback>
      className={className}
      entityName="feedback"
      fields={fields}
    />
  );
};
