import { useState } from "react";

import { accountContributorActions } from "../../redux/reducers/accountContributorSlice";
import { AvatarField } from "../../components/AvatarField";
import { Button } from "../../components/base/Button";
import { Haptics } from "../../utils/haptics";
import { OnboardingCard } from "../../components/OnboardingCard";
import { ROUTES } from "../../utils/routes";
import { sessionActions } from "../../redux/reducers/sessionSlice";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useNavigation } from "../../hooks/useNavigation";
import { userActions } from "../../redux/reducers/userSlice";
import { useRoute } from "../../hooks/useRoute";

type Route = {
  params: {
    onboardingType: "contributor" | "account";
    contributorId?: string;
  };
};

export const OnboardingContributorPhotoPage = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const route = useRoute<Route>();
  const user = useAppSelector((state) => state.session.user);
  const contributor = useAppSelector((state) => state.session?.user?.account?.contributors?.[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [photo, setPhoto] = useState<string>();

  const onChangePhoto = (value: string) => {
    setPhoto(value);
  };

  const onClickContinue = async () => {
    Haptics.trigger(Haptics.HapticFeedbackTypes.impactLight);

    setIsLoading(true);

    if (route.params.onboardingType === "contributor") {
      await dispatch(
        accountContributorActions.updateById({
          id: route.params.contributorId,
          imageUrl: photo,
        }),
      );
    } else {
      await dispatch(
        userActions.updateById({
          id: user?.id,
          image: photo,
        }),
      );

      await dispatch(
        accountContributorActions.updateById({
          id: contributor?.id,
          imageUrl: photo,
        }),
      );

      await dispatch(sessionActions.getSession());
    }

    setIsLoading(false);

    navigation.navigate(ROUTES.ONBOARDING_CONTRIBUTOR_VOICE, {
      contributorId: route.params.contributorId,
      onboardingType: route.params.onboardingType,
    });
  };

  const onClickSkip = () => {
    navigation.navigate(ROUTES.ONBOARDING_CONTRIBUTOR_VOICE, {
      contributorId: route.params.contributorId,
      onboardingType: route.params.onboardingType,
    });
  };

  return (
    <OnboardingCard
      title="Add a photo to personalize the experience. You're a key character in this story! 📸✨"
      currentStep={route.params.onboardingType === "contributor" ? 2 : 4}
      totalSteps={route.params.onboardingType === "contributor" ? 3 : 5}
      onClickContinue={onClickContinue}
      continueDisabled={!photo}
      continueLoading={isLoading}
    >
      <AvatarField
        className="mb-4"
        onChange={onChangePhoto}
        value={photo}
      />
      <Button
        color="white"
        type="link"
        onClick={onClickSkip}
        className="w-full mb-4"
      >
        Skip the photo for now
      </Button>
    </OnboardingCard>
  );
};
