import { RestList } from "../../components/RestList";
import { useEntity } from "../../hooks/useEntity";
import { StorySet } from "../../redux/reducers/storySetSlice";
import { Story } from "../../redux/reducers/storySlice";
import { World } from "../../redux/reducers/worldSlice";

type AdminStorySetsPageProps = {
  className?: string;
};

export const AdminStorySetsPage = ({ className }: AdminStorySetsPageProps) => {
  const { entities: storyEntities } = useEntity<Story>("story");
  const { entities: worldEntities } = useEntity<World>("world");

  const fields = [
    {
      name: "name",
      label: "Name",
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
    },
    {
      name: "icon",
      label: "Icon",
      visible: false,
    },
    {
      name: "worldId",
      label: "World",
      type: "dropdown",
      options: Object.values(worldEntities).map((world) => ({
        label: world.name,
        value: world.id,
      })),
    },
    {
      name: "storyIds",
      label: "Stories",
      type: "multiselect",
      options: Object.values(storyEntities).map((story) => ({
        label: story.title,
        value: story.id,
      })),
      visible: false,
    },
  ];

  return (
    <RestList<StorySet>
      className={className}
      entityName="storySet"
      fields={fields}
      sort={(ids, entities) => [...ids].sort((a, b) => entities[a]?.name.localeCompare(entities[b]?.name))}
    />
  );
};
