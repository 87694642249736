import { createRestSlice } from "./restSlice";
import { Story } from "./storySlice";

export type StorySet = {
  id: string;
  name: string;
  description: string;
  storyTemplateSetId: string;
  storyIds: string[];
  worldId: string;
  icon: string;
};

const storySetSlice = createRestSlice<StorySet>("storySet", "/v1/storySets");

export const storySetActions = storySetSlice.actions;
export const storySetReducer = storySetSlice.reducer;
