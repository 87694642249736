import { useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

import { accountActions, accountReducer } from "./reducers/accountSlice";
import { accountContributorActions, accountContributorReducer } from "./reducers/accountContributorSlice";
import { accountReaderActions, accountReaderReducer } from "./reducers/accountReaderSlice";
import { audioStoryActions, audioStoryReducer } from "./reducers/audioStorySlice";
import { audioStoryPlaylistActions, audioStoryPlaylistReducer } from "./reducers/audioStoryPlaylistSlice";
import { backgroundTrackActions, backgroundTrackReducer } from "./reducers/backgroundTrackSlice";
import { characterActions, characterReducer } from "./reducers/characterSlice";
import { chatReducer } from "./reducers/chatSlice";
import { contentReducer } from "./reducers/contentSlice";
import { memoryActions, memoryReducer } from "./reducers/memorySlice";
import { onboardingReducer } from "./reducers/onboardingSlice";
import { personaActions, personaReducer } from "./reducers/personaSlice";
import { sessionReducer } from "./reducers/sessionSlice";
import { Story, storyActions, storyReducer } from "./reducers/storySlice";
import { StoryChunk, storyChunkActions, storyChunkReducer } from "./reducers/storyChunkSlice";
import { storySetActions, storySetReducer } from "./reducers/storySetSlice";
import { storyTemplateActions, storyTemplateReducer } from "./reducers/storyTemplateSlice";
import { storyTemplatePromptActions, storyTemplatePromptReducer } from "./reducers/storyTemplatePromptSlice";
import { storyTemplateSetActions, storyTemplateSetReducer } from "./reducers/storyTemplateSetSlice";
import { subscriptionActions, subscriptionReducer } from "./reducers/subscriptionSlice";
import { themeActions, themeReducer } from "./reducers/themeSlice";
import { userActions, userReducer } from "./reducers/userSlice";
import { voiceActions, voiceReducer } from "./reducers/voiceSlice";
import { worldActions, worldReducer } from "./reducers/worldSlice";
import { WS_URL } from "../config";
import { feedbackActions, feedbackReducer } from "./reducers/feedbackSlice";

export type RootState = ReturnType<typeof store.getState>;
export type EntityState = Omit<RootState, "chat" | "content" | "onboarding" | "session">;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export const store = configureStore({
  reducer: {
    account: accountReducer,
    accountContributor: accountContributorReducer,
    accountReader: accountReaderReducer,
    audioStory: audioStoryReducer,
    audioStoryPlaylist: audioStoryPlaylistReducer,
    backgroundTrack: backgroundTrackReducer,
    character: characterReducer,
    chat: chatReducer,
    content: contentReducer,
    feedback: feedbackReducer,
    memory: memoryReducer,
    onboarding: onboardingReducer,
    persona: personaReducer,
    session: sessionReducer,
    story: storyReducer,
    storyChunk: storyChunkReducer,
    storySet: storySetReducer,
    storyTemplate: storyTemplateReducer,
    storyTemplatePrompt: storyTemplatePromptReducer,
    storyTemplateSet: storyTemplateSetReducer,
    subscription: subscriptionReducer,
    theme: themeReducer,
    user: userReducer,
    voice: voiceReducer,
    world: worldReducer,
  },
});

export const getActionsByName = (entityName: keyof EntityState) => {
  switch (entityName) {
    case "account":
      return accountActions;
    case "accountContributor":
      return accountContributorActions;
    case "accountReader":
      return accountReaderActions;
    case "audioStory":
      return audioStoryActions;
    case "audioStoryPlaylist":
      return audioStoryPlaylistActions;
    case "backgroundTrack":
      return backgroundTrackActions;
    case "character":
      return characterActions;
    case "feedback":
      return feedbackActions;
    case "memory":
      return memoryActions;
    case "persona":
      return personaActions;
    case "story":
      return storyActions;
    case "storyChunk":
      return storyChunkActions;
    case "storySet":
      return storySetActions;
    case "storyTemplate":
      return storyTemplateActions;
    case "storyTemplatePrompt":
      return storyTemplatePromptActions;
    case "storyTemplateSet":
      return storyTemplateSetActions;
    case "subscription":
      return subscriptionActions;
    case "theme":
      return themeActions;
    case "user":
      return userActions;
    case "voice":
      return voiceActions;
    case "world":
      return worldActions;
    default:
      throw new Error(`Unknown entity name: ${entityName}`);
  }
};

export const webSocket = new WebSocket(WS_URL);

const heartbeat = () => {
  if (webSocket.readyState === WebSocket.OPEN) {
    webSocket.send(JSON.stringify({ event: "heartbeat" }));
  }

  setTimeout(() => heartbeat(), 10000);
};

webSocket.onopen = () => {
  console.log("WebSocket connection established.");

  heartbeat();
};

webSocket.onmessage = (event) => {
  const parsedMessage = JSON.parse(event.data);

  switch (parsedMessage?.event) {
    case "story.updated":
      const story = parsedMessage.data as Story;
      store.dispatch(storyActions.updateLocalById(story));
      break;
    case "storyChunk.updated":
      const storyChunk = parsedMessage.data as StoryChunk;
      store.dispatch(storyChunkActions.updateLocalById(storyChunk));
      break;
    case "heartbeat":
      console.log("Heartbeat received");
      break;
    default:
      console.log(`Unknown event: ${parsedMessage?.event}`);
      break;
  }
};
