import { useState } from "react";

import { Haptics } from "../../utils/haptics";
import { OnboardingCard } from "../../components/OnboardingCard";
import { ROUTES } from "../../utils/routes";
import { SelectableCard } from "../../components/SelectableCard";
import { useNavigation } from "../../hooks/useNavigation";

export const OnboardingAccountTypePage = () => {
  const navigation = useNavigation();
  const [accountType, setAccountType] = useState<"owner" | "contributor">("owner");

  const onChangeFormDataHandler = (newAccountType: "owner" | "contributor") => () => {
    setAccountType(newAccountType);
  };

  const onClickContinue = () => {
    Haptics.trigger(Haptics.HapticFeedbackTypes.impactLight);

    if (accountType === "owner") {
      navigation.navigate(ROUTES.ONBOARDING_CREATE_ACCOUNT, { onboardingType: "account" });
    } else {
      navigation.navigate(ROUTES.ONBOARDING_CONTRIBUTOR_CODE, { onboardingType: "contributor" });
    }
  };

  return (
    <OnboardingCard
      title="Let's get you set up for storytelling. Tell us the part you want to play. ✨"
      backText="Already have an account?"
      onClickBack={() => navigation.goBack()}
      continueText={accountType === "owner" ? "Create Account" : "Enter Code"}
      onClickContinue={onClickContinue}
    >
      <SelectableCard
        className="mb-2"
        title="Owner"
        subtitle="I'm setting this account up for my child."
        isSelected={accountType === "owner"}
        onClick={onChangeFormDataHandler("owner")}
      />
      <SelectableCard
        className="mb-4"
        title="Contributor"
        subtitle="I received a link from a family member, and I want to add to the story."
        isSelected={accountType === "contributor"}
        onClick={onChangeFormDataHandler("contributor")}
      />
    </OnboardingCard>
  );
};
