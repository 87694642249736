import { Character } from "./characterSlice";
import { createRestSlice } from "./restSlice";

export type StoryAnalysisWord = {
  text: string;
  start: number;
  end: number;
  characterId: string;
  paragraph: number;
};

export type StoryAnalysis = {
  duration: number;
  words: StoryAnalysisWord[];
};

export type Story = {
  id: string;
  isPublic: boolean;
  storyTemplateId: string;
  themeId: string;
  worldId: string;
  title: string;
  content: string;
  audioUrl: string;
  analysis: StoryAnalysis;
  narrator: Character;
  speakingCharacters: Character[];
  nonSpeakingCharacters: Character[];

  // Used for creating stories.
  storyIds?: string[];
  storySetId?: string;
  narratorId?: string;
  speakingCharacterIds?: string[];
  nonSpeakingCharacterIds?: string[];
};

const storySlice = createRestSlice<Story>("story", "/v1/stories");

export const storyActions = storySlice.actions;
export const storyReducer = storySlice.reducer;
