import { useEffect, useState } from "react";
import { DeleteOutlined, LinkOutlined } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../redux";
import { AccountContributor, accountContributorActions } from "../redux/reducers/accountContributorSlice";
import { AudioPlayer } from "./AudioPlayer";
import { Button } from "./Button";
import { FileField } from "./FileField";
import { InputField } from "./InputField";
import { Modal } from "./Modal";
import { View } from "./base/View";
import { Text } from "./base/Text";
import { useNavigation } from "../hooks/useNavigation";

type AccountContributorModalProps = {
  accountId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const AccountContributorModal = ({ accountId, isOpen, onClose }: AccountContributorModalProps) => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.accountContributor.isLoading);
  const isLoadingCreate = useAppSelector((state) => state.accountContributor.isLoadingCreate);
  const isLoadingDelete = useAppSelector((state) => state.accountContributor.isLoadingDelete);
  const accountContributors = useAppSelector((state) =>
    Object.values(state.accountContributor.entities).filter((contributor) => contributor.accountId === accountId),
  );
  const [name, setName] = useState<string>("");
  const [audioSampleFile, setAudioSampleFile] = useState<FileList>();

  const onClickCreateAccountContributor = async () => {
    const { payload } = await dispatch(
      accountContributorActions.create({
        accountId,
        name,
      }),
    );

    if (audioSampleFile) {
      dispatch(
        accountContributorActions.uploadVoice({
          id: (payload as AccountContributor).id,
          file: audioSampleFile[0],
        }),
      );
    }
  };

  const onClickVoiceIdHandler = (voiceId: string) => () => {
    navigation.navigate(`/admin/voices?id=${voiceId}`);
  };

  const onClickDeleteAccountContributorHandler = (id: string) => async () => {
    dispatch(accountContributorActions.deleteById(id));
  };

  useEffect(() => {
    if (accountId) {
      dispatch(accountContributorActions.getByAccountId(accountId));
    }
  }, [accountId]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Account Contributors"
      className="min-w-[80vw]"
    >
      <View className="flex flex-col w-full">
        <View
          className="grid w-full gap-4 mb-4"
          style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr" }}
        >
          <InputField
            name="name"
            placeholder="Name"
            value={name}
            onChange={(value) => setName(value)}
          />
          <FileField
            value={audioSampleFile}
            onChange={(value) => setAudioSampleFile(value)}
            fileTypes={["mp3", "wav", "mp4", "m4a"]}
            type="button"
          />
          <Button
            type="outlined"
            onClick={onClickCreateAccountContributor}
            isLoading={isLoadingCreate}
          >
            Create
          </Button>
        </View>
        <View
          className="grid w-full pb-2 font-semibold border-b border-gray-300"
          style={{ gridTemplateColumns: "1fr 1fr 64px 64px" }}
        >
          <View>Name</View>
          <View>Audio Sample</View>
          <View>Voice</View>
        </View>
        {isLoading ? (
          <View className="flex flex-row items-center justify-center w-full h-64">Loading...</View>
        ) : (
          <View className="w-full overflow-y-auto">
            {accountContributors.map((contributor) => (
              <View
                className="grid items-center border-b border-gray-300"
                style={{ gridTemplateColumns: "1fr 1fr 64px 64px" }}
              >
                <Text>{contributor.name}</Text>
                <AudioPlayer
                  audioUrl={contributor.audioSampleUrl}
                  size="small"
                  className="pr-8"
                />
                <Button
                  type="basic"
                  onClick={onClickVoiceIdHandler(contributor.voiceId)}
                  isLoading={isLoadingDelete}
                  className="flex justify-start"
                >
                  <LinkOutlined className="!h-8! w-8 text-gray-500" />
                </Button>
                <Button
                  type="basic"
                  onClick={onClickDeleteAccountContributorHandler(contributor.id)}
                  isLoading={isLoadingDelete}
                >
                  <DeleteOutlined className="text-red-500" />
                </Button>
              </View>
            ))}
            {accountContributors.length === 0 && <View className="flex flex-row items-center justify-center w-full h-64">No contributors found.</View>}
          </View>
        )}
      </View>
    </Modal>
  );
};
