import { useState } from "react";

import { accountReaderActions } from "../../redux/reducers/accountReaderSlice";
import { Haptics } from "../../utils/haptics";
import { OnboardingCard } from "../../components/OnboardingCard";
import { ROUTES } from "../../utils/routes";
import { SelectableCard } from "../../components/SelectableCard";
import { sessionActions } from "../../redux/reducers/sessionSlice";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useNavigation } from "../../hooks/useNavigation";
import { useRoute } from "../../hooks/useRoute";

type Route = {
  params: {
    onboardingType: "account" | "contributor";
  };
};

export const OnboardingReaderGenderPage = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const route = useRoute<Route>();
  const user = useAppSelector((state) => state.session.user);
  const isLoading = useAppSelector((state) => state.accountReader.isLoading);
  const [gender, setGender] = useState<string>("boy");

  const onChangeGenderHandler = (newGender: string) => () => {
    setGender(newGender);
  };

  const onClickContinue = async () => {
    Haptics.trigger(Haptics.HapticFeedbackTypes.impactLight);

    await dispatch(
      accountReaderActions.updateById({
        id: user?.account?.readers?.[0].id,
        gender,
      }),
    );

    dispatch(sessionActions.getSession());

    navigation.navigate(ROUTES.ONBOARDING_READER_LEVEL, { onboardingType: route.params.onboardingType });
  };

  return (
    <OnboardingCard
      title="Tell us about your reader. What's their gender?"
      subtitle="This ensures each tale is uniquely tailored, engaging, and delightful, making story time a magical experience."
      currentStep={3}
      totalSteps={6}
      onClickContinue={onClickContinue}
      continueDisabled={!gender}
      continueLoading={isLoading}
    >
      <SelectableCard
        className="mb-2"
        title="Boy"
        subtitle="(he/him)"
        isSelected={gender === "boy"}
        onClick={onChangeGenderHandler("boy")}
      />
      <SelectableCard
        className="mb-2"
        title="Girl"
        subtitle="(she/her)"
        isSelected={gender === "girl"}
        onClick={onChangeGenderHandler("girl")}
      />
      <SelectableCard
        className="mb-4"
        title="Do not gender"
        subtitle="(always use name)"
        isSelected={gender === "none"}
        onClick={onChangeGenderHandler("none")}
      />
    </OnboardingCard>
  );
};
