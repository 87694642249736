import { AutoFixHighRounded, Close, PlayArrowRounded } from "@mui/icons-material";
import clsx from "clsx";

import { Button } from "./Button";
import { Card } from "./Card";
import { ListItem } from "./ListItem";
import { Story } from "../redux/reducers/storySlice";
import { Text } from "./Text";
import { View } from "./View";

type StoryListCardProps = {
  className?: string;
  title?: string;
  stories?: Story[];
  onClickBack: () => void;
  onClickStory: (storyId: string) => void;
  onClickCreate?: () => void;
  selectedStoryId?: string;
};

export const StoryListCard = ({
  className,
  title = "Stories",
  stories = [],
  onClickBack = () => {},
  onClickStory = () => {},
  onClickCreate,
  selectedStoryId,
}: StoryListCardProps) => {
  const onClickStoryHandler = (storyId: string) => () => {
    onClickStory(storyId);
  };

  return (
    <Card className={className}>
      <View className="flex items-center justify-start gap-4 mb-4 w-full pt-4 sm:pt-8 px-4 sm:px-8">
        <Button
          type="basic"
          className="!w-8 !h-8 sm:!w-10 sm:!h-10"
        >
          <Close
            className="!w-8 !h-8 sm:!w-10 sm:!h-10 text-gray-800"
            onClick={onClickBack}
          />
        </Button>
        <Text className="text-2xl sm:text-4xl font-semibold text-gray-800 line-clamp-2">{title}</Text>
      </View>
      <View className="flex flex-col overflow-y-auto px-4 sm:px-8 pb-4 sm:pb-8">
        {stories.map((story, index) => {
          return (
            <ListItem
              key={story.id}
              className={clsx(selectedStoryId === story.id && "!border-secondary")}
              onClick={onClickStoryHandler(story.id)}
              leftIcon={<Text className="text-white text-xl font-bold">{index + 1}</Text>}
              leftIconClassName="bg-secondary border-secondary border-[1px]"
              rightIcon={<PlayArrowRounded className="text-primary" />}
              rightIconClassName="border-none"
              title={story.title}
            />
          );
        })}
      </View>
      {onClickCreate && (
        <Button
          className="!w-auto px-4 mb-8 mx-8 mt-4"
          onClick={onClickCreate}
          isLoading={false}
        >
          Create
          <AutoFixHighRounded className="ml-4" />
        </Button>
      )}
    </Card>
  );
};
