import { createRestSlice } from "./restSlice";

export type AudioStory = {
  id: string;
  title: string;
  content: string;
  personaId: string;
  baseAudioUrl: string;
  audioUrl: string;
  duration: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
};

export type AudioStoryVersion = {
  id: string;
  userId: string;
  accountContributorIds: string[];
  audioStoryId: string;
  audioUrl: string;
  duration: number;
};

export type AudioStoryChunk = {
  id: string;
  index: number;
  content: string;
  audioUrl: string;
  duration: number;
  direction: string;
};

const audioStorySlice = createRestSlice<AudioStory>("audioStory", "/v1/audioStories");

export const audioStoryActions = audioStorySlice.actions;
export const audioStoryReducer = audioStorySlice.reducer;
