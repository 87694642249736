import { useEffect } from "react";
import { EntityState, getActionsByName, useAppDispatch, useAppSelector } from "../redux";

export const useEntity = <T extends {}>(entityName: keyof EntityState, id?: string) => {
  const dispatch = useAppDispatch();
  const entities = useAppSelector((state: EntityState) => state[entityName].entities);
  const ids = useAppSelector((state: EntityState) => state[entityName].ids);
  const actions = getActionsByName(entityName);

  useEffect(() => {
    if (id && !entities[id]) {
      // @ts-ignore
      dispatch(actions.getById(id));
    } else {
      // @ts-ignore
      dispatch(actions.get());
    }
  }, [id]);

  return {
    entity: id ? (entities[id] as unknown as T) : undefined,
    entities: entities as unknown as Record<string, T>,
    ids,
  };
};
