import { useState } from "react";

import { Button } from "../components/base/Button";
import { contentActions } from "../redux/reducers/contentSlice";
import { Form } from "../components/base/Form";
import { Haptics } from "../utils/haptics";
import { InputField } from "../components/base/InputField";
import { OnboardingCard } from "../components/OnboardingCard";
import { ROUTES } from "../utils/routes";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { Text } from "../components/base/Text";
import { useAppDispatch, useAppSelector } from "../redux";
import { useNavigation } from "../hooks/useNavigation";

type FormData = {
  email?: string;
  password?: string;
};

export const SignInPage = () => {
  const navigation = useNavigation();
  const [formData, setFormData] = useState<FormData>({});
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.session.isLoading);
  const error = useAppSelector((state) => state.session.error);

  const onChangeFormData = (newFormData: FormData) => {
    setFormData(newFormData);
  };

  const onClickSignIn = async () => {
    Haptics.trigger(Haptics.HapticFeedbackTypes.impactLight);

    try {
      await dispatch(sessionActions.signIn(formData)).unwrap();
      await dispatch(contentActions.getContent()).unwrap();

      const user = await dispatch(sessionActions.getSession()).unwrap();

      if (user?.isAdmin) {
        navigation.navigate("/admin");
      } else {
        navigation.navigate(ROUTES.AUDIO_LIBRARY);
      }
    } catch (error) {
      // Unauthorized
    }
  };

  const onClickSignUp = () => {
    Haptics.trigger(Haptics.HapticFeedbackTypes.impactLight);

    navigation.navigate(ROUTES.ONBOARDING_ACCOUNT_TYPE);
  };

  const onClickForgotPassword = () => {
    navigation.navigate(ROUTES.FORGOT_PASSWORD);
  };

  return (
    <OnboardingCard>
      <Text className="text-6xl font-semibold text-center mb-2 text-primary">Prim Stories</Text>
      <Text className="text-lg md:text-xl font-light text-center mb-4">Inspire Readers. Connect Families.</Text>
      <Form
        data={formData}
        onChange={onChangeFormData}
      >
        <InputField
          className="w-full mb-4"
          name="email"
          placeholder="Email"
          showError={!!error}
        />
        <InputField
          className="w-full mb-4"
          name="password"
          placeholder="Password"
          type="password"
          showError={!!error}
        />
      </Form>
      <Button
        className="w-full mb-4"
        isLoading={isLoading}
        onClick={onClickSignIn}
      >
        Sign In
      </Button>
      <Button
        className="w-full mb-4"
        onClick={onClickSignUp}
        color="white"
      >
        Sign Up
      </Button>
      <Button
        className="w-full flex flex-row justify-center"
        onClick={onClickForgotPassword}
        type="basic"
        color="white"
      >
        <Text>Forgot Password?</Text>
      </Button>
    </OnboardingCard>
  );
};
