import { useEffect } from "react";

import { View } from "../components/View";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { useAppDispatch } from "../redux";

type SignOutPageProps = {
  className?: string;
};

export const SignOutPage = ({ className }: SignOutPageProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(sessionActions.signOut());
  }, []);

  return <View className={className} />;
};
