import React, { useState } from "react";

import { Form } from "../components/base/Form";
import { InputField } from "../components/base/InputField";
import { OnboardingCard } from "../components/OnboardingCard";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { Text } from "../components/base/Text";
import { useAppDispatch, useAppSelector } from "../redux";
import { useNavigation } from "../hooks/useNavigation";

type FormData = {
  email: string;
};

export const ForgotPasswordPage = () => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.session.isLoading);
  const [formData, setFormData] = useState<FormData>({ email: "" });
  const [resetPasswordSent, setResetPasswordSent] = useState<boolean>(false);

  const onChangeFormData = (newFormData: Record<string, unknown>) => {
    setFormData(newFormData as FormData);
  };

  const onClickContinue = async () => {
    if (resetPasswordSent) {
      navigation.goBack();
    } else {
      await dispatch(sessionActions.resetPassword(formData));

      setResetPasswordSent(true);
    }
  };

  const onClickBack = () => {
    navigation.goBack();
  };

  return (
    <OnboardingCard
      onClickBack={!resetPasswordSent ? onClickBack : undefined}
      onClickContinue={onClickContinue}
      continueDisabled={!formData.email}
      continueLoading={isLoading}
      backText="Back to Sign In"
      continueText={resetPasswordSent ? "Back to Sign In" : "Send Reset Link"}
    >
      {resetPasswordSent ? (
        <Text className="text-2xl text-center mb-4">
          Check your email! If we found an account associated with that email, we sent you a link to reset your password.
        </Text>
      ) : (
        <>
          <Text className="text-2xl text-center mb-4">Enter the email address associated with your account.</Text>
          <Form
            data={formData}
            onChange={onChangeFormData}
          >
            <InputField
              className="mb-4"
              name="email"
              placeholder="Email"
            />
          </Form>
        </>
      )}
    </OnboardingCard>
  );
};
