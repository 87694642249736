import { AddRounded } from "@mui/icons-material";

import { View } from "./View";
import { Button } from "./Button";
import { Text } from "./Text";
import { Character } from "../redux/reducers/characterSlice";
import { WrapContainer } from "./WrapContainer";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { Card } from "./Card";
import { Avatar } from "./Avatar";
import { capitalize } from "../utils/strings";
import { useState } from "react";

type StoryTitleSelectCardProps = {
  className?: string;
  characters?: Character[];
  selectedCharacters?: Record<string, string>;
  onChange?: (selectedCharacters: Record<string, string>) => void;
};

export const StoryTitleSelectCard = ({ className, characters = [], selectedCharacters = {}, onChange = () => {} }: StoryTitleSelectCardProps) => {
  const { windowWidth } = useWindowDimensions();
  const [currentCharacterKey, setCurrentCharacterKey] = useState<string>();

  const onClickCharacterHandler = (characterId: string) => () => {
    onChange({
      ...selectedCharacters,
      [currentCharacterKey || ""]: characterId,
    });

    setCurrentCharacterKey(undefined);
  };

  const onClickChangeCharacterHandler = (characterKey: string) => () => {
    setCurrentCharacterKey(characterKey);
  };

  return (
    <Card className={className}>
      <View className="absolute top-0 right-1/2 translate-x-1/2 -translate-y-1/4 !w-64 flex items-center justify-center ribbon bg-secondary">
        <Text className="text-center">PERFORMED BY</Text>
      </View>
      {currentCharacterKey ? (
        <View className="flex flex-row flex-wrap items-center justify-between pt-16 px-4">
          {characters.map((character) => (
            <View
              key={character.id}
              className="!w-[72px] !h-[72px] md:!w-[100px] md:!h-[100px] flex items-center justify-center bg-gradient-to-b from-secondary to-primary rounded-full p-1 mx-2 mb-4"
            >
              <Avatar
                className="!w-[64px] !h-[64px] md:!w-[92px] md:!h-[92px] !shadow-none"
                image={character.image}
                name={character.name}
                characterId={character.id}
                onClick={onClickCharacterHandler(character.id)}
              />
            </View>
          ))}
        </View>
      ) : (
        <WrapContainer
          className="w-full overflow-hidden p-8 pt-16"
          maxRows={2}
          itemSize={windowWidth > 768 ? 144 : 88}
          items={Object.keys(selectedCharacters).map((key) => {
            const character = characters.find((character) => character.id === selectedCharacters[key]);

            return (
              <View
                key={character?.id}
                className="flex flex-col w-full h-full items-center justify-end cursor-pointer min-w-[72px] md:min-w-[144px] md:max-w-[144px]"
              >
                <View className="flex items-center justify-center bg-gradient-to-b from-secondary to-primary rounded-full p-1 mx-2 my-1">
                  {character ? (
                    <Avatar
                      className="!w-[64px] !h-[64px] md:!w-[92px] md:!h-[92px] transition-all !duration-700 !shadow-none"
                      image={character?.image}
                      name={character?.name}
                      characterId={character?.id}
                      onClick={onClickChangeCharacterHandler(key)}
                    />
                  ) : (
                    <Button
                      type="basic"
                      className="!w-24 !h-24 !rounded-full bg-white"
                      onClick={onClickChangeCharacterHandler(key)}
                    >
                      <AddRounded className="!w-16 !h-16 text-primary" />
                    </Button>
                  )}
                </View>
                <View className="w-full hidden md:flex flex-col items-center justify-center text-lg font-semibold mt-2 p-2 rounded-full shadow-lg transition-all duration-700 bg-white">
                  <Text className="truncate">{character?.name || capitalize(key.replace("_", " "))}</Text>
                  {character?.name && <Text className="truncate text-gray-400 text-sm -mt-2">{capitalize(key.replace("_", " "))}</Text>}
                </View>
              </View>
            );
          })}
        />
      )}
    </Card>
  );
};
