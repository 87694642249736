import { createRestSlice } from "./restSlice";

export type Voice = {
  id: string;
  name: string;
  description: string;
  providerType: string;
  providerId: string;
  trainingAudioUrl: string;
  sampleAudioUrl: string;
};

const voiceSlice = createRestSlice<Voice>("voice", "/v1/voices");

export const voiceActions = voiceSlice.actions;
export const voiceReducer = voiceSlice.reducer;
